<template>
  <div>
    <h4 class="text-black-50 font-weight-bold">RABBIT MQ</h4>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-5 col-12">
          <div class="card resultadosCard p-3">
            <table class="table table-borderless">
              <thead class="">
                <tr>
                  <th aling="center" class="h6 font-weight-bold">Cron</th>
                  <th aling="center" class="h6 font-weight-bold">vs</th>
                  <th aling="center" class="h6 font-weight-bold">Bot</th>
                </tr>
              </thead>
              <tbody>
                <tr v-tooltip.bottom="'Resultados de estudios en general'">
                  <td>{{ totalesPorDia.CRON.resultados }}</td>
                  <td>Resultados 🧪</td>
                  <td>{{ totalesPorDia.BOT.resultados }}</td>
                </tr>
                <tr v-tooltip.bottom="'Resultados COVID19 PCR'">
                  <td>{{ totalesPorDia.CRON.covid }}</td>
                  <td>COVID-19 PCR 🦠</td>
                  <td>{{ totalesPorDia.BOT.covid }}</td>
                </tr>
                <tr v-tooltip.bottom="'Resultados de Antígeno COVID19'">
                  <td>{{ totalesPorDia.CRON.antigeno }}</td>
                  <td>☺COVID-19 Antígeno💉</td>
                  <td>{{ totalesPorDia.BOT.antigeno }}</td>
                </tr>

                <tr v-tooltip.bottom="'Resultados de lentes'">
                  <td>{{ totalesPorDia.CRON.glasses }}</td>
                  <td>Lentes 👓</td>
                  <td>{{ totalesPorDia.BOT.glasses }}</td>
                </tr>
                <tr v-tooltip.bottom="'Resultados de laboratorio'">
                  <td>{{ totalesPorDia.CRON.laboratorio }}</td>
                  <td>Laboratorio 🔬</td>
                  <td>{{ totalesPorDia.BOT.laboratorio }}</td>
                </tr>
                <tr v-tooltip.bottom="'Resultados no listos'">
                  <td>{{ totalesPorDia.CRON.no_ready }}</td>
                  <td>No listo ❌</td>
                  <td>{{ totalesPorDia.BOT.no_ready }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-7 col-12">
          <div class="card resultadosCard p-3">
            <div class="tipo my-3 borderAbajo">
              <b class="h5 font-weight-bold">Servidores</b><b></b>
            </div>
            <div class="row">
              <div class="col-md-6 col-12">
                <div class="tipo my-1 pl-4">
                  <b class="h6 text-left">{{
                    servidores.Clusters
                  }}</b
                  ><b class="text-right h6 font-weight-bold">Clusters</b>
                </div>

                <div class="tipo my-1 pl-4">
                  <b class="h6 text-left">{{
                    servidores.WorkersBot
                  }}</b
                  ><b class="text-right h6 font-weight-bold">Workers Bot</b>
                </div>

                <div class="tipo my-1 pl-4">
                  <b class="h6 text-left">{{
                    servidores.WorkersValidation
                  }}</b
                  ><b class="text-right h6 font-weight-bold"
                    >Workers Validación</b
                  >
                </div>

                <div class="tipo my-1 pl-4">
                  <b class="h6 text-left">{{
                    servidores.WorkersNotificacion
                  }}</b
                  ><b class="text-right h6 font-weight-bold"
                    >Workerks notificación</b
                  >
                </div>
              </div>
              <div class="col-md-6 col-12">
                <Chart
                  v-if="graficaServidores.datasets[0]"
                  type="bar"
                  :data="graficaServidores"
                  :options="options"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-4 col-12">
          <div class="card resultadosCard p-3">
            <div class="tipo ml-5 mb-3">
              <b class="h5 font-weight-bold">COLA NOTIFICACIONES</b><b></b>
            </div>
            <Chart
              type="line"
              :data="graficaNotificaciones"
              :options="option"
              class="mb-3"
            />
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="card resultadosCard p-3">
            <div class="tipo ml-5 mb-3">
              <b class="h5 font-weight-bold">COLA BOT</b><b></b>
            </div>
            <Chart
              type="line"
              :data="graficaBot"
              :options="option"
              class="mb-3"
            />
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="card resultadosCard p-3">
            <div class="tipo ml-5 mb-3">
              <b class="h5 font-weight-bold">COLA VALIDACIÓN</b><b></b>
            </div>
            <Chart
              type="line"
              :data="graficaValidacion"
              :options="option"
              class="mb-3"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tooltip from "primevue/tooltip";
import SocketIo from "socket.io-client";
import Chart from "primevue/chart";
import dashboardService from "@/services/dashboard";
export default {
  name: "RabbitMQ",
  data() {
    return {
      resultadosTotalesWhatsapp: -1,
      totalesPorDia: {CRON:{resultados:-1},BOT:{resultados:-1}},
      totalesColasRabbit: -1,
      servidores: -1,
      hoy: null,
      basicData: {
        labels: [],
        datasets: [],
      },
      graficaServidores: {
        labels: ["Servidores"],
        datasets: [],
      },
      option: {},
      options: {
        responsive: true,
        tooltips: {
          mode: "index",
          intersect: false,
        },
        scales: {
          yAxes: [
            {
              display: false,
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
      chartData: {
        labels: ["Notificación", "Bot", "Plataforma"],
        datasets: [
          {
            data: [0, 0, 0],
            backgroundColor: ["#FFC830", "#99D992", "#7ED3EE"],
            hoverBackgroundColor: ["#FFC830", "#99D992", "#7ED3EE"],
          },
        ],
      },
      colaNotificacion: -1,
      colaBot: -1,
      colaValidacion: -1,
    };
  },
  components: {
    Chart: Chart,
  },
  props: {
    ruta: String,
    data: Object,
  },
  directives: {
    tooltip: Tooltip,
  },
  created() {
    var moment = require("moment");
    moment.locale("es");
    this.hoy = moment().format("LL");
    this.get();
  },
  methods: {
    get() {
      var moment = require("moment");
      moment.locale("es");
      var socket = SocketIo.connect(this.ruta, this.data);

      socket.on("totalesPorDia", (mensaje) => {
        this.totalesPorDia = mensaje;
      });
      dashboardService.getServidores().then((mensaje) => {
        this.servidores = mensaje;
        this.graficaServidores.datasets = [
          {
            label: "Clusters.",
            backgroundColor: "#FFC830",
            data: [parseInt(this.servidores.Clusters)],
          },
          {
            label: "Bot",
            backgroundColor: "#99D992",
            data: [parseInt(this.servidores.WorkersBot)],
          },
          {
            label: "Valid.",
            backgroundColor: "#7ED3EE",
            data: [parseInt(this.servidores.WorkersValidation)],
          },
          {
            label: "Notif.",
            backgroundColor: "#ff7e00",
            data: [parseInt(this.servidores.WorkersNotificacion)],
          },
        ];
      });
      socket.on("totalesColasRabbit", (mensaje) => {
        this.totalesColasRabbit = mensaje;
      });
      this.option = {
        responsive: true,
        hoverMode: "index",
        scales: {
          yAxes: [
            {
              type: "linear",
              display: true,
              id: "der",
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              display: true,
            },
          ],
        },
        legend: {
          labels: {
            fontSize: 14,
          },
        },
      };
      socket.on("colasRabbitMQ", (mensaje) => {
        let notificaciones = [];
        let notificacionesLabel = [];
        this.colaNotificacion = mensaje;
        for (let x = 0; x < this.colaNotificacion.Notificaciones.length; x++) {
          notificaciones.push(this.colaNotificacion.Notificaciones[x].x);
          notificacionesLabel.push(this.colaNotificacion.Notificaciones[x].y);
        }
        let bot = [];
        let botLabel = [];
        this.colaNotificacion = mensaje;
        for (let x = 0; x < this.colaNotificacion.Bot.length; x++) {
          bot.push(this.colaNotificacion.Bot[x].x);
          botLabel.push(this.colaNotificacion.Bot[x].y);
        }
        let validacion = [];
        let validacionLabel = [];
        this.colaNotificacion = mensaje;
        for (let x = 0; x < this.colaNotificacion.Validacion.length; x++) {
          validacion.push(this.colaNotificacion.Validacion[x].x);
          validacionLabel.push(this.colaNotificacion.Validacion[x].y);
        }
        this.graficaNotificaciones = {
          labels: notificacionesLabel,
          datasets: [
            {
              label: 'Total',
              data: notificaciones,
              fill: true,
              backgroundColor: "#258e4d00",
              borderColor: "#ffc830",
            },
            
          ],
        };
        this.graficaBot = {
          labels: botLabel,
          datasets: [
            {
              label: 'Total',
              data: bot,
              fill: true,
              backgroundColor: "#258e4d00",
              borderColor: "#ffc830",
            },
            
          ],
        };
        this.graficaValidacion = {
          labels: validacionLabel,
          datasets: [
            {
              label: 'Total',
              data: validacion,
              fill: true,
              backgroundColor: "#258e4d00",
              borderColor: "#ffc830",
            },
            
          ],
        };
      });
    },
  },
};
</script>