<template>
    <div>
        <div class="row">
            <div class="form-group col-md-3 col-11">
                <label for="formGroupExampleInput">Fecha inicial</label>
                <input v-model="fecha_inicio" type="date" id="fecha_inicio" class="form-control">
            </div>
            <div class="form-group col-md-3 col-11">
                <label for="formGroupExampleInput">Fecha final</label>
                <input v-model="fecha_final" type="date" id="theDate" class="form-control">
            </div>
            <div class="form-group col-md-3 col-11 my-auto">
                <button type="button" class="btn btn-primary" @click="actualizar">Consultar</button>
            </div>
        </div>
        <DataTable :value="flujos" ref="flujo" class="p-datatable-responsive">
                <template #header>
                    Flujos COVID
                </template>
                
                <Column field="fecha" header="Hora y fecha" :sortable="true"></Column>
                <Column field="ASESOR_CORONAVIRUS" header="Asesor" :sortable="true"></Column>
                <Column field="CORONAVIRUS_INFO" header="Informacion" :sortable="true"></Column>
                <Column field="CORONAVIRUS_MEDIDAS" header="Medidas preventivas" :sortable="true"></Column>
                <Column field="CORONAVIRUS_CLINICAS" header="Clinicas" :sortable="true"></Column>
                <Column field="CORONAVIRUS_CITA" header="Citas" :sortable="true"></Column>
                <Column field="CORONAVIRUS_REAGENDAR" header="Reagendar" :sortable="true"></Column>
                <Column field="CORONAVIRUS_DOCS" header="Docs" :sortable="true"></Column>
                <Column field="CORONAVIRUS_CANCELAR" header="Cancelar" :sortable="true"></Column>
                <Column field="OTROS" header="Otros" :sortable="true"></Column>
                <Column field="TOTAL" header="Total" :sortable="true"></Column>
        </DataTable>
        <DataTable :value="conversacion" ref="conversaciones" :filters="filters" :paginator="true" :rows="15" :selection.sync="seleccionado" selectionMode="single" data-key="senderId" @row-select="onRowSelect" @row-unselect="onRowUnselect" class="p-datatable-responsive">
                <template #header>
                    Conversaciones de COVID
                </template>
                <Column field="userid" header="Paciente" :sortable="true"></Column>
                <Column field="estado" header="Mensaje" :sortable="true"></Column>
                <!-- Column class="d-none" field="rEncuesta" header="Calificación de encuesta" :sortable="true"  >
                    <Rating :value="rEncuesta" :stars="5" :cancel="false" :readonly="true" />
                </Column-->
                <Column field="fecha" header="Hora y fecha" :sortable="true"></Column>
        </DataTable>
        <Dialog class="conversaciones" :closable="false" :modal="true" :header="'Conversación de +'+this.userid+' con ASISTENTE VIRTUAL el dia de '+this.fecha" :visible.sync="chat">
            <mensajes style="z-index:1044" :sender="this.userid" />
            <template #footer>
                <Button class="btn btn-primary btn-sm float-left" @click="inbox"  data-toggle="modal" data-target="#traspasarChat">
                    <font-awesome-icon class="mr-1" icon="reply" /> Enviar con asesor
                </Button>  
                <Button class="btn btn-danger btn-sm" @click="cerrar">
                    <font-awesome-icon class="mr-1" icon="times" />Cerrar conversacion
                </Button>
            </template>
        </Dialog>
        <table class="table table-hover table-reflow table-bordered">
        <thead class="thead-light">
            <tr>
            <th>Numeros incorrectos a los que se enviaron resultados</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="user in perdidos" :key="user.userid">
                <th scope="row">{{user.userid}}</th>
                
            </tr>
        </tbody>
    </table>
    </div>
</template>
<style scoped>
.p-dialog-mask.p-component-overlay {
    z-index: 1002!important;
}
</style>
<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Dialog from "primevue/dialog"
import reportesServices from "../../services/reportes"
import Mensajes from "../Mensajes.vue";
import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
export default {
    name: 'Covid',
    components: {
        DataTable,
        Column,
        Dialog,
        Mensajes,
        FontAwesomeIcon
    },
    data() {
        return {
            fecha_inicio: '',
            fecha_final: '',
            conversacion: null,
            filters: {},
            seleccionado: null,
            chat: false,
            userid: null,
            fecha: null,
            flujos:null,
            perdidos:null
        }
    },
    created() {

        let today = new Date();
        this.maxDate = new Date();
        this.maxDate.setDate(today.getDate() - 1);
    },
    mounted() {
        var date = new Date()

        var day = date.getDate()
        var month = date.getMonth() + 1
        var year = date.getFullYear()

        if (month < 10) month = "0" + month
        if (day < 10) day = "0" + day

        var today = year + "-" + month + "-" + day
        this.fecha_final = today
        this.fecha_inicio = `${year}-${month}-${day}`
        this.get()
    },
    methods: {
        actualizar() {
            this.get()
        },
        get(){
             reportesServices.covidChat(this.fecha_inicio,this.fecha_final).then(resp => {
                var moment = require('moment')
                moment.locale('es')
                resp = resp.map(chat =>{
                   
                    chat.fecha = moment(chat.fecha).format('YYYY MMMM DD, dddd')
                    return chat
                })
                this.conversacion = resp

            })
            reportesServices.covidIntents(this.fecha_inicio,this.fecha_final).then(resp => {
                var moment = require('moment')
                moment.locale('es')
                resp = resp.map(flujo =>{
                   
                    flujo.fecha = moment(flujo.fecha).format('YYYY MMMM DD, dddd')
                    return flujo
                })
                this.flujos = resp

            })
            reportesServices.resultadosPerdidosCovid(this.fecha_inicio,this.fecha_final).then(resp => {
                
                this.perdidos = resp

            })
        },
        abrir() {
            this.chat = true;
        },
        cerrar() {
            this.chat = false;
            this.seleccionado = null;
        },
        inbox() {
            this.$bus.$emit("abrirInbox", this.seleccionado.userid);
        },
        onRowSelect() {
            this.chat = true;
            this.userid = this.seleccionado.userid;
            this.fecha = this.seleccionado.fecha;
            this.$bus.$emit("abrirConversacion", 1);
            sessionStorage.setItem('sender',this.userid)
            this.seleccionado = null;
        },
        onRowUnselect() {
            this.seleccionado = null;
        },
    }
}
</script>