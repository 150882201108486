<template>
<div class="container-fluid">
  <div class="row p-3 border border-dark border-top-0 border-left-0 border-right-0 mb-3">
    <div class="col-6 col-md-auto d-flex mx-auto justify-content-center">
      <button class="btn px-md-5 m-2 m-md-0" @click="menu=1" :class="{'btn-warning text-white': menu==1, 'btn-outline-dark':menu!=1}">Dashboard</button>
    </div>
    <div class="col-6 col-md-auto d-flex mx-auto justify-content-center">
      <button class="btn px-md-5 m-2 m-md-0" @click="menu=2" :class="{'btn-warning text-white': menu==2, 'btn-outline-dark':menu!=2}">Whatsapp</button>
    </div>
    <div class="col-6 col-md-auto d-flex mx-auto justify-content-center">
      <button class="btn px-md-5 m-2 m-md-0" @click="menu=3" :class="{'btn-warning text-white': menu==3, 'btn-outline-dark':menu!=3}">Sitio Web</button>
    </div>
    <div class="col-6 col-md-auto d-flex mx-auto justify-content-center">
      <button class="btn px-md-5 m-2 m-md-0" @click="menu=5" :class="{'btn-warning text-white': menu==5, 'btn-outline-dark':menu!=5}">Sitio Web Ni</button>
    </div>
    <div class="col-6 col-md-auto d-flex mx-auto justify-content-center">
      <button class="btn px-md-5 m-2 m-md-0" @click="menu=4" :class="{'btn-warning text-white': menu==4, 'btn-outline-dark':menu!=4}">RabbitMQ</button>
    </div>
  </div>
  <inicio v-if="menu==1" :ruta="socket" :data="query" />
  <whatsapp v-else-if="menu==2" :ruta="socket" :data="query" />
  <sitio-web-ni v-else-if="menu==5"  />
  <sitio-web v-else-if="menu==3" :ruta="socket" :data="query" />
  <rabbitMQ v-else :ruta="socket" :data="query" />
</div>
</template>

<script>
import Inicio from './Secciones/Inicio'
import Whatsapp from './Secciones/Whatsapp'
import SitioWebNi from './Secciones/SitioWebNi'
import SitioWeb from './Secciones/SitioWeb'
import RabbitMQ from './Secciones/RabbitMQ'
export default {
    name: 'MonitorDeResultados',
    components: {
      Inicio,
      Whatsapp,
      SitioWebNi,
      SitioWeb,
      RabbitMQ
    },
    data(){
      return{
        socket: 'https://socket-ws.salud-digna.site',
        query: {
            query: {
                "Tipo": "5"
            }
        },
        menu:1
      }
    },
    
}
</script>
