<template>
    <div class="container-fluid">
        <div class="my-2">



            <div class="table-container">
                <table>
                    <thead>
                        <tr>
                            <th colspan="11" class="text-center">Filas</th>  
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Filas</td>
                            <td v-for="(row, index) in rows" :key="index">{{ row.nombre }}</td>
                            <td>Total</td>
                        </tr>
                        <tr>
                            <td>Total de solicitudes por fila</td>
                            <td v-for="(row, index) in rows" :key="index">{{ row.total }}</td>
                            <td>{{ totalSolicitudes }}</td>
                        </tr>
                        <tr>
                            <td>Cantidad de solicitudes sin asignar</td>
                            <td v-for="(row, index) in rows" :key="index">{{ row.sinAsignar }}</td>
                            <td>{{ totalSinAsignar }}</td>
                        </tr>
                        <tr>
                            <td>Cantidad de solicitudes en asesor</td>
                            <td v-for="(row, index) in rows" :key="index">{{ row.enAsesor }}</td>
                            <td>{{ totalEnAsesor }}</td> 
                        </tr>
                        <tr>
                            <td>Tiempo de espera de asignación</td>
                            <td v-for="(row, index) in rows" :key="index">{{ row.tiempoEspera }}</td>
                            <td>{{ solicitudes_max_tiempo ? solicitudes_max_tiempo.substring(0, 8) : 0 }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="table-responsive">
                <table class="table table-hover table-reflow table-bordered">
                    <thead class="thead-light">
                        <tr>
                            <th>Filas</th>
                            <th colspan="2">Cotizaciones</th>
                            <th colspan="2">Resultados</th>
                            <th colspan="2">Citas</th>
                            <th colspan="2">Otro</th>
                            <th colspan="2">Lentes</th>
                            <th colspan="2">Devoluciones</th>
                            <th colspan="2">Facturación</th>
                            <th colspan="2">Ubicaciones</th>
                            <th colspan="2">Desconocida</th>
                            <th rowspan="2">Solicitudes</th>
                            <th rowspan="2">Controles</th>
                            <th rowspan="2">MAX # PX</th>
                            <th rowspan="2">Espera</th>
                            
                            <!-- <th rowspan="2">Tiempo prom espe asesor</th> -->
                            <th rowspan="2">Editar</th>
                        </tr>
                        <tr>
                            <th>Ejecutivos</th>
                            <th>I</th>
                            <th>P</th>
                            <th>I</th>
                            <th>P</th>
                            <th>I</th>
                            <th>P</th>
                            <th>I</th>
                            <th>P</th>
                            <th>I</th>
                            <th>P</th>
                            <th>I</th>
                            <th>P</th>
                            <th>I</th>
                            <th>P</th>
                            <th>I</th>
                            <th>P</th>
                            <th>I</th>
                            <th>P</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="asesor in asesores" :key="asesor.id">
                            <th scope="row col-sm">
                                <b class="h5">{{ asesor.nombre }}
                                    <i v-if="asesor.rol_id==3">
                                <a v-if="asesor.felizometro==1" class="pt-5 my-2" style="font-size: 1em"> &nbsp;
                                    <font-awesome-icon icon="laugh" style="color:#75d27f"/>
                                </a>
                                <a v-if="asesor.felizometro==2" class="pt-5 my-2" style="font-size: 1em"> &nbsp;
                                    <font-awesome-icon icon="smile" style="color:#7fe1c5"/>
                                </a>
                                <a v-if="asesor.felizometro==3" class="pt-5 my-2" style="font-size: 1em"> &nbsp;
                                    <font-awesome-icon icon="meh" style="color:#ffc900" />
                                </a>
                                <a v-if="asesor.felizometro==4" class="pt-5 my-2" style="font-size: 1em" > &nbsp;
                                    <font-awesome-icon icon="sad-cry" style="color:#fb9a27" /> 
                                </a>
                                <a v-if="asesor.felizometro==5" class="pt-5 my-2" style="font-size: 1em"> &nbsp;
                                    <font-awesome-icon icon="tired" style="color:#ff7b7c"/> 
                                </a> 
                                     </i>
                                </b>
                                <br>
                        <i v-if="asesor.horario"><p v-if="asesor.horario[diaSemana].status">Labora de {{asesor.horario[diaSemana].matutino[0]}} a {{asesor.horario[diaSemana].vespertino[1]}}hrs <br>
                        Hora de comida {{asesor.horario[diaSemana].matutino[1]}} a {{asesor.horario[diaSemana].vespertino[0]}}hrs
                        </p><p v-else>No labora</p></i><i v-else>Sin horario asignado</i>
                        <p><span v-show="asesor.max_tiempo">Paciente mas antiguo: {{asesor.max_tiempo}}</span></p>
                                <div class="row">
                                    <div v-if="asesor.break" class="col-1 d-flex justify-content-start mr-2">
                                        <a class="badge badge-danger text-light d-flex justify-content-center align-items-center pointer "
                                            @click="cerrarBreak(asesor.id)">Cerrar break</a>
                                    </div> 
                                     
                                    <div class="col-10 ml-2">
                                       
                                        <span v-if="asesor.estatus_asesor == 1" class="parpadea texto-verde">En
                                            línea</span>
                                        <span v-else-if="asesor.estatus_asesor == 2"
                                            class="texto-verde text-warning">Inactivo</span>
                                        <span v-else-if="asesor.estatus_asesor == 4" class="texto-naranja">Ausente</span>
                                        
                                        <span v-else class="texto-verde text-danger">Desconectado</span>
                                         <span v-if="asesor.break" class="texto-naranja"><i class="icon-clock-1"/> 
                                            <span class="texto-naranja">{{asesor.break}} </span>
                                        </span>
                                    </div>
                                    
                                </div>
                            </th>
                            <td class="h5">{{ asesor.cotizaciones_inbox  > 0 ? asesor.cotizaciones_inbox : '-' }}</td>
                            <td class="h5">{{ asesor.cotizaciones_pendientes  > 0 ? asesor.cotizaciones_pendientes : '-' }}</td>
                            <td class="h5">{{ asesor.resultados_inbox  > 0 ? asesor.resultados_inbox : '-' }}</td>
                            <td class="h5">{{ asesor.resultados_pendientes  > 0 ? asesor.resultados_pendientes : '-' }}</td>
                            <td class="h5">{{ asesor.agendar_cita_inbox  > 0 ? asesor.agendar_cita_inbox : '-' }}</td>
                            <td class="h5">{{ asesor.agendar_cita_pendientes  > 0 ? asesor.agendar_cita_pendientes : '-' }}</td>
                            <td class="h5">{{ asesor.otro_inbox  > 0 ? asesor.otro_inbox : '-' }}</td>
                            <td class="h5">{{ asesor.otro_pendientes  > 0 ? asesor.otro_pendientes : '-' }}</td>
                            <td class="h5">{{ asesor.lentes_inbox  > 0 ? asesor.lentes_inbox : '-' }}</td>
                            <td class="h5">{{ asesor.lentes_pendientes  > 0 ? asesor.lentes_pendientes : '-' }}</td>
                            <td class="h5">{{ asesor.devoluciones_inbox  > 0 ? asesor.devoluciones_inbox : '-' }}</td>
                            <td class="h5">{{ asesor.devoluciones_pendientes  > 0 ? asesor.devoluciones_pendientes : '-' }}</td>
                            <td class="h5">{{ asesor.facturacion_inbox  > 0 ? asesor.facturacion_inbox : '-' }}</td>
                            <td class="h5">{{ asesor.facturacion_pendientes  > 0 ? asesor.facturacion_pendientes : '-' }}</td>
                            <td class="h5">{{ asesor.ubicaciones_inbox  > 0 ? asesor.ubicaciones_inbox : '-' }}</td>
                            <td class="h5">{{ asesor.ubicaciones_pendientes  > 0 ? asesor.ubicaciones_pendientes : '-' }}</td>
                            
                            <td class="h5">{{ asesor.desconocido_inbox  > 0 ? asesor.desconocido_inbox : '-' }}</td>
                            <td class="h5">{{ asesor.desconocido_pendientes  > 0 ? asesor.desconocido_pendientes : '-' }}</td>
                            
                            <td class="h5">{{ asesor.solicitudes_asesor   }}</td>
                            <td>
                                <div v-if="asesor.sesion" class="rounded bg-success text-white font-weight-bold text-center mr-1" style="display: inline-block; padding: 6px">
                                    <font-awesome-icon spin icon="cog"/></div>
                                    <div v-else class="rounded bg-info text-white font-weight-bold text-center mr-1" style="display: inline-block; padding: 6px"> <font-awesome-icon icon="cog"/>
                                </div> 
                                <button @click="cambiarEstatus(asesor.id, !asesor.sesion)" class="btn btn-sm"
                                    :class="{ 'btn-info': !asesor.sesion, 'btn-secondary': asesor.sesion }">
                                    <font-awesome-icon v-if="asesor.sesion" icon="pause" />
                                    <font-awesome-icon v-else icon="play" />
                                    <span v-if="asesor.sesion"></span>
                                    <span v-else></span>
                                    <span v-if="esperar" class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                </button>
                                <button @click="mandarCola(asesor.id)" class="btn btn-primary btn-sm ml-1">A
                                    cola</button>
                            </td>
                            <td class="h5">{{ asesor.num_pacientes }}</td>
                            <td class="h5">{{ asesor.max_tiempo ? asesor.max_tiempo.substring(0, 8) : '00:00' }}</td>

                            <!-- <td class="h5">0:03:00</td> -->
                            <td>
                                <button class="btn btn-warning btn-sm" @click="editar(asesor)">
                                    <font-awesome-icon icon="edit" />
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>


        </div>

        <Sidebar :visible="panelLateral" position="right" class="lateralParametros">
            <h4><b>{{ seleccionado.nombre }}</b></h4>
            <div class="contenidoAsesorParametros mt-4">
                <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                        <span class="input-group-text"># de px máximos</span>
                    </div>
                    <input v-model="seleccionado.num_pacientes" type="number" class="form-control">
                </div>
                <p class="h6 mt-3">Orden de los pacientes que recibirá:</p>
                <select v-model="seleccionado.cola" class="custom-select custom-select-sm">
                    <option value="ASC">Antiguos</option>
                    <option value="DESC">Nuevos</option>
                </select>
            </div>
            <button class="btn btn-secondary btn-sm float-left mt-2" @click="panelLateral = false">Cerrar</button>
            <button class="btn btn-success btn-sm float-right mt-2"
                @click="guardar(seleccionado.id, seleccionado.sesion, seleccionado.facebook, seleccionado.num_pacientes, seleccionado.cola)">Guardar
                <font-awesome-icon icon="save" /></button>
        </Sidebar>
        <Sidebar :visible="panelGeneral" position="right" class="lateralGeneral">
            <h4><b>Px máximos generales </b></h4>
            <div class="contenidoAsesorParametros mt-4">
                <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                        <span class="input-group-text"># de px máximos generales</span>
                    </div>
                    <input v-model="seleccionadoGen.num_pacientes" type="number" class="form-control">
                </div>
            </div>
            <ul class="list-group list-group-flush" style="margin: 9px;">
                <li class="list-group-item" v-for="list in listaAse" :key="list.id" style="padding: 7px">
                    <input type="checkbox" v-model="list.seleccionado">
                    {{ list.nombre }}
                </li>
            </ul>
            <button class="btn btn-secondary btn-sm float-left mt-2" @click="panelGeneral = false">Cerrar</button>
            <button class="btn btn-success btn-sm float-right mt-2"
                @click="guardarGenEsp(seleccionadoGen.num_pacientes)">Guardar Generales <font-awesome-icon
                    icon="save" /></button>
        </Sidebar>
    </div>
</template>
<style>
.texto-verde {
    font-size: 14px;
    font-weight: bold;
    color: #4d9702;
}

.texto-naranja {
    font-size: 14px;
    font-weight: bold;
    color: orangered !important
}

.parpadea {

    animation-name: parpadeo;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    -webkit-animation-name: parpadeo;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo {
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 0.0;
    }

    100% {
        opacity: 1.0;
    }
}

@-webkit-keyframes parpadeo {
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 0.0;
    }

    100% {
        opacity: 1.0;
    }
}

@keyframes parpadeo {
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 0.0;
    }

    100% {
        opacity: 1.0;
    }
}

.table-container {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #000;
    padding: 10px;
    text-align: center;
}

thead {
    background-color: #007bff;
    color: white;
}

tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* .table-responsive {
    max-width: 1000px;
}

.table {
    width: 1000px;
} */

/* .listaAsesores {
      list-style-type: none;
      padding: 0;
    }
    
    .listaAsesores-item {
      margin-bottom: 10px;
    } */
</style>
<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import controlesServices from '../../services/controles'
import { faPlay, faPause, faCog, faMoon, faArrowAltCircleUp, faArrowAltCircleDown, faEdit, faSave, faSmile, faAngry, faLaugh, faFrown, faMeh, faHeadSideMask, faKissBeam, faSadCry, faDizzy, faTired } from '@fortawesome/free-solid-svg-icons'
library.add(faPlay, faPause, faCog, faMoon, faArrowAltCircleUp, faArrowAltCircleDown, faEdit, faSave, faSmile, faAngry, faLaugh, faFrown, faMeh, faHeadSideMask, faKissBeam, faSadCry, faDizzy, faTired, faSadCry)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import asesorServices from '../../services/asesor' 
import Sidebar from 'primevue/sidebar'
import Querys from '../../graphQL/chat.graphql'
export default {
    name: 'ConfiAsesor',
    data() {
        return {
            asesores: null,
            esperar: false,
            switch: false,
            checked: false,
            panelLateral: false,
            panelGeneral: false,
            cola: null,
            nicaragua: {},
            salvador: {},
            tab: 1,
            pedircola: null,
            sesion: JSON.parse(sessionStorage.getItem('sesion')),
            hemodialisis: null,
            seleccionado: { "id": 0, "nombre": "Aqui aparece el nombre del paciente", "ATENDIENDO": "Numero de atendidos", "ESPERA_1": "Px en Pendientes", "sesion": true, "facebook": 0, "num_pacientes": 35, "cola": "DESC" },
            semana: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
            diaSemana: '',
            seleccionadoGen: { "num_pacientes": 10 },
            listaAse: null,
            petct: null,
            google: null,
            gml: null,
            poolColasInfo: [],
            asesoresInfo: [],
            solicitudes_max_tiempo: 0,
            columns: [
                { name: "Cotizaciones" },
                { name: "Resultados" },
                { name: "Citas" },
                { name: "Otro" },
                { name: "Lentes" },
                { name: "Devoluciones" },
                { name: "Facturación" },
                { name: "Ubicaciones" },
                { name: "Desconocida" },
                { name: "Total" }  
            ],
            rows: [
                { nombre: "Precios y preparaciones", total: 0, sinAsignar: 0, enAsesor: 0, tiempoEspera: "00:00:00" },
                { nombre: "Consulta de resultados", total: 0, sinAsignar: 0, enAsesor: 0, tiempoEspera: "00:00:00" },
                { nombre: "Citas", total: 0, sinAsignar: 0, enAsesor: 0, tiempoEspera: "00:00:00" },
                { nombre: "Otro", total: 0, sinAsignar: 0, enAsesor: 0, tiempoEspera: "00:00:00" },
                { nombre: "Lentes", total: 0, sinAsignar: 0, enAsesor: 0, tiempoEspera: "00:00:00"},
                { nombre: "Devoluciones",total: 0, sinAsignar: 0, enAsesor: 0, tiempoEspera: "00:00:00" },
                { nombre: "Facturación",total: 0, sinAsignar: 0, enAsesor: 0, tiempoEspera: "00:00:00" },
                { nombre: "Ubicaciones",total: 0, sinAsignar: 0, enAsesor: 0, tiempoEspera: "00:00:00" }, 
                { nombre: "Desconocida", total: 0, sinAsignar: 0, enAsesor: 0, tiempoEspera: "00:00:00" }  
            ],
        }
    },
    components: {
        FontAwesomeIcon,
        Sidebar,
    },
    computed: {
        seleccionadoEsp() {
            return this.listaAse
                .filter(list => list.seleccionado)
                .map(list => list.id);
        },
        totalSolicitudes() {
            return this.rows.reduce((sum, row) => sum + row.total, 0);
        },
        totalSinAsignar() {
            return this.rows.reduce((sum, row) => sum + row.sinAsignar, 0);
        },
        totalEnAsesor() {
            return this.rows.reduce((sum, row) => sum + row.enAsesor, 0);
        }
    }/* ,
        watch: {
            listaAse: {
            deep: true,
                handler() {
                    // Eliminamos los IDs deseleccionados de la lista final
                    this.listaAse = this.listaAse.filter(list => list.seleccionado);
                }
            }
        } */, 
    apollo: {
        $subscribe: {}
    },
    created(){
 
        const numeroDia = new Date().getDay();
        this.diaSemana = this.semana[numeroDia]
     },
    mounted() {
        this.get()
        this.listaAsesores()
        this.poolColas()
        this.asesoresTiempoReal()
    },
    methods: {
        getSubscriptionAndQuery() {
            return { subscriptionName: 'poolColas', query: Querys.poolColas };
        },
         getSubscriptionAndQueryAsesor() {
            return { subscriptionName: 'asesoresTiempoReal', query: Querys.getAsesoresTiempoReal };
        },
        poolColas() { 
            if (this.$apollo.subscriptions.poolColas) {
                this.$apollo.subscriptions.poolColas.destroy()
            }

        // Guarda la referencia a `this` en otra variable
            const self = this;
            const { subscriptionName, query } = this.getSubscriptionAndQuery();
       
            this.$apollo.addSmartSubscription(subscriptionName, {
                query: query,
                async result({ data }) { 

                     // Asigna los datos recibidos al array
                    if (data && data.poolCoolas) {
                        const info =  data.poolCoolas[0]
                        self.poolColasInfo = data.poolCoolas[0];  
                        self.rows[0].total =  parseInt(info.cotizaciones_asesor) + parseInt(info.cotizaciones_noasesor) 
                        self.rows[0].enAsesor = info.cotizaciones_asesor  
                        self.rows[0].sinAsignar = info.cotizaciones_noasesor 
                        self.rows[0].tiempoEspera = info.cotizaciones_cita_max_tiempo == null ? '00:00:00' : info.cotizaciones_cita_max_tiempo.substring(0, 8)

                        self.rows[1].total = parseInt(info.resultados_asesor) + parseInt(info.resultados_noasesor) 
                        self.rows[1].enAsesor = info.resultados_asesor 
                        self.rows[1].sinAsignar = info.resultados_noasesor  
                        self.rows[1].tiempoEspera = info.resultados_max_tiempo == null ?'00:00:00' : info.resultados_max_tiempo.substring(0, 8)

                        self.rows[2].total = parseInt(info.agendar_cita_asesor) + parseInt(info.agendar_cita_noasesor) 
                        self.rows[2].enAsesor = info.agendar_cita_asesor  
                        self.rows[2].sinAsignar = info.agendar_cita_noasesor  
                        self.rows[2].tiempoEspera = info.agendar_cita_max_tiempo == null ? '00:00:00' : info.agendar_cita_max_tiempo.substring(0, 8)
                     
                        self.rows[3].total = parseInt(info.otro_asesor) + parseInt(info.otro_noasesor) 
                        self.rows[3].enAsesor = info.otro_asesor  
                        self.rows[3].sinAsignar = info.otro_noasesor  
                        self.rows[3].tiempoEspera = info.otro_cita_max_tiempo == null ? '00:00:00' : info.otro_cita_max_tiempo.substring(0, 8)

                        self.rows[4].total = parseInt(info.lentes_asesor) + parseInt(info.lentes_noasesor) 
                        self.rows[4].enAsesor = info.lentes_asesor  
                        self.rows[4].sinAsignar = info.lentes_noasesor 
                        self.rows[4].tiempoEspera = info.lentes_max_tiempo == null ? '00:00:00' : info.lentes_max_tiempo.substring(0, 8)

                        self.rows[5].total = parseInt(info.devoluciones_asesor) + parseInt(info.devoluciones_noasesor) 
                        self.rows[5].enAsesor = info.devoluciones_asesor  
                        self.rows[5].sinAsignar = info.devoluciones_noasesor  
                        self.rows[5].tiempoEspera = info.devoluciones_max_tiempo == null ? '00:00:00' : info.devoluciones_max_tiempo.substring(0, 8)

                        self.rows[6].total = parseInt(info.facturacion_asesor) + parseInt(info.facturacion_noasesor) 
                        self.rows[6].enAsesor = info.facturacion_asesor  
                        self.rows[6].sinAsignar = info.facturacion_noasesor  
                        self.rows[6].tiempoEspera = info.facturacion_max_tiempo == null ? '00:00:00' : info.facturacion_max_tiempo.substring(0, 8)

                        self.rows[7].total = parseInt(info.ubicaciones_asesor) + parseInt(info.ubicaciones_noasesor) 
                        self.rows[7].enAsesor = info.ubicaciones_asesor 
                        self.rows[7].sinAsignar = info.ubicaciones_noasesor  
                        self.rows[7].tiempoEspera = info.ubicaciones_max_tiempo == null ? '00:00:00' : info.ubicaciones_max_tiempo.substring(0, 8)

                        self.rows[8].total = parseInt(info.noespesificado_asesor) + parseInt(info.noespesificado_noasesor) 
                        self.rows[8].enAsesor = info.noespesificado_asesor  
                        self.rows[8].sinAsignar = info.noespesificado_noasesor 

                        self.rows[8].tiempoEspera = info.solicitudes_max_tiempo  ?  info.solicitudes_max_tiempo.substring(0, 8) : '00:00:00'

                        self.solicitudes_max_tiempo = info.solicitudes_max_tiempo  > 0 ? info.solicitudes_max_tiempo : '-'
                    } else {
                        console.log("No se recibieron datos de poolCoolas");
                    }
                },
            })
        },
        
        asesoresTiempoReal() { 
            const fechas = asesorServices.getFechasHoy() 
             
            if (this.$apollo.subscriptions.asesoresTiempoReal) {
                this.$apollo.subscriptions.asesoresTiempoReal.destroy()
            }
 
            const { subscriptionName, query } = this.getSubscriptionAndQueryAsesor();
 
            const self = this;
              
            this.$apollo.addSmartSubscription(subscriptionName, {
                query: query,
                variables () {
                    return {
                        fecha_inicio: fechas.fechaI,
                        fecha_fin: fechas.fechaF
                    }
                },
                async result({ data }) { 
                    self.asesores = data.asesoresTiempoReal
                     // Asigna los datos recibidos al array

                },
            })
        },
        get() { 
            controlesServices.Control().then(resp => {
                resp.map(x => {
                    if (x.id == 1) {
                        this.switch = x
                    } else {
                        return
                    }
                    return x
                })

                if (this.switch.status == 0) {
                    this.checked = false
                } else {
                    this.checked = true
                }
            })
        },
        cambiarEstatus(id, estatus) {
            this.esperar = true
            asesorServices.estatus(id, estatus).then(() => {
                this.get()
                this.esperar = false
            })
        },
        mandarCola(a) {
            this.esperar = true
            asesorServices.traspasarPacientesParametros(a).then(() => {
                this.get()
                this.esperar = false
            })
        },
        cambiarSwitch() {
            let variable
            variable = this.checked ? 0 : 1
            controlesServices.CambiarControl(1, variable).then(() => {
                this.get()
            })
        },
        editar(asesor) {
            this.seleccionado = asesor
            this.panelLateral = !this.panelLateral
        },
        editarGeneral() {
            this.panelGeneral = !this.panelGeneral
        },
        guardar(a, b, c, d, e) {
            this.esperar = true
            let datos = {
                asesor_id: a,
                sesion: b,
                facebook: c,
                num_pacientes: d,
                cola: e,
            }
            controlesServices.updateAsesorConfig(datos).then(res => {
                if (res) {
                    this.panelLateral = false
                    this.seleccionado = { "id": 0, "nombre": "Aqui aparece el nombre del paciente", "ATENDIENDO": "Numero de atendidos", "ESPERA_1": "Px en Pendientes", "sesion": true, "facebook": 0, "num_pacientes": 35, "cola": "DESC" }
                }
                this.esperar = false
            })
        },
        guardarGen(a) {
            this.esperar = true
            controlesServices.updateAsesorConfigGen(a).then(res => {
                if (res) {
                    this.panelGeneral = false
                    this.seleccionadoGen = { "num_pacientes": 10 }
                    controlesServices.getAsesorConfigGen()
                }
                this.esperar = false
            })
        },
        async cerrarBreak(asesor_id) {
            await asesorServices.cerrarBreak(asesor_id)
        },
        guardarGenEsp(data) {
            this.esperar = true
            for (var i = 0; i < this.seleccionadoEsp.length; i++) {
                var elemento = this.seleccionadoEsp[i];
                var a = elemento;
                var d = data;

                var datos = {
                    asesor_id: a,
                    num_pacientes: d
                };
                controlesServices.updateAsesorConfigGenEsp(datos).then(res => {
                    if (res) {
                        this.panelLateral = false
                    }
                    this.esperar = false
                })
            }

        },
        listaAsesores() {
            asesorServices.getAsesorEsp().then(respa => {
                this.listaAse = respa.map(item => ({ ...item, seleccionado: true }));
            })
        }
    },
}
</script>