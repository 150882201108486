<template>
    <div class>
        <table class="table table-hover table-reflow table-bordered">
            <thead class="thead-light">
                <tr>
                    <th>No. de Colaborador</th>
                    <th>Asesor</th>
                    <th>Email</th>
                    <th>Foto</th>
                    <th>Fecha Nacimiento</th>
                    <th>Fecha Aniversario</th>
                    <th>Permisos</th>
                    <th>Area</th>
                    <th><button class="btn btn-primary btn-sm" @click="nuevo()"><font-awesome-icon icon="plus"/></button></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="usuario in users" :key="usuario.id">
                    <td class="h5">{{usuario.num_colaborador}}</td>
                    <th scope="row"><b class="h5">{{usuario.nombre}}</b><br>
                        <b v-if="usuario.horario==null" class="h6">No tiene horario asignado</b>
                        <ul v-else>
                            <li v-for="(hora, index) in usuario.horario" :key="index"><p v-if="hora.status"><b>{{index}}</b><br> Labora de: 🕙{{hora.matutino[0]}} a {{hora.vespertino[1]}} <br> Descanso de: ⌛{{hora.matutino[1]}} a {{hora.vespertino[0]}}</p><p v-else><b>{{index}}</b><br>No labora</p></li>
                        </ul>
                    </th>
                    <td class="h5">{{usuario.email}}</td>
                    <td class="h5"><img class="rounded-circle imgPerfilAsesor" :src="'https://sd-storage.sfo2.digitaloceanspaces.com/PLATAFORMA-INBOX/imagenes/asesores/'+usuario.id+'.jpg'" :alt="usuario.nombre" height="75px" width="75px"></td>
                    <td class="h5">{{usuario.fecha_nacimiento}}</td>
                    <td class="h5">{{usuario.fecha_aniversario}}</td>
                    <td class="h5"><h5 class="h5"><span class="badge badge-secondary"> {{usuario.rol_id==1?'Administrador':usuario.rol_id==2?'Moderador': usuario.rol_id==3?'Asesor':'Apoyo'}}</span></h5><span v-show="usuario.estatus==0" class="badge badge-danger">Baja</span></td>
                    <td class="h5">{{usuario.grupo_id==1?'Productos Digitales':usuario.grupo_id==2?'Communitys Manager':usuario.grupo_id==3?'Marketing Automatizado':usuario.grupo_id==4?'Ecommerce':usuario.grupo_id==6?'Innovación':usuario.grupo_id==7?'Exp. al Paciente':usuario.grupo_id==8?'Clinica Pet-CT':usuario.grupo_id==9?'Clinica Pet-CT':'E-Marketing'}}</td>
                    <td><button class="btn btn-warning btn-sm" @click="editar(usuario)"><font-awesome-icon icon="edit"/></button><button class="btn btn-danger btn-sm" @click="eliminar(usuario)"><font-awesome-icon icon="trash"/></button></td>
                </tr>
            </tbody>
        </table>
        <Sidebar :visible="panelLateral" position="right" class="lateralParametros">
            <h4><b>{{seleccionado.nombre}}</b></h4>
                <div class="input-group mb-3">
                <div class="custom-file">
                    <input type="file" class="custom-file-input" id="file" @change="onSub($event,seleccionado.id)" accept="image/*">
                    <label class="custom-file-label" for="file">Selecciona una imagen</label>
                </div>
                </div> 
                <div class="text-success" id="estatusimgSucces"></div>
               
                    <div id="imgpreview"></div>
                    <img v-if="seleccionado.id==undefined" :src="'https://sd-storage.sfo2.digitaloceanspaces.com/PLATAFORMA-INBOX/imagenes/asesores/7.jpg'" height="75px" width="75px" onerror='this.style.display = "none"' reload>
                    <img id="imgperf" class="rounded-circle imgPerfilAsesor" v-else :src="'https://sd-storage.sfo2.digitaloceanspaces.com/PLATAFORMA-INBOX/imagenes/asesores/'+seleccionado.id+'.jpg'" height="75px" width="75px" onerror='this.style.display = "none"' reload>
                
                
                <div class="text-danger" id="estatusimgError"></div>
            <div class="contenidoAsesorParametros mt-4">
                <div class="input-group input-group-sm mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Numero de colaborador</span>
                        <input v-model="seleccionado.num_colaborador" type="text" class="form-control">
                    </div>
                </div>
                <div class="input-group input-group-sm mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Nombre</span>
                        <input v-model="seleccionado.nombre" type="text" class="form-control">
                    </div>
                </div>
                <div class="input-group input-group-sm mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Correo</span>
                    </div>
                    <input v-model="seleccionado.email" type="email" class="form-control">
                </div>
                <div v-if="nuevoUsuario" class="input-group input-group-sm mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Contraseña</span>
                    </div>
                    <input v-model="seleccionado.password" type="password" class="form-control">
                </div>
                <div v-else class="input-group input-group-sm mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Nueva Contraseña</span>
                    </div>
                    <input v-model="seleccionado.password" type="password" class="form-control">
                </div>
                <div class="input-group input-group-sm mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Cumpleaños</span>
                    </div>
                    <input v-model="seleccionado.fecha_nacimiento" type="date" class="form-control">
                </div>
                <div class="input-group input-group-sm mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Aniversario</span>
                    </div>
                    <input v-model="seleccionado.fecha_aniversario" type="date" class="form-control">
                </div>
    
                <div class="input-group input-group-sm mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text"># de px máximos</span>
                    </div>
                    <input v-model="seleccionado.num_pacientes" type="number" class="form-control">
                </div>
    
                <div class="input-group input-group-sm mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Meta p/hr</span>
                    </div>
                    <input v-model="seleccionado.meta_hora" @keyup="actualizarMeta()" type="number" class="form-control">
                </div>
                <p class="h6 mt-3">Tipo:</p>
                <select v-model="seleccionado.rol_id" class="custom-select custom-select-sm">
                    <option v-if="usuarioSesion.rol_id==1&&usuarioSesion.grupo_id<6" :value="1">Administrador</option>
                    <option :value="2">Moderador</option>
                    <option :value="3">Asesor</option>
                    <option :value="4">Apoyo</option>
                </select>
    
                <p class="h6 mt-3" v-if="usuarioSesion.grupo_id!=6">Equipo:</p>
                <select v-if="usuarioSesion.grupo_id!=6 && usuarioSesion.grupo_id!=10" v-model="seleccionado.grupo_id" class="custom-select custom-select-sm">
                    <option v-for="opcion in opcionesGrupos" :key="opcion.id" :value="opcion.id">{{ opcion.nombre }}</option>
                </select>
                <p class="h6 mt-3" >Encargado:</p>
                <select  v-model="seleccionado.encargado_id" class="custom-select custom-select-sm">
                    <option v-for="opcion in asesoresLista" :key="opcion.id" :value="opcion.id">{{ opcion.nombre }}</option>
                </select>
                <div v-if="seleccionado.grupo_id==2 || seleccionado.grupo_id==10 ">
                    <p class="h5 mt-3">
                        Horario de atención:
                    </p>
                    <table class="table table-responsive table-borderless">
                        <thead>
                            <tr>
                            <th>Dia</th>
                            <th>Turno 1</th>
                            <th>Turno 2</th>
                            </tr>
                        </thead>
                        <tbody class="table-scroll">
                            <div v-for="(hora, index) in seleccionado.horario" :key="index">
                                <tr>
                                    <th scope="row"><Checkbox v-model="hora.status" :binary="true" /><br>
                                        {{index}}
                                    </th>
                                    <td>De: <InputText type="time" v-model="hora.matutino[0]" @change="calcularMeta({...hora, index})"  min="05:00:00" max="22:00:00" :disabled="!hora.status" /><br>
                                    A: <InputText type="time" v-model="hora.matutino[1]" @change="calcularMeta({...hora, index})" min="05:00:00" max="22:00:00" :disabled="!hora.status" />
                                    </td>
                                    <td>De: <InputText type="time" v-model="hora.vespertino[0]" @change="calcularMeta({...hora, index})" min="05:00:00" max="22:00:00" :disabled="!hora.status" /><br>
                                    A: <InputText type="time" v-model="hora.vespertino[1]" @change="calcularMeta({...hora, index})" min="05:00:00" max="22:00:00" :disabled="!hora.status" />
                                    </td>
                                </tr>
                                <tr v-if="hora.status"><td></td><td>Meta total: {{ meta[index] }}</td></tr>
                            </div>
                        </tbody>
                        </table>
                </div>
            </div>
            <button class="btn btn-secondary btn-sm float-left mt-2" @click="cerrar()">Cerrar</button>
            <button class="btn btn-success btn-sm float-right mt-2" @click="guardar()">Guardar <font-awesome-icon icon="save"/></button>
        </Sidebar>
    </div>
    </template>
    <script>
    import InputText from 'primevue/inputtext';
    import Checkbox from 'primevue/checkbox';
    import userservices from "../../services/asesor"
    import Sidebar from 'primevue/sidebar'
    import {
        FontAwesomeIcon
    } from "@fortawesome/vue-fontawesome";
    import {
        library
    } from "@fortawesome/fontawesome-svg-core";
    import {
        faTimes,faReply,faFileExcel,faTrash
    } from "@fortawesome/free-solid-svg-icons";
    library.add(faTimes, faReply, faFileExcel,faTrash);
    export default {
        data() {
            return {
                users: null,
                usuarioSesion:JSON.parse(sessionStorage.getItem('sesion')),
                asesoresLista : [],
                panelLateral:false,
                seleccionado:{},
                esperar:false,
                nuevoUsuario:false,
                demo:null,
                semana:{"Domingo":{"status":false,"matutino":[],"vespertino":[]},"Lunes":{"status":true,"matutino":["08:00","13:30"],"vespertino":["15:00","18:30"]},"Martes":{"status":true,"matutino":["08:00","13:30"],"vespertino":["15:00","18:30"]},"Miercoles":{"status":true,"matutino":["08:00","13:30"],"vespertino":["15:00","18:30"]},"Jueves":{"status":true,"matutino":["08:00","13:30"],"vespertino":["15:00","18:30"]},"Viernes":{"status":true,"matutino":["08:00","13:30"],"vespertino":["15:00","18:30"]},"Sabado":{"status":false,"matutino":[],"vespertino":[]}},
                meta:{"Domingo":0,"Lunes":0,"Martes":0,"Miercoles":0,"Jueves":0,"Viernes":0,"Sabado":0},
                horastrabajo: {"Domingo":{},"Lunes":{},"Martes":{},"Miercoles":{},"Jueves":{},"Viernes":{},"Sabado":{}},
                file:null,
                imgpreview: '',
                asesoresGrupos: []  // Aquí almacenaremos las opciones obtenidas de la API
            }
        },
        userservice: null,
        components:{
          FontAwesomeIcon, Sidebar, InputText, Checkbox 
        },
        mounted() {
            this.inicio();
            this.cargarGrupoAsesores();
        },
        methods: {
           async cargarGrupoAsesores() {
                let resp;
                try {
                    resp = await userservices.getGrupoAsesor();
                    if (resp) { 
                        const marketingDigital = resp.find(v => v.nombre === "Marketing Digital");
                        const experienciaPaciente = resp.find(v => v.nombre === "Exp al Paciente");
                        const callCenter = resp.find(v => v.nombre === "CAT");
                        
                        if (marketingDigital) {
                            marketingDigital.nombre = "Marketing Automatizado";
                        }
                        if (experienciaPaciente) {
                            experienciaPaciente.nombre = "Experiencia al Paciente";
                        }
                         if (callCenter) {
                            callCenter.nombre = "Call Center";
                        }
    
                        this.opcionesGrupos = resp.filter(v => v.id !== 6 && v.id !== 10);
    
                    }
                } catch (error) {
                    console.error("Error al cargar opciones desde la API:", error);
                }    
            },
            
            inicio(){
                userservices.usuarios().then(datos => {
                    const data = datos.filter(x => x.estatus == 1);
                  
                    this.asesoresLista=data.filter(x => x.rol_id ==2 ); 
                    data.sort((a,b) =>{
                        return a.id - b.id
                    })
                    if(this.usuarioSesion.rol_id==2 && this.usuarioSesion.grupo_id!=6 && this.usuarioSesion.grupo_id!=10){
                        this.users=data.filter(x=> x.estatus==1 && x.rol_id>=2)
                    }else if(this.usuarioSesion.grupo_id==6){
                        this.users = data.filter(x => x.grupo_id == 6)
                    }else if(this.usuarioSesion.grupo_id==10){
                        this.users=data.filter(x=> x.grupo_id==10)
                    }else{
                        this.users = data
                    }
                })
            },
            nuevo(){
                this.panelLateral=true
                this.seleccionado={facebook:0,estatus:1,num_pacientes:0,rol_id:3,fecha_nacimiento:'1990-01-01',fecha_aniversario:'2020-01-01',grupo_id:2, cola:'ASC', meta_hora: 41.11}
                this.seleccionado.horario=this.semana
                this.nuevoUsuario=true
                if(this.usuarioSesion.grupo_id==6){
                    this.seleccionado.grupo_id=6
                }
                 if(this.usuarioSesion.grupo_id==10){
                    this.seleccionado.grupo_id=10
                }
            },
            editar(user){
                this.nuevoUsuario=false
                this.seleccionado=user
                this.seleccionado.fecha_nacimiento?false:this.seleccionado.fecha_nacimiento='1990-01-01'
                this.seleccionado.fecha_aniversario?false:this.seleccionado.fecha_aniversario='2020-01-01'
                this.seleccionado.horario==null?this.seleccionado.horario=this.semana:console.log('Ya trae horario')
                this.seleccionado.meta_hora = user.meta_hora
                this.panelLateral=true
                this.actualizarMeta()
            },
            eliminar(user){
                user.estatus=0
                let obj = {
                    id: user.id,
                    estatus:0
                }
                userservices.editarUsuario(obj).then(resp =>{
                        if(resp){
                            this.cerrar()
                            this.inicio()
                        }
                    }).finally(()=>{
                    this.esperar=false 
                    })
            },
            guardar(){
                this.esperar=true
                if(this.nuevoUsuario){
                    userservices.nuevoUsuario(this.seleccionado).then(resp =>{
                        if(resp){
                            this.cerrar()
                            this.inicio()
                        }
                    }).finally(()=>{
                    this.esperar=false 
                    })
                }else{
                    userservices.editarUsuario(this.seleccionado).then(resp =>{
                        if(resp){
                            this.cerrar()
                            this.inicio()
                        }
                    }).finally(()=>{
                    this.esperar=false 
                    })
                }
            },
            cerrar(){
                this.panelLateral=false
            },
            async onSub(event,id) {
                let div = document.getElementById('imgpreview')
                let div2 = document.getElementById('estatusimgError')
                let div3 = document.getElementById('estatusimgSucces')
                
                this.file=event.target.files[0]
                let filesize=event.target.files[0].size
                if(filesize>1000000){
                    div2.innerHTML= "La imagen no debe de superar 1 MB"
                }else{
                    let formData = new FormData();
                    formData.append("upload", this.file);
                    const imgrender  = await userservices.subirImg(formData,id)
                        if(imgrender){
                                const imgaperfil=document.querySelector('#imgperf')
                                let timestamp = new Date().getTime();
                                div.innerHTML=''
                                let img = document.createElement('img')
                                img.setAttribute('height','75px')
                                img.setAttribute('width','75px')
                                img.setAttribute('class','rounded-circle imgPerfilAsesor')
                                img.setAttribute('src',`https://sd-storage.sfo2.digitaloceanspaces.com/PLATAFORMA-INBOX/imagenes/asesores/${id}.jpg?t=` + timestamp)
                                div3.innerHTML= "Imagen cargada correctamente"
                                div.appendChild(img) 
                                imgaperfil.remove();
                                div2.remove();
                            } 
                }
                
            },
            calcularMeta(horario){
                if(!horario.status)
                return
                const meta = userservices.calcularMeta({...horario, metaphora: this.seleccionado.meta_hora})
                this.meta[horario.index] = meta
            },
            actualizarMeta(){
                const user = this.seleccionado
                for (const [index, value] of Object.entries(user.horario)) {
                    this.calcularMeta({...value, index})
                }
            },
        }
    }
    </script>
    <style>
    .horatxt {
        width: 84px!important;
        font-size: 11px;
    }
    </style>