import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
//import Resumen from '@/components/Dashboard/Resumen.vue'
import Reporte from '@/components/Dashboard/Reportes.vue'
import Historial from '@/components/Dashboard/Historial.vue'
import Monitor from '@/components/Dashboard/Monitor.vue'
import MonitorResultados from '@/components/Resultados/Monitor.vue'
import Catalogos from '@/components/Dashboard/Catalogo.vue'
import Incidencias from '@/components/Dashboard/Incidencias.vue'
import Envios from '@/components/Dashboard/Envios.vue'
import Parametros from '@/components/Dashboard/Parametros.vue'
import ServiceControl from '@/components/Dashboard/ServiceControl.vue'
import Covid from '@/components/Dashboard/Covid.vue'
//import Navbar from '@/components/Navbar.vue'
// import OctubreRosa from '@/components/Dashboard/OctubreRosa.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      title: 'Chat SD - LOGIN',
      metaTags: [
        {
          name: 'description',
          content: 'Plataforma diseñada para la atención de los pacientes'
        },
        {
          property: 'og:description',
          content: 'Plataforma diseñada para la atención de los pacientes'
        }
      ]
    }
  },
  {
    path: '/chat',
    name: 'chat',
    meta: { title: 'Inbox - Salud Digna' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Chats" */ '../views/Chat.vue')
  },
  // {
  //   path: '/resultados',
  //   name: 'resultados',
  //   meta: { title: 'Resultados - Salud Digna' },
  //   children:[{
  //     path: '/resultados',
  //     name: 'moResultados',
  //     component: MonitorResultados,
  //   }],
  //   component: () => import(/* webpackChunkName: "Resultados" */ '../views/Resultados.vue'),
  // },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: { title: 'Dashboard - Chat SD' },
    children: [{
      path: '/dashboard/historial',
      name: 'historial',
      component: Historial,
      meta: { title: 'Dashboard - Chat SD' },
    },{
      path: '/dashboard/catalogos',
      name: 'catalogo',
      component: Catalogos,
    },{
      path: '/dashboard/monitor',
      name: 'monitor',
      component: Monitor,
      meta: { title: 'Monitor - Chat SD' },
    },{
      path: '/dashboard',
      name: 'resumen',
      component: Monitor,
      meta: { title: 'Dashboard - Chat SD' },
    },{
      path: '/dashboard/resultados',
      name: 'moResultados',
      component: MonitorResultados,
      meta: { title: 'Resultados - Chat SD' },
    },{
      path: '/dashboard/reportes',
      name: 'reportes',
      component: Reporte,
      meta: { title: 'Reportes - Chat SD' },
    },{
      path: '/dashboard/incidencias',
      name: 'incidencias',
      component: Incidencias,
      meta: { title: 'Incidencias - Chat SD' },
    },{
      path: '/dashboard/envios',
      name: 'hsm',
      component: Envios,
    },{
      path: '/dashboard/parametros',
      name: 'parametros',
      component: Parametros,
      meta: { title: 'Parametros - Chat SD' },
    },{
      path: '/dashboard/servicecontrol',
      name: 'servicecontrol',
      component: ServiceControl,
      meta: { title: 'Control de Servicios - Chat SD' },
    },{
      path: '/dashboard/covid',
      name: 'covid',
      component: Covid,
      meta: { title: 'Dashboard - Chat SD' },
    }
    // ,
    // {
    //   path: '/dashboard/octubrerosa',
    //   name: 'octubrerosa',
    //   component: OctubreRosa,
    //   meta: { title: 'Octubre Rosa - Chat SD' },
    // }
  ],
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue')
  },
  {
    path: '/inicio',
    name: 'dashboardAsesor',
    meta: { title: 'Dashboard - Chat SD' },
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/DashboardAsesor.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const DEFAULT_TITLE = 'Chat SD - Salud Digna'
router.afterEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE
})
export default router
