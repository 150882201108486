import axios from 'axios'
import config from './config'
//const network = localStorage.getItem('network')
const token = sessionStorage.getItem('token')
//const ruta = window.location.href
let api = axios.create({
    
    baseURL: config.BASEURL, //(network=='FB'&&ruta=='/chat')?config.baseurlFB:config.BASEURL,
    headers: {
        Authorization: `${token}`
    },
    timeout: 85000 
})
let asesor = JSON.parse(sessionStorage.getItem('sesion')) || {id:0}
let asesor_id = asesor.id
api.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201 || response.status === 202 || response.status === 203 || response.status === 204) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                // case 400:
                //     console.log(error)
                //     break;
                case 401: 
                    return api.post('/closed', { asesor_id }).then(window.location.href = '/')
                
                // case 403:
                //     console.log(error)
                //     break;
                // case 404:
                //     console.log(error)
                //     break;
                case 500: 
                    console.log(error)
                    break;
                
                // case 502:
                //     console.log(error)
            }
            return Promise.reject(error.response);
        }
    }
);

export default api