import api from './api'
let service = {}

service.login = function(user, pass){
    return api.post('/login', {user,pass}).then(res => res.data)
}

service.loginGoogle = function(user, uid){
    return api.post('/login', {user, uid}).then(res => res.data)
}

export default service