<template>
  <div class="container">
    <div class="row">
      <div class="col mt-3">
        <div class="form-group">
          <label for="titulo">Titulo de notificación</label>
          <input type="text" class="form-control" id="titulo" v-model="titulo" placeholder="Tema" min="0" max="50">
        </div>
        <div class="form-group">
          <label for="descripcion">Descripción de notificación</label>
          <textarea type="text" class="form-control" id="descripcion" v-model="descripcion" placeholder="¿Que deseas notificar?" />
        </div>
        <div class="form-group">
          <label for="remitente">Remitente</label>
          <select class="custom-select" v-model="remitente">
            <option selected :value="asesor.nombre">Yo</option>
            <option value="Laboratorio Digital">Laboratorio Digital</option>
            <option value="Marketing Digital">Marketing Digital</option>
            <option value="Ecommerce">Ecommerce</option>
          </select>
        </div>
        <div class="form-group">
          <label for="tipo">Tipo de notificación</label>
          <select class="custom-select" v-model="tipo">
            <option selected :value="0">Común</option>
            <!-- <option :value="1">Con confirmación</option> -->
            <option :value="2">Emergente</option>
          </select>
        </div>
        <div class="form-group">
          <label for="tipo">Enviar a</label>
          <select class="custom-select" v-model="destino">
            <option selected value="2">Mi equipo</option>
            <option value="1">Todos</option>
            <option value="3">Un usuario</option>
          </select>
        </div>
        <div class="form-group" v-if="destino==3">
          <label for="tipo">Elegír a un usuario de la plataforma</label>
          <!-- <select ref="asesor" class="custom-select col-10" :class="{'col-12': this.tipo!=1}">
              <option disabled selected :value="0">Usuarios</option>
              <option v-for="(asesor) in asesores" :key="asesor.id"  :value="asesor.id" v-show="asesor.email!='monitor@salud-digna.org'">{{asesor.nombre}}</option> 
          </select> -->
          <v-select placeholder="Buscar usuario" :options="asesores" label="nombre"  v-model="destino_usuario">
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                  No se encontró un usuario llamado: <em>{{ search }}</em>.
              </template>
              <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
            </template>
          </v-select>
        </div>
        <div class="form-group">
          <label for="tipo">Categoría</label>
          <div class="custom-control custom-radio">
            <input type="radio" id="customRadio1" v-model="categoria" name="customRadio" :value="1" class="custom-control-input">
            <label class="custom-control-label" for="customRadio1">Saludo</label>
          </div>
          <!-- <div class="custom-control custom-radio">
            <input type="radio" id="customRadio2" v-model="categoria" name="customRadio" :value="2" class="custom-control-input">
            <label class="custom-control-label" for="customRadio2">Redimientos</label>
          </div> -->
          <div class="custom-control custom-radio">
            <input type="radio" id="customRadio3" v-model="categoria" name="customRadio" :value="3" class="custom-control-input">
            <label class="custom-control-label" for="customRadio3">Avisos de clínicas</label>
          </div>
          <div class="custom-control custom-radio">
            <input type="radio" id="customRadio4" v-model="categoria" name="customRadio" :value="4" class="custom-control-input">
            <label class="custom-control-label" for="customRadio4">Felicitaciones</label>
          </div>
          <div class="custom-control custom-radio">
            <input type="radio" v-model="categoria" id="customRadio5" :value="5" name="customRadio" class="custom-control-input">
            <label class="custom-control-label" for="customRadio5">Entrar a un enlace</label>
          </div>
        </div>
        <div class="form-group" v-if="categoria==5">
          <label for="titulo">Enlace</label>
          <input type="text" v-model="enlace" class="form-control" id="titulo" placeholder="https://salud-digna.org">
        </div>
        <!-- <div class="form-check mb-2">
          <input class="form-check-input" v-model="programado" type="checkbox" value="" id="defaultCheck1">
          <label class="form-check-label" for="defaultCheck1">
            Programada
          </label>
        </div> -->
        <div class="row" v-show="programado">
          <div class="col">
            <div class="form-group">
              <label for="titulo">Fecha de salida</label>
              <input type="date" v-model="fecha_inicio" class="form-control" id="titulo" placeholder="Tema">
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="titulo">Fecha de vencimiento</label>
              <input type="date" v-model="fecha_final" class="form-control" id="titulo" placeholder="Tema">
            </div>
          </div>
        </div>
        <button class="btn btn-success float-right" @click="darDeAlta">Crear</button>
      </div>
      <div class="col mt-3">
        <table class="table table-hover table-reflow table-bordered">
        <thead class="thead-light">
            <tr>
              <td>Notificaciones activas <button class="btn btn-sm btn-info ml-5" @click="notificacionActiva">Actualizar</button></td>
              <td>Opciones</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="notificacion in notificaciones" :key="notificacion.id">
                <td>
                  <div class="Notificacion">
                    <div class="IcoNot bg-secondary"><font-awesome-icon :icon="[(1>2)?'far':'fas','bell']" /></div>
                    <div class="texto">
                      <h5 class="h6">{{notificacion.nombre}}</h5>
                      <p>{{notificacion.descripcion}}</p>
                      <span>Enviado por: <i>{{notificacion.remitente_nombre}}</i></span>
                    </div>
                  </div>
                </td>
                <td>
                  <button v-if="notificacion.id!=0" class="btn btn-danger btn-sm" @click="darDeBaja(notificacion.id)">Dar de baja</button>
                </td>          
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBell, faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { faBell as Campana, faCheckCircle, faHandPaper, faChartBar, faHospital, faGrinStars } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faBell, faCheckCircle, Campana,faHandPaper, faChartBar, faHospital, faGrinStars, faPaperclip )
import push from 'push.js'
import SocketIo from 'socket.io-client'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import asesorService from '../../services/asesor'
export default {
  name:'Notificaciones',
  data() {
    return {
      asesor: JSON.parse(sessionStorage.getItem('sesion')),
      asesores:null,
      programado:false,
      enlace:null,
      titulo:null,
      descripcion:null,
      remitente:0,
      tipo:0,
      categoria: 1,
      fecha_inicio:null,
      fecha_final:null,
      destino:1,
      destino_usuario:null,
      destino_grupo:null,
      notificaciones:null
    }
  },
  components:{
    vSelect,
    FontAwesomeIcon
  },
  mounted(){
    this.listaAsesores()
    this.notificacionActiva()
    this.remitente=this.asesor.nombre
  },
  methods:{
    notificar(a, b){
      push.create(a, {
        body: b,
        icon: 'https://sd-storage.sfo2.digitaloceanspaces.com/PLATAFORMA-INBOX/imagenes/asesores/0.png',
        timeout: 8000,
        onClick: function () {
            window.focus();
            this.close();
        }
      });
    },
    darDeAlta(){
      let link = ''
      if(this.categoria==5){
        if(this.enlace.indexOf('https://')==-1){
          link='https://'+this.enlace
        }else{
          link=this.enlace
        }
      }
      this.socket = SocketIo.connect('https://socket-ws.salud-digna.site')
      this.socket.emit("sendNotificacion", {
        nombre: this.titulo,
        descripcion: this.descripcion,
        enlace: link,
        tipo: this.tipo,
        categoria: parseInt(this.categoria),
        fecha_inicio: this.fecha_inicio,
        fecha_fin: this.fecha_final,
        remitente_nombre: this.remitente,
        remitente_asesorid: this.asesor.id,
        destino_tipo: parseInt(this.destino),
        destino_asesorid: (parseInt(this.destino)==3?this.destino_usuario.id:null),
        destino_grupoid: (parseInt(this.destino)==2?this.asesor.grupo_id:null),
      })
      this.notificar(('Se envio Notificacion'), this.titulo)
      this.ponerDefault()
    },
    listaAsesores() {
      asesorService.listaDeAsesores().then(respa => {
        this.asesores = respa
      })
    },
    notificacionActiva(){
      asesorService.notificaciones().then(respuesta => {
        if(respuesta.length!=0){
          this.notificaciones=respuesta
        }else{
          this.notificaciones=[{ "createdAt": "2020-12-14 16:01:17", "updatedAt": "2020-12-14 16:01:17", "id": 0, "nombre": "No hay notificaciones", "descripcion": "Si gustas crear una notificación creala desde el lado izquierdo", "enlace": null, "tipo": 2, "categoria": 3, "fecha_inicio": null, "fecha_fin": null, "remitente_nombre": "Plataforma Inbox", "remitente_asesorid": 3, "destino_tipo": 1, "destino_asesorid": null, "destino_grupoid": null, "estatus": 1 }]
          
        }
      })
    },
    async darDeBaja(id){
      await asesorService.bajaNotificacion(id)
      this.notificacionActiva()
    },
    ponerDefault(){
      this.programado=false
      this.enlace=null
      this.titulo=null
      this.descripcion=null
      this.remitent=0
      this.tipo=0
      this.categoria= 1
      this.fecha_inicio=null
      this.fecha_final=null
      this.destino=1
      this.destino_usuario=null
      this.destino_grupo=null
    }
  },
  
  
}
</script>