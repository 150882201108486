<template>

<div class>
    <div class="row">
        <div   v-if="sessionStorageUser.grupo_id!=6 && sessionStorageUser.grupo_id!=10 && sessionStorageUser.grupo_id!=9" class="col-md-3" style="padding:1rem">
            <v-select class="pypCombo" placeholder="Buscar por servicio" :options="servicios" :value.sync="servicios.id" label="nombre"  v-model="servicioSeleccionadoS">
                <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                        No se encontró copy de: <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
                </template>
            </v-select>
            
        </div>
        <div  v-if="sessionStorageUser.grupo_id!=6 && sessionStorageUser.grupo_id!=10  && sessionStorageUser.grupo_id!=9" class="col-md-3" style="padding:1rem">
            <v-select  class="pypCombo" placeholder="Buscar por estatus" :options="estatus" label="estatus"  v-model="estatusSeleccionado">
                <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                        No se encontró copy de: <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
                </template>
            </v-select>
        </div>
    </div>
    <table class="table table-hover table-reflow table-bordered">
        <thead class="thead-light">
            <tr>
                <th v-if="sessionStorageUser.grupo_id!=6  && sessionStorageUser.grupo_id!=10  && sessionStorageUser.grupo_id!=9">Servicio</th>
                <th>Nombre</th>
                <th>Msj</th>
                <th>Caption</th>
                <th>Tema</th>
                <th>Estatus</th>
                <th>Nuevo<button class="btn btn-primary btn-sm" @click="nuevo()"><font-awesome-icon icon="plus"/></button></th>
                </tr>
        </thead>
        <tbody>
            <tr v-for="copy in copys" :key="copy.id" v-show="(  (servicioSeleccionadoS == null && estatusSeleccionado == null) || 
                                                                (servicioSeleccionadoS == null && copy.status == estatusSeleccionado.id) || 
                                                                (copy.servicio_nombre.includes(servicioSeleccionadoS === null ? '' : servicioSeleccionadoS.nombre) && estatusSeleccionado == null) ||
                                                                (copy.servicio_nombre.includes(servicioSeleccionadoS === null ? '' : servicioSeleccionadoS.nombre) && copy.status == estatusSeleccionado.id))">
            
                
                <th v-if="sessionStorageUser.grupo_id!=6  && sessionStorageUser.grupo_id!=10  && sessionStorageUser.grupo_id!=9" scope="row"><b class="h5">{{copy.servicio_nombre}}</b></th>
                <th scope="row"><b class="h5 col-md-2">{{copy.nombre}}</b></th>
                <td v-if="copy.tipo_msj=='TEXT'" class="h5 col-sm-4">{{copy.msj}}</td>
                <td v-else-if="copy.tipo_msj=='PDF'" class="h5 col-sm-2"><font-awesome-icon icon="file-pdf" height="700px" width="550px"/></td>
                <td v-else class="imagen"><img :src="copy.msj" height="200px" width="150px"></td>
                <td class="h5">{{copy.caption === null ? '' : copy.caption}}</td>
                <td class="h5">{{copy.tipo}}</td>
                <td class="h5">
                    <a v-if="copy.status==0" class="pt-5 my-2" style="font-size: 1em">
                    Inactivo
                    </a>
                    <a v-if="copy.status==1" class="pt-5 my-2" style="font-size: 1em">
                    Activo
                    </a></td>
                <td>
                    <button class="btn btn-warning btn-sm" @click="editar(copy)"><font-awesome-icon icon="edit"/></button><button class="btn btn-danger btn-sm" @click="eliminar(copy)"><font-awesome-icon icon="trash"/></button>
                </td>
                
            </tr>
        </tbody>
    </table>
    
    <Sidebar :visible="panelLateral" position="right" class="lateralGeneral">
        <h4 v-if="nuevoCopy==true"><b>Nuevo Copy</b></h4>
        <h4 v-else><b>Editar Copy</b></h4>
        

        <p class="h6 mt-3">Seleccione el tipo de Copy:</p>
            <select v-model="seleccionado.tipo_msj" class="custom-select custom-select-sm">
                <option :value="'TEXT'">Texto</option>
                <option :value="'IMG'">Imagen</option>
                <option :value="'PDF'">PDF</option>
            </select>
        <div v-if="seleccionado.tipo_msj=='TEXT'"> <!-- ----TEXTO -->
            <div class="contenidoAsesorParametros mt-4">
            <div class="input-group input-group-sm mb-2">
                <div v-if="sessionStorageUser.grupo_id!=6  && sessionStorageUser.grupo_id!=10  && sessionStorageUser.grupo_id!=9" class="input-group-prepend">
                    <span class="input-group-text" style="width: 80px;">Servicio</span>
                    <div>
                        <select v-model="seleccionado.servicio_id" class="custom-select custom-select-sm" style=" width:300px;">
                            <option disabled value="">Seleccione un servicio</option>
                            <option v-for="servicio in servicios" :value="servicio.id" :key="servicio.id"  >{{servicio.nombre}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width: 100px;">Nombre</span>
                    <input v-model="seleccionado.nombre" type="text" class="form-control" size="40" >
                </div>
            </div>
            <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width: 100px;">Tema</span>
                    <input v-model="seleccionado.tipo" type="text" class="form-control" size="40">
                </div>
            </div>
            <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width: 100px;" >Mensaje</span>
                    <textarea v-model="seleccionado.msj" type="text" class="form-control" rows="7" cols="40"></textarea>
                </div>
            </div>
            <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text">Estatus</span>
                    <div class="form-check">
                            <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="seleccionado.status" checked>
                                <label class="custom-control-label" for="customSwitch1" ></label>
                            </div> 
                    </div>
                            
                </div>
            </div>
            <button class="btn btn-success btn-sm float-right mt-2" 
            :disabled="!seleccionado.nombre || !seleccionado.tipo || !seleccionado.msj || ( (sessionStorageUser.grupo_id != 6 && sessionStorageUser.grupo_id!=10  && sessionStorageUser.grupo_id!=9 ) && (!seleccionado.servicio_id || seleccionado.servicio_id == ''))" 
            @click="guardarTxt(seleccionado.nombre, seleccionado.tipo, seleccionado.msj, seleccionado.status, seleccionado.servicio_id, sessionStorageUser.id)">
            Guardar <font-awesome-icon icon="save"/></button>
            
            </div>
        
    </div>
    <div v-else-if="seleccionado.tipo_msj=='IMG'">     <!-- ------IMG -->
    <div class="contenidoAsesorParametros mt-4">
            <div class="input-group input-group-sm mb-2">
                <div v-if="sessionStorageUser.grupo_id!=6  && sessionStorageUser.grupo_id!=10  && sessionStorageUser.grupo_id!=9" class="input-group-prepend">
                    <span class="input-group-text" style="width: 80px;">Servicio</span>
                    <div>
                        <select v-model="seleccionado.servicio_id" class="custom-select custom-select-sm" style=" width:300px;">
                            <option disabled value="">Seleccione un servicio</option>
                            <option v-for="servicio in servicios" :value="servicio.id" :key="servicio.id"  >{{servicio.nombre}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width: 100px;">Nombre</span>
                    <input v-model="seleccionado.nombre" type="text" class="form-control" size="40">
                </div>
            </div>
            <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width: 100px;">Tema</span>
                    <input v-model="seleccionado.tipo" type="text" class="form-control" size="40">
                </div>
            </div>
            <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <div class="input-group mb-3">
                        <div class="custom-file">
                            <input type="file" v-on:change="documento($event)" class="custom-file-input" id="file" accept="image/*" size="40">
                            <label class="custom-file-label" for="file">Selecciona una imagen</label>
                        </div>
                    </div>
                    
                    <div id="imgpreview"></div>
                    <div class="text-success" id="estatusimgSucces"></div>
                    <div class="text-danger" id="estatusimgError"></div>
                </div>
            </div>
            <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width: 100px;">Mensaje</span>
                    <textarea v-model="seleccionado.caption" type="text" class="form-control" rows="7" cols="40"></textarea>
                </div>
            </div>
            <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text">Estatus</span>
                    <div class="form-check">
                            <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="seleccionado.status" checked>
                                <label class="custom-control-label" for="customSwitch1" ></label>
                                
                            </div> 
                    </div>
                            
                </div>
            </div>
            <button v-if=" seleccionado.tipo=='' || seleccionado.nombre=='' || this.nuevoCopy && !this.seleccionado.file || !seleccionado.nombre || !seleccionado.tipo" class="btn btn-success btn-sm float-right mt-2" disabled >Guardar <font-awesome-icon icon="save"/></button>
            <button v-else class="btn btn-success btn-sm float-right mt-2" @click="guardarImg(seleccionado.nombre,seleccionado.tipo,seleccionado.caption,seleccionado.status,seleccionado.servicio_id,sessionStorageUser.id)">Guardar <font-awesome-icon icon="save"/></button>        
            </div>
    </div>
    <div v-else-if="seleccionado.tipo_msj=='PDF'">
    <div class="contenidoAsesorParametros mt-4">
            <div class="input-group input-group-sm mb-2">
                <div v-if="sessionStorageUser.grupo_id!=6  && sessionStorageUser.grupo_id!=10 " class="input-group-prepend">
                    <span class="input-group-text" style="width: 80px;">Servicio</span>
                    <div>
                        <select v-model="seleccionado.servicio_id" class="custom-select custom-select-sm" style=" width:300px;">
                            <option disabled value="">Seleccione un servicio</option>
                            <option v-for="servicio in servicios" :value="servicio.id" :key="servicio.id"  >{{servicio.nombre}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width: 150px;">Nombre</span>
                    <input v-model="seleccionado.nombre" type="text" class="form-control" size="40">
                </div>
            </div>
            <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width: 150px;">Tema</span>
                    <input v-model="seleccionado.tipo" type="text" class="form-control" size="40">
                </div>
            </div>
            <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <div class="input-group mb-3">
                    <div class="custom-file">
                        <input :v-model="this.seleccionado.file"  type="file" @change="documento($event)" class="custom-file-input" id="file" accept="application/pdf" >
                        <label class="custom-file-label" for="file">Selecciona un archivo</label>
                    </div>
                    </div>
                    <div id="imgpreview"></div>
            <div class="text-success" id="estatusimgSucces"></div>
            
           <div class="text-danger" id="estatusimgError"></div>
                </div>
            </div>
            <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width: 150px;">Nombre de Archivo</span>
                    <input v-model="seleccionado.caption" type="text" class="form-control" size="40">
                </div>
            </div>
            <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text">Estatus</span>
                    <div class="form-check">
                            <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="seleccionado.status" checked>
                                <label class="custom-control-label" for="customSwitch1" ></label>
                                
                            </div> 
                    </div>
                            
                </div>
            </div>
            <button v-if=" seleccionado.nombre=='' || seleccionado.caption=='' || this.nuevoCopy && !this.seleccionado.file || !seleccionado.nombre || !seleccionado.caption " class="btn btn-success btn-sm float-right mt-2" disabled >Guardar <font-awesome-icon icon="save"/></button>
            <button v-else class="btn btn-success btn-sm float-right mt-2" @click="guardarPdf(seleccionado.nombre,seleccionado.tipo,seleccionado.caption,seleccionado.status,seleccionado.servicio_id,sessionStorageUser.id)">Guardar <font-awesome-icon icon="save"/></button>        
            </div>
    </div>
        
        <button class="btn btn-secondary btn-sm float-left mt-2" @click="cerrar()">Cerrar</button>
         
    </Sidebar>
    
</div>


</template>


<script>
import Sidebar from 'primevue/sidebar'
import asesorService from "../../services/asesor"
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
import {
    library
} from "@fortawesome/fontawesome-svg-core";
import {
    faTimes,faReply,faFileExcel,faTrash,faFilePdf
} from "@fortawesome/free-solid-svg-icons";
library.add(faTimes, faReply, faFileExcel,faTrash,faFilePdf);

export default{
    data(){
        return{
            panelLateral:false,
            nuevoCopy:false,
            copys:null,
            selectRespuestas:null,
            seleccionado:{},
            sessionStorageUser: JSON.parse(sessionStorage.getItem('sesion')),
            estatusSeleccionado:null,
            servicioSeleccionadoS:null,
            servicios:null,
            imgpreview:'',
            estatus:[{id: 0, estatus: "Inactivo"},{id: 1, estatus: "Activo"}]
        }
    }, 
    components:{
        vSelect,
        FontAwesomeIcon,
        Sidebar
    },
    mounted() {
        this.inicio(),
        this.getServicios()  
        
         
    },
    
    methods: { 
        inicio(){
                asesorService.getTodoPorServicio().then(respuestas => {
                        return this.copys = respuestas
                })
            let images = document.querySelectorAll(".imagen")
            images.forEach((image) => {
            image.src = image.src + new Date().getTime();
            });
        },
        nuevo(){
            this.seleccionado = {
                tipo_msj: null
            }
            this.nuevoCopy=true
            this.panelLateral=!this.panelLateral
        },
        guardarTxt(a,b,c,d,e,f){
            if(this.nuevoCopy){
                    asesorService.crearCopyTxt(a,b,c.trim(),d === undefined ? 1 : d === true ? 1 : 0,e === undefined ? 1 : e,f).then(res=>{
                        if(res){
                            this.cerrar()
                            this.inicio()
                        }
                    })
            }else{
                let formData = new FormData();
                formData.append("nombre", a.trim());
                formData.append("tipo", b.trim());
                formData.append("tipo_msj", this.seleccionado.tipo_msj);
                formData.append("caption", '');
                formData.append("msj", c.trim());
                formData.append("status", d === true ? 1 : d === false ? 0 : this.seleccionado.status);
                formData.append("servicio_id", e === undefined ? 1 : e);
                formData.append("asesor_id", f);

                asesorService.editarCopy(this.seleccionado.id, formData).then(resp =>{
                    if(resp){
                        this.cerrar()
                        this.inicio()
                    }
                })
            }
        },
        guardarImg(a,b,c,d,e,f){
            if(this.nuevoCopy){
                let formData = new FormData();
                //seleccionado.nombre,seleccionado.tipo,seleccionado.caption,seleccionado.status,seleccionado.servicio_id,sessionStorageUser.id
                formData.append("upload", this.seleccionado.file);
                formData.append("tipo", b.trim());
                formData.append("nombre", a.trim());
                formData.append("tipo_msj", this.seleccionado.tipo_msj);
                formData.append("caption", c.trim() == '' ? null : c.trim());
                formData.append("status",  d === undefined ? 1 : d ? 1 : 0);
                formData.append("servicio_id", e === undefined ? 1 : e);
                formData.append("asesor_id", f);
                console.log(formData)
                asesorService.crearCopyImg(formData).then(res=>{
                    if(res){
                        this.cerrar()
                        this.inicio()
                    }
                })
            }else{
                if(this.seleccionado.caption==null){
                    this.seleccionado.caption=''
                }
                let formData = new FormData();
                formData.append('upload', this.seleccionado.file)
                formData.append('nombre', this.seleccionado.nombre.trim())
                formData.append("tipo_msj", this.seleccionado.tipo_msj);
                formData.append('tipo', this.seleccionado.tipo.trim())
                formData.append('caption', this.seleccionado.caption.trim() === '' ? null : this.seleccionado.caption.trim())
                formData.append('status', d === true ? 1 : d === false ? 0 : this.seleccionado.status)
                formData.append('servicio_id', this.seleccionado.servicio_id ? 1 :this.seleccionado.servicio_id)
                formData.append('asesor_id', this.sessionStorageUser.id)
                asesorService.editarCopy(this.seleccionado.id, formData).then(resp =>{
                    if(resp){
                        this.cerrar()
                        this.inicio()
                    }
                })
            }
            },
        guardarPdf(a,b,c,d,e,f){
            if(this.nuevoCopy){
                let formData = new FormData();
                formData.append("upload", this.seleccionado.file);
                formData.append("tipo", b.trim());
                formData.append("nombre", a.trim());
                formData.append("tipo_msj", this.seleccionado.tipo_msj);
                formData.append("caption", c.trim() == '' ? null : c.trim());
                formData.append("status", 1);
                formData.append("servicio_id", e === undefined ? 1 : e);

                formData.append("asesor_id", f);

                asesorService.crearCopyImg(formData).then(res=>{
                if(res){
                    this.cerrar()
                    this.inicio()
                }
            })

            }else{
                let formData = new FormData();
                formData.append('upload', this.seleccionado.file)
                formData.append('nombre', this.seleccionado.nombre.trim())
                formData.append("tipo_msj", this.seleccionado.tipo_msj);
                formData.append('tipo', this.seleccionado.tipo.trim())
                formData.append('caption', this.seleccionado.caption.trim() === '' ? null : this.seleccionado.caption.trim())
                formData.append('status', d === true ? 1 : d === false ? 0 : this.seleccionado.status)
                formData.append('servicio_id',  this.seleccionado.servicio_id ? 1 :this.seleccionado.servicio_id)
                formData.append('asesor_id', this.sessionStorageUser.id)
                
                asesorService.editarCopy(this.seleccionado.id, formData).then(resp =>{
                    if(resp){
                        this.cerrar()
                        this.inicio()
                    }
                })
            }

        },
        documento(event){
            let div2 = document.getElementById('estatusimgError')
            if (event.target.files && event.target.files.length) {
                    let filesize=event.target.files[0].size
                    if(filesize>1000000){
                        div2.innerHTML= "La imagen no debe de superar 1 MB"
                    }else{
                        this.seleccionado.file = event.target.files[0];
                        this.$forceUpdate()
                    }
            }else{
                this.seleccionado.file = null
                this.$forceUpdate()
            }
        },
        getServicios(){
            asesorService.getServicios().then(resp => {
                 
                this.servicios = resp
                
            })
        },
        eliminar(cop){
            asesorService.desactivarCopy(cop.id).then(resp =>{
                    if(resp){
                        this.cerrar()
                        this.inicio()
                    }
                })
        },
        editar(copy){ 
            this.seleccionado=Object.assign({}, copy)
            this.panelLateral=true
            
        },
        cerrar(){
            this.nuevoCopy = false
            this.seleccionado.file = null
            this.panelLateral=false
        },
            
        },
        
    }

</script> 