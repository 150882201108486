<template>
  <div class="container-fluid px-0">
    <div class="row">
      <div class="col-12 row d-flex barraInfo">
        <div class="col-12 col-md-12">
          <span>Asesores en linea: 🟢</span>  <b>{{conectados}} </b>
        </div>
        <div class="col-12 col-md-12">
          <span>Asesores fuera de linea: 🔴</span>  <b>{{desconectados}} </b>
        </div>
        <div class="col-12 col-md-12">
          <span>Asesores especiales:</span>  <b>{{especiales}} </b>
        </div>
      </div>
      <div v-for="asesor in asesores" :key="asesor.id" class=" media px-0" :class="{'asesorMonitor': asesor.rol_id==3, 'admMonitor': asesor.rol_id!=3, 'listaMonitor':tipoLista==0, 'muchosAsesores':asesores.length>10}" >
        
        <img class="imgPerfilAsesor d-flex align-self-center" :class="{'rounded-circle': asesor.asesor_id!=0}" :src="asesor.asesor_id==0?'https://sd-storage.sfo2.digitaloceanspaces.com/PLATAFORMA-INBOX/imagenes/asesores/0.png':'https://sd-storage.sfo2.digitaloceanspaces.com/PLATAFORMA-INBOX/imagenes/asesores/'+asesor.asesor_id+'.jpg'" :alt="asesor.asesor_id" />
        <div>
          <div class="rounded-circle automatizacionMonitor" :class="{'bg-info': asesor.automatizacion, 'bg-secondary':!asesor.automatizacion}"  v-tooltip="asesor.automatizacion?(asesor.asesor+' tiene la automatización encendida.'):(asesor.asesor+' tiene la automatización apagada')" ><font-awesome-icon :spin="asesor.automatizacion" icon="cog"/></div>
          
          
        </div>
        <div class="ml-3 d-flex align-self-center" >
          <div class="" >
            <strong class="h5 ">{{asesor.asesor}}</strong>                               
            <br>
            <p class="mb-0" v-show="asesor.calificacion_deseada!=0" v-tooltip.left="'A '+asesor.asesor+' le han contestado '+((asesor.calificacion_deseada/5)>1?(asesor.calificacion_deseada/5)+' encuestas':'1 encuesta')"><span> {{asesor.calificacion_obtenida}}/{{asesor.calificacion_deseada}} ⭐</span> <b>{{asesor.porcentaje_calificacion}}%</b></p>
            <p class="mb-0"><span class="h5" v-show="asesor.pacientes_whatsapp!=0"><i class="icon-whatsapp h5 text-whatsapp"></i>: {{asesor.pacientes_whatsapp}}</span> <span class="h5" v-show="asesor.pacientes_facebook!=0"><i class="icon-messenger-new h6 text-primary"></i>: {{asesor.pacientes_facebook}}</span> <span class="h5" v-show="asesor.pacientes_nicaragua!=0"> <img class="bandera" src="../../../assets/images/ni.svg" height="13px" alt="Bandera de Nicaragua" />: {{asesor.pacientes_nicaragua}}</span></p>
            <div v-if="(asesor.rol_id==3)" v-show="(!asesor.especial)" class="progress widthBar" v-tooltip.top="asesor.suma==meta?(asesor.asesor+' ha cumplido su meta diaría'):asesor.suma<meta?(asesor.asesor+' le hacen falta solo '+(meta-asesor.suma)+' para cumplir su meta'):(asesor.asesor+' ha cumplido su meta diaría con '+(asesor.suma-meta)+' pacientes extras')">
              <div class="progress-bar progress-bar-animated bg-success" :class="{'progress-bar-striped': asesor.online}" role="progressbar" :aria-valuenow="asesor.pacientes_whatsapp+asesor.pacientes_facebook+asesor.pacientes_nicaragua" aria-valuemin="0" aria-valuemax="100" :style="'width: '+asesor.totalW+'%'"></div>
              <div class="progress-bar progress-bar-animated" :class="{'progress-bar-striped': asesor.online}" role="progressbar" :aria-valuenow="asesor.pacientes_whatsapp+asesor.pacientes_facebook+asesor.pacientes_nicaragua" aria-valuemin="0" aria-valuemax="100" :style="'width: '+asesor.totalF+'%'"></div>
              <div class="progress-bar progress-bar-animated bg-nica" :class="{'progress-bar-striped': asesor.online}" role="progressbar" :aria-valuenow="asesor.pacientes_whatsapp+asesor.pacientes_facebook+asesor.pacientes_nicaragua" aria-valuemin="0" aria-valuemax="100" :style="'width: '+asesor.totalWN+'%'"></div>
              <span class="metaMonitor">Meta: {{meta}}</span>
            </div>

          </div>
        </div>
        <div class="rounded-circle onlinenMonitor float-right"  v-tooltip.left="asesor.online?(asesor.asesor+' se encuentra en Linea'):(asesor.asesor+' se desconectó '+ asesor.fin)" ><span v-if="asesor.online" class="parpadea">🟢</span><span v-else>🔴</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from 'primevue/tooltip'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlay, faPause, faCog, faMoon,faSmile, faAngry,faLaugh,faFrown,faMeh,faHeadSideMask,faKissBeam } from '@fortawesome/free-solid-svg-icons'
library.add(faPlay, faPause, faCog, faMoon,faSmile, faAngry,faLaugh,faFrown,faMeh,faHeadSideMask,faKissBeam)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import SocketIo from 'socket.io-client'
export default {
  name: 'Asesores',
  data(){
    return{
      sesion: JSON.parse(sessionStorage.getItem('sesion')),
      hoy: new Date(),
      meta: 370,
      antes:null,
      socket: null,
      orden:0,
      conectados:-1,
      desconectados: -1,
      especiales: -1,
      especialesD: -1,
      asesoresDeApoyo:[38,25,47,20,12,18,14,26,29,33,27,24,23,14,10,67,72,54,58,80],
      asesores: [ { "asesor_id": 0, "asesor": "Esperando asesores...", "rol_id": 3, "ultima_conexion": null, "automatizacion": false, "calificacion_obtenida": -1, "calificacion_deseada": -1, "porcentaje_calificacion": "00.00", "pacientes_facebook": -1, "pacientes_whatsapp": "-1", "pacientes_nicaragua": "-1" }]
    }
  },
  props: {
      tipoLista:null,
      
  },
  directives: {
    'tooltip': Tooltip
  },
  created(){
    this.$bus.$off('cambiarFiltro')
    this.$bus.$on('cambiarFiltro', filtro => {
      this.orden=filtro
      this.cambioOrden(this.socket)
    })
  },
  mounted(){
      this.get()
  },
  components:{
    'fontAwesomeIcon': FontAwesomeIcon
  },
  methods: {
    cambioOrden(s){
      this.asesores=s
      
      switch(this.orden){
        case 0:
          this.asesores.sort((a,b) =>{
            return b.total - a.total
          })
          this.asesores.sort((a,b) =>{
            return (a.online === b.online)? 0 : a.online? -1 : 1
          })
          this.asesores=this.asesores.filter(asesor=> !asesor.especial)
          this.asesores=this.asesores.filter(asesor=> asesor.rol_id==3)
          break
        case 1:
          this.asesores=this.asesores.filter(asesor=> !asesor.especial)
          this.asesores.sort((a,b) =>{
            return a.total - b.total
          })
          this.asesores.sort((a,b) =>{
            return (a.online === b.online)? 0 : a.online? -1 : 1
          })
          this.asesores=this.asesores.filter(asesor=> asesor.rol_id==3)
          break
        case 2:
          this.asesores=this.asesores.filter(asesor=> !asesor.especial)
          this.asesores=this.asesores.filter(asesor=> asesor.pacientes_whatsapp)
          this.asesores.sort((a,b) =>{
            return b.pacientes_whatsapp - a.pacientes_whatsapp
          })
          this.asesores.sort((a,b) =>{
            return (a.online === b.online)? 0 : a.online? -1 : 1
          })
          this.asesores=this.asesores.filter(asesor=> asesor.rol_id==3)
          break
        case 3:
          this.asesores=this.asesores.filter(asesor=> !asesor.especial)
          this.asesores=this.asesores.filter(asesor=> asesor.pacientes_facebook)
          this.asesores.sort((a,b) =>{
            return b.pacientes_facebook - a.pacientes_facebook
          })
          this.asesores.sort((a,b) =>{
            return (a.online === b.online)? 0 : a.online? -1 : 1
          })
          this.asesores=this.asesores.filter(asesor=> asesor.rol_id==3)
          break
        case 4:
          this.asesores=this.asesores.filter(asesor=> !asesor.especial)
          this.asesores.sort((a,b) =>{
            return parseFloat(b.porcentaje_calificacion) - parseFloat(a.porcentaje_calificacion)
          })
          this.asesores.sort((a,b) =>{
            return (a.online === b.online)? 0 : a.online? -1 : 1
          })
          this.asesores=this.asesores.filter(asesor=> asesor.rol_id==3)
          break
        case 5:
          this.asesores=this.asesores.filter(asesor=> !asesor.especial)
          this.asesores.sort((a,b) =>{
            return parseFloat(a.porcentaje_calificacion) - parseFloat(b.porcentaje_calificacion)
          })
          this.asesores.sort((a,b) =>{
            return (a.online === b.online)? 0 : a.online? -1 : 1
          })
          this.asesores=this.asesores.filter(asesor=> asesor.rol_id==3)
          break
        case 6:
          this.asesores=this.asesores.filter(asesor=> !asesor.especial)
          this.asesores=this.asesores.filter(asesor=> asesor.online)
          this.asesores=this.asesores.filter(asesor=> asesor.rol_id==3)
          break
        case 7:
          this.asesores=this.asesores.filter(asesor=> !asesor.especial)
          this.asesores=this.asesores.filter(asesor=> !asesor.online)
          this.asesores=this.asesores.filter(asesor=> asesor.rol_id==3)
          break
        case 8:
          break
        case 9:
          this.asesores=this.asesores.filter(asesor=> asesor.especial || asesor.rol_id==2)
          break
        case 10:
          this.asesores.sort((a,b) =>{
            if (a.pacientes_whatsapp < b.pacientes_whatsapp){
              return -1
            }
            if (a.pacientes_whatsapp > b.pacientes_whatsapp){
              return 1
            }
            return 0
          })
          break
      }

    },
    get(){

      if(this.sesion.grupo_id==9){
        window.location.href = '/dashboard/reportes'
      }

      let data = {
          query: {
              "Tipo": "1"
          }
      }

      var socket = SocketIo.connect('https://socket-ws.salud-digna.site', data)
      socket.on('monitorAsesor',  (resp) => {
        var moment = require('moment')
        moment.locale('es')
        this.conectados=0
        this.especiales=0
        this.especialesD=0
        this.desconectados=0
        resp.map(x =>{
          x.pacientes_whatsapp= parseInt(x.pacientes_whatsapp)
          x.pacientes_facebook= parseInt(x.pacientes_facebook)
          x.pacientes_nicaragua= parseInt(x.pacientes_nicaragua)
          x.total=((x.pacientes_facebook+x.pacientes_whatsapp+x.pacientes_nicaragua)*100)/this.meta
          x.suma=(x.pacientes_facebook+x.pacientes_whatsapp+x.pacientes_nicaragua)
          x.totalW=((+x.pacientes_whatsapp)*100)/this.meta
          x.totalWN=((+x.pacientes_nicaragua)*100)/this.meta
          x.totalF=((x.pacientes_facebook)*100)/this.meta
          x.ultima_conexion = moment(x.ultima_conexion)
          x.fin = moment(x.fin_sesion, 'YYYY-MM-DD HH:mm').calendar()
          if((x.ultima_conexion.diff(moment(), 'days')==0)&&(x.fin_sesion==null)){
              x.online=true
              if(this.asesoresDeApoyo.includes(x.asesor_id)){
                this.especiales=this.especiales+1
              } else{
                this.conectados=this.conectados+1
              }
          }else{
              x.online=false
              if(this.asesoresDeApoyo.includes(x.asesor_id)){
                this.especialesD=this.especialesD+1
              }else{
                this.desconectados=this.desconectados+1
              }
          }
          //Asesores sin Meta definida
          if(this.asesoresDeApoyo.includes(x.asesor_id)){
            x.especial=true
          } else{
            x.especial=false
          }
          return x
        })
        resp.sort((a,b) =>{
          return b.total - a.total
        })
        // resp.sort((a,b) =>{
        //   return (b.online === a.online)? 0 : b.online? -1 : 1
        // })
        this.socket=resp
        this.cambioOrden(this.socket)
        this.antes=this.orden
          
      })
    }
  },
}
</script>
