import api from './apiHSM'
let service = {}
let config = {
  headers: {
  "Content-Type": "multipart/form-data"
  }
}



service.uploadFile = function (formData) {
    return api.post('api/envioExcel', formData, config).then(res => res.data)
}
service.getAllHsm = function() {
    return api.get('/api/getHsmByName?nombre=').then(res => res)
}
service.getByFilterHsm = function(filtro) {
    return api.get('/api/gethsm?id='+filtro).then(res => res)
}
export default service