import api from './api'
let reportes = {}

reportes.cargarPacientesAtendidos = function(opcion) {
    return api.get('/reporte/pacientesatendidos?opcion=' + opcion).then(res => res.data)
}

reportes.getPacientesAtendidos = function() {
    return api.get('/reporte/pacientesatendidosasesor').then(res => res.data)
}

reportes.getChats = function() {
    return api.get('/chats').then(res => res.data).catch(err => {
        if (401 === err.response.status) {
            window.location = '/'
            console.log('Token Expirado ', err)
        }
    })
}
reportes.getChecador = function(fecha_inicial, fecha_final){
    return api.get(`/reporte/checador?tipo=LOGIN&fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data).catch(err => {
        if (401 === err.response.status) {
            window.location = '/'
            this.$gtag.event('tokenExpirado',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': '12hrs conectado',
                'value': 1
            })
        }
    })
}
reportes.getFelizometroAsesor= function(fecha_inicial, fecha_final){
    return api.get(`/reporte/obtenerFelizometroAsesoresPorFechas?fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)/* .catch(err => {
        if (401 === err.response.status) {
            window.location = '/'
            this.$gtag.event('tokenExpirado',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': '12hrs conectado',
                'value': 1
            })
        }
    }) */
}

reportes.reporte = function(fecha_inicial, fecha_final, rep) {
    let respuesta = ''
    switch (rep) {
        case 1:
            respuesta = api.get('/reporte/pacientesatendidos?' + `fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}&opcion=${rep}`).then(res => res.data)
            break;
        case 2:
            respuesta = api.get('/reporte/pacientesatendidosasesor?' + `fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
            break
        case 3:
            respuesta = api.get('/reporte/pacientespordia?' + `fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
            break
        case 4:
            respuesta = api.get('/reporte/pacientespordiaasesor?' + `fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
            break
        case 5:
            respuesta = api.get('/reporte/detpacientespordiaasesor?' + `fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
            break
        case 6:
            respuesta = api.get('/reporte/motivostransferencias?' + `fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
            break
        case 7:
            respuesta = api.get('/reporte/detmotivostransferencias?' + `fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
            break
        case 47:
            respuesta = api.get('/reporte/tiempos_asesor?' + `fecha_inicial=${fecha_inicial}`).then(res => res.data)
            break
    }

    return respuesta

}
reportes.getHistorialChats = function(fecha_inicial, fecha_final, origen) {
    return api.get(`/chatshistorico?fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}&origen=${origen}`).then(res => res.data).catch(err => {
        if (401 === err.response.status) {
            window.location = '/'
            this.$gtag.event('tokenExpirado',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': '12hrs conectado',
                'value': 1
            })
        }
    })
}

reportes.tiempoEsperaByAsesor = function(fecha_inicial) {
    return api.get(`/reporte/tiempos_asesor?fecha_inicial=${fecha_inicial}`).then(res => res.data).catch(err => {
        if (401 === err.response.status) {
            window.location = '/'
            this.$gtag.event('tokenExpirado',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': '12hrs conectado',
                'value': 1
            })
        }
    })
}

reportes.getTraspasos = function(origen) {
    return api.get(`/traspasos?origen=${origen}`).then(res => res.data)
}

reportes.mensajesPacientes = function(fecha_inicial, fecha_final, rep) {
    return api.get('/reporte/mensajesusuario?' + `fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}&opcion=${rep}`).then(res => res.data)
}

reportes.mensjaesAsesores = function(fecha_inicial, fecha_final, rep) {
    return api.get('/reporte/mensajesasesor?' + `fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}&opcion=${rep}`).then(res => res.data)
}

reportes.pacientesAtendidos = function(fecha_inicial, fecha_final, rep) {
    return api.get('/reporte/pacientespordia?' + `fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}&opcion=${rep}`).then(res => res.data)
}

reportes.felizometroReporte = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/felizometro?' +`inicio=${fecha_inicial}&fin=${fecha_final}`).then(res => res.data)
}
reportes.felizometro = function() {
    return api.get('/reporte/felizometro').then(res => res.data)
}

reportes.felizometroComentarios = function (fecha_inicial, fecha_final) {
    return api.get('/reporte/felizometrocomentarios?' + `inicio=${fecha_inicial}&fin=${fecha_final}`).then(res => res.data)
}

reportes.reporteEncuestasContestadas = function (fecha_inicial, fecha_final) {
    return api.get('/reporte/encuestascontestadas?' + `inicio=${fecha_inicial}&fin=${fecha_final}`).then(res => res.data)
}

reportes.reporteEncuestasContestadasCalcuGeneral = function (fecha_inicial, fecha_final) {
    return api.get('/reporte/encuestascontestadascalcugeneral?' + `inicio=${fecha_inicial}&fin=${fecha_final}`).then(res => res.data)
}

reportes.reporteEncuestasContestadasCalcuDetalle = function (fecha_inicial, fecha_final) {
    return api.get('/reporte/encuestascontestadascalcudetalle?' + `inicio=${fecha_inicial}&fin=${fecha_final}`).then(res => res.data)
}


reportes.horaPico = function() {
    return api.get('/reporte/horaspico').then(res => res.data)
}
reportes.monitorHR = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/monitorhoras?'+ `fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.horaAtencion = function() {
    return api.get('/reporte/horasatencion').then(res => res.data)
}
reportes.horaCola = function() {
    return api.get('/reporte/horassolicitudasesor').then(res => res.data)
}
reportes.horaPicoReporte = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/horaspico?' +`fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.resultadoReporte = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/resultados?' +`fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}&tipo=day`).then(res => res.data)
}
reportes.resultadoReporteSemanal = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/resultados?' +`fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}&tipo=week`).then(res => res.data)
}
reportes.colaAtendida = function( fecha_inicial, fecha_final) {
    return api.get(`/reporte/colaatendida/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.cola = function( fecha_inicial, fecha_final) {
    return api.get(`/reporte/cola/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.flujos = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/flujos?' +`fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}

reportes.horasAsesor = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/horasasesor?' +`fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}

reportes.asesorAtendiendo = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/atendiendo?' +`fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}

reportes.asesorSesion = function() {
    return api.get('/reporte/atendiendomonitor').then(res => res.data)
}

reportes.felizometroAsesorReporte = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/felizometroasesor?' +`fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}

reportes.atencionPorAsesor = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/asesormensajes?' +`fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.covidIntents = function(fecha_inicial, fecha_final) {
    return api.get('/covid/intents?' +`fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data).catch(err => {
        if (401 === err.response.status) {
            window.location = '/'
            this.$gtag.event('tokenExpirado',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': '12hrs conectado',
                'value': 1
            })
        }
    })
}
reportes.tiemposMkt = function( fecha_inicial, fecha_final) {
    return api.get(`/reporte/tiemposmarketing/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.tiemposDia = function( fecha_inicial, fecha_final) {
    return api.get(`/reporte/tiemposdia/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.octubreIntents = function(fecha_inicial, fecha_final) {
    return api.get('/octubrerosa/intents?' +`fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data).catch(err => {
        if (401 === err.response.status) {
            window.location = '/'
            this.$gtag.event('tokenExpirado',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': '12hrs conectado',
                'value': 1
            })
        }
    })
}

reportes.covidChat = function(fecha_inicial, fecha_final) {
    return api.get('/covid/mensajes?' +`fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}

reportes.octubreChat = function(fecha_inicial, fecha_final) {
    return api.get('/octubrerosa/mensajes?' +`fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.resultadosPerdidosCovid = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/pacientesresultadosnows?' +`fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.pacientesNoAtendidos = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/pacientesnoatendidos/cola?' +`fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.sesionesPerdidas = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/lostsessions?' +`fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.atencionAVG = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/atencion?' +`fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.bitacora = function(tipo, fecha_inicial, fecha_final) {
    return api.get(`/reporte/bitacora?tipo=${tipo}&fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.etiqueta = function( fecha_inicial, fecha_final) {
    return api.get(`/reporte/etiquetas?fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.facebookAtendidos = function( fecha_inicial, fecha_final) {
    return api.get(`/facebook/reporte/atendidos?fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.facebookAtendidosU = function( fecha_inicial, fecha_final) {
    return api.get(`/facebook/reporte/atendidosunicos/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.facebookSolicitudAsesor = function( fecha_inicial, fecha_final) {
    return api.get(`/facebook/reporte/solicitudasesor/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.facebookPacientes = function( fecha_inicial, fecha_final) {
    return api.get(`/facebook/reporte/pacientes/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.nicaraguaPxAsesor = function( fecha_inicial, fecha_final) {
    return api.get(`/nicaragua/reporte/pacientesasesor/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.nicaraguaPxporAsesor = function( fecha_inicial, fecha_final) {
    return api.get(`/nicaragua/reporte/pacientescadaasesor/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.nicaraguaPacientes = function( fecha_inicial, fecha_final) {
    return api.get(`/nicaragua/reporte/pacientes/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.hemodialisisPxAsesor = function( fecha_inicial, fecha_final) {
    return api.get(`/hemodialisis/reporte/pacientesasesor/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.hemodialisisPxporAsesor = function( fecha_inicial, fecha_final) {
    return api.get(`/hemodialisis/reporte/pacientescadaasesor/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.hemodialisisPacientes = function( fecha_inicial, fecha_final) {
    return api.get(`/hemodialisis/reporte/pacientes/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.asesoresInactivos = function( fecha_inicial, fecha_final) {
    return api.get(`/reporte/inactivos/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.CopysEnviados = function(fecha_inicial, fecha_final) {
    //console.log(fecha_inicial, fecha_final)
    return api.get(`/reporte/obtenerCopysEnviadosPorFecha?fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.BitacoraBreaks = function(fecha_inicial, fecha_final) {
    //console.log(fecha_inicial, fecha_final)
    return api.get(`/reporte/bitacorabreaks?fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.ReporteUnificado = function(fecha_inicial, fecha_final) {
    //console.log(fecha_inicial, fecha_final)
    return api.get(`/reporte/reporteUnificado?fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.salvadorPxAsesor = function( fecha_inicial, fecha_final) {
    return api.get(`/salvador/reporte/pacientesasesor/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.salvadorPxporAsesor = function( fecha_inicial, fecha_final) {
    return api.get(`/salvador/reporte/pacientescadaasesor/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.salvadorPacientes = function( fecha_inicial, fecha_final) {
    return api.get(`/salvador/reporte/pacientes/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}

reportes.guatemalaPxAsesor = function( fecha_inicial, fecha_final) {
    return api.get(`/guatemala/reporte/pacientesasesor/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.guatemalaPxporAsesor = function( fecha_inicial, fecha_final) {
    return api.get(`/guatemala/reporte/pacientescadaasesor/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.guatemalaPacientes = function( fecha_inicial, fecha_final) {
    return api.get(`/guatemala/reporte/pacientes/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}

reportes.medicosPxAsesor = function( fecha_inicial, fecha_final) {
    return api.get(`/medicos/reporte/pacientesmedico/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.medicosPxporAsesor = function( fecha_inicial, fecha_final) {
    return api.get(`/medicos/reporte/pacientescadamedico/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.medicosPacientes = function( fecha_inicial, fecha_final) {
    return api.get(`/medicos/reporte/pacientes/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}

reportes.medicosPacientes = function( fecha_inicial, fecha_final) {
    return api.get(`/medicos/reporte/pacientes/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}

reportes.noMedicos = function( fecha_inicial, fecha_final) {
    return api.get(`/medicos/reporte/nomedicos/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.sinAtender = function( fecha_inicial, fecha_final) {
    return api.get(`/medicos/reporte/sinatender/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.petctPxporAsesor = function( fecha_inicial, fecha_final) {
    return api.get(`/petct/reporte/pacientescadamedico/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.petctPacientes = function( fecha_inicial, fecha_final) {
    return api.get(`/petct/reporte/pacientes/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}

reportes.petctPxAsesor = function( fecha_inicial, fecha_final) {
    return api.get(`/petct/reporte/pacientesmedico/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}

reportes.medicoEncuenta = function( fecha_inicial, fecha_final) {
    return api.get(`/medicos/reporte/felizometroasesor/1/1?fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.medicoEncuenta2 = function( fecha_inicial, fecha_final) {
    return api.get(`/medicos/reporte/felizometroasesor/1/2?fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.medicoEncuenta3 = function( fecha_inicial, fecha_final) {
    return api.get(`/medicos/reporte/felizometroasesor/1/3?fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.medicoEncuentaDia = function( fecha_inicial, fecha_final) {
    return api.get(`/medicos/reporte/felizometroasesorpordia/1/1?fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.medicoEncuentaDia2 = function( fecha_inicial, fecha_final) {
    return api.get(`/medicos/reporte/felizometroasesorpordia/1/2?fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.medicoEncuentaDia3 = function( fecha_inicial, fecha_final) {
    return api.get(`/medicos/reporte/felizometroasesorpordia/1/3?fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.ingregosAsesor = function( fecha_inicial, fecha_final) {
    return api.get(`/reporte/ingresoAsesor/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.asesorFueradeHora = function( fecha_inicial, fecha_final) {
    return api.get(`/reporte/asesorFueradeHora/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.googlePacientesCadaAsesor = function( fecha_inicial, fecha_final) {
    return api.get(`/google/reporte/pacientescadaasesor/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.googlePacientesEnAsesor = function( fecha_inicial, fecha_final) {
    return api.get(`/google/reporte/pacientesasesor/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}

reportes.mesaPxAsesor = function( fecha_inicial, fecha_final) {
    return api.get(`/mesa/reporte/pacientesasesor/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.mesaPxporAsesor = function( fecha_inicial, fecha_final) {
    return api.get(`/mesa/reporte/pacientescadaasesor/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.mesaPacientes = function( fecha_inicial, fecha_final) {
    return api.get(`/mesa/reporte/pacientes/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
export default reportes