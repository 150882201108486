<template>
<div id="page-content-wrapper" :class="{msjDashboard: ruta != ('/chat')}">
    <div class="container-fluid" v-if="mensajes.length!=0">
        <ul class="chat" id="conversacion">
            <button v-show="mostrar" type="button" id="mensajesAnti" class="btn btn-secondary alert alert-secondary cambioOrigen text-center mt-4 mb-4" @click="getMensajesAnt">{{ this.boton }}</button>
            <li :id="msj.id" v-for="msj in mensajes" :key="msj.id" :class="{chatDer: msj.origen == ('3'||'1'), chatIzq: msj.origen == '2'}">
                <!-- Mensajes enviados -->
                <div v-if="(msj.origen == '3') || (msj.origen == '1')" class="msj-rta macro" :class="{'msj-rtaImg': msj.mensaje.tipo == '2','tipoBot': msj.origen == '1'}">
                    <div class="text text-r">
                        <small v-if="msj.asesor!='Tú'" v-show="msj.asesor_id!=0" cl>{{msj.asesor}} dijo:</small>
                        <small v-else v-show="msj.asesor_id!=0" cl>{{msj.asesor}}:</small>
                        <p v-if="msj.mensaje.tipo == '1'" v-html="msj.mensaje.msj"></p>
                        <img v-else-if="msj.mensaje.tipo == '2'" class="card-img-top img300 imagenChat" :src="msj.mensaje.msj" alt="Imagen de respuesta" title="Enviada por usuario">
                        <b v-else-if="msj.mensaje.tipo == '3'">SE ENVIÓ PDF</b>
                        <audio v-else-if="msj.mensaje.tipo == '5'" :src="msj.mensaje.msj" type="audio/ogg" preload="none" controls></audio>
                        <div v-else-if="msj.mensaje.tipo == '6'" class="imgMapa"><a class="linkMapa" :href="'https://www.google.com/maps/search/?api=1&query='+msj.mensaje.msj+'&heading=-45&pitch=38&fov=80'" target="_blank"><img src="../assets/images/mapa.png"><b class="ubiClinica">Se envió ubicación de clínica</b></a></div>
                        <div v-else-if="msj.mensaje.tipo == '7' && !msj.mensaje.base64"><img @click="abrirModal(msj.mensaje.msj)" class="card-img-top img300 imagenChat" :src="msj.mensaje.msj" alt="Imagen no encontrada" title="Enviada"><br><p class="caption" v-html="msj.mensaje.caption"></p></div>
                        <div v-else-if="msj.mensaje.tipo == '7' && msj.mensaje.base64"><img @click="abrirModal('data:image/jpeg;base64,'+msj.mensaje.msj)" class="card-img-top img300 imagenChat" v-bind:src="'data:image/jpeg;base64,'+msj.mensaje.msj" alt="Imagen no encontrada" title="Enviada"><br><p class="caption" v-html="msj.mensaje.caption"></p></div>
                        <p class="sinSeleccion"><small v-if="(dia != msj.mensaje.fecha.substr(8,2))" class="text-dark">{{msj.mensaje.fecha}}</small><small v-else-if="(parseInt(msj.updatedAt.substr(12,2))== parseInt(msj.mensaje.fecha.substr(12,2)))||(parseInt(msj.updatedAt.substr(12,2))==parseInt(msj.mensaje.fecha.substr(12,2)+12))" class="text-dark">{{msj.mensaje.hora}}</small><small class="text-dark" v-else>{{msj.mensaje.fecha.substr(10,14)}}</small>
                            <font-awesome-icon v-show="msj.mensaje.status_send == 2 &&(msj.mensaje.status_delivered != 4 && msj.mensaje.status_delivered != 5)" class="text-secondary" icon="check" />
                            <font-awesome-icon v-show="msj.mensaje.status_delivered == 4" class="text-secondary" icon="check-double" />
                            <font-awesome-icon v-show="msj.mensaje.status_delivered == 5" class="text-primary" icon="check-double" />
                            <font-awesome-icon v-show="msj.mensaje.status_send != null" v-if="msj.mensaje.status_send != 2 || (msj.mensaje.status_delivered < 4 && msj.mensaje.status_delivered > 5)" class="text-danger ml-1" icon="times" />
                        </p>
                    </div>
                    <div class="avatar" style="padding:0px 0px 0px 10px !important"></div>
                </div>
                <!-- Mensajes recibidos-->
                <div v-else-if="msj.origen == '2'" class="msj macro" :class="{'msj-rtaImg': msj.mensaje.tipo == '2'}">
                    <div class="text text-l">
                        <!-- Tipo TEXTO -->
                        <p v-if="msj.mensaje.tipo == '1'" v-html="msj.mensaje.msj"></p>
                        <!-- Tipo IMG -->
                        <img v-else-if="msj.mensaje.tipo == '2'" @click="abrirModal(msj.mensaje.msj)" class="card-img-top img200 imagenChat" :src="msj.mensaje.msj" alt="Imagen de respuesta" title="Enviada por paciente">
                        <!-- Tipo DOCUMENTO -->
                        <div v-else-if="msj.mensaje.tipo == '3'"><a class="btn btnLink" :href="msj.mensaje.msj" target="_blank"><font-awesome-icon class="text-primary" icon="file-download" /></a><iframe :src="'https://docs.google.com/gview?url='+msj.mensaje.msj+'&embedded=true'" width="320" height="220" style="border: none;"></iframe></div>
                        <!-- Tipo VIDEO -->
                        <video v-else-if="msj.mensaje.tipo == '4'" :src="msj.mensaje.msj" preload="none" controls></video>
                        <!-- Tipo Nota de VOZ/AUDIO -->
                        <audio v-else-if="msj.mensaje.tipo == '5'" :src="msj.mensaje.msj" type="audio/ogg" preload="none" controls></audio>
                        <!-- Tipo UBICACIÓN -->
                        <div v-else-if="msj.mensaje.tipo == '6'" class="imgMapa"><a class="linkMapa" :href="'https://www.google.com/maps/search/?api=1&query='+msj.mensaje.msj+'&heading=-45&pitch=38&fov=80'" target="_blank"><img src="../assets/images/mapa.png"><b class="ubiPaciente">Se recibió ubicación de paciente</b></a></div>
                        <!-- Tipo IMG con TEXTO -->
                        <div v-else-if="msj.mensaje.tipo == '7'"><img @click="abrirModal(msj.mensaje.msj)" class="card-img-top img200 imagenChat" :src="msj.mensaje.msj" alt="Imagen de respuesta" title="Enviada por paciente"><br><p class="caption" v-html="msj.mensaje.caption"></p></div>
                        <!-- Tipo VIDEO con TEXTO -->
                        <div v-else-if="msj.mensaje.tipo == '8'"><video :src="msj.mensaje.msj" preload="none" controls></video><br><p class="caption" v-html="msj.mensaje.caption"></p></div>
                        <!-- Tipo Stikers -->
                        <b v-else-if="msj.mensaje.tipo == '9'">Mensaje no soportado</b>
                        <p class="sinSeleccion"><small v-if="(dia != msj.mensaje.fecha.substr(8,2))">{{msj.mensaje.fecha}} </small><small v-else>{{msj.mensaje.fecha.substr(10,14)}}</small></p>
                    </div>
                    <div class="avatar" style="padding:0px 0px 0px 10px !important"></div>
                </div>
                <div v-else-if="msj.origen == '4'" class="alert alert-secondary cambioOrigen text-center mt-4 mb-4" role="alert" :title="msj.mensaje.fecha">
                    
                    <b v-if="msj.mensaje.tipo==1">El paciente pasó al asesor: {{msj.asesor}} </b>
                    <b v-else>El paciente volvió de la cola.</b>
                </div>
                <div v-else-if="msj.origen == '5'" class="alert alert-secondary cambioOrigen text-center mt-4 mb-4" role="alert" :title="msj.mensaje.fecha">
                    <b v-if="msj.mensaje.tipo=='2'">{{msj.asesor}} terminó la conversación.</b>
                    <b v-else-if="msj.mensaje.tipo=='3'">Se perdió la sesión con el paciente.</b>
                    <b v-else>El paciente volvió a cola</b>
                </div>
                <!-- Exclusivo de Facebook -->
                <div v-else-if="msj.origen == '6'" class="my-3 float-right" :class="{'msj-rtaImg': msj.mensaje.tipo == '2','tipoBot': msj.origen == '1'}">
                    <!-- <div class="preguntaFB bg-primary text-white" :title="msj.mensaje.hora">
                          Aun no es posible visualizar la conversación completa del 🤖 Bot de Facebook. ATTE: Laboratorio Digital
                      </div> -->
                  <div v-if="msj.estructura">
                      <div v-if="msj.estructura.tipo=='menu'" class="card-deck" :title="msj.mensaje.hora">
                        <div class="card" v-for="menu in msj.estructura.menu.payload.elements" :key="menu.id">
                            <img class="card-img-top  align-self-center" :src="menu.image_url" :alt="msj.mensaje.fecha" >
                            <div class="card-body">{{menu.title}}<br>{{menu.subtitle}}</div>
                            
                        </div>
                    </div>
                    <div v-else-if="msj.estructura.tipo=='menuClinicas'" class="card-deck" :title="msj.mensaje.hora">
                        <div class="card" v-for="menu in msj.estructura.menu.attachment.payload.elements" :key="menu.id">
                            <img class="card-img-top  align-self-center" :src="menu.image_url" :alt="msj.mensaje.fecha" >
                            <div class="card-body">{{menu.title}}<br>{{menu.subtitle}}</div>
                            
                        </div>
                    </div>
                    <div v-else-if="msj.estructura.tipo=='respuestaRapida'">
                        <div class="preguntaFB bg-primary text-white" :title="msj.mensaje.fecha">
                            {{msj.estructura.menu?(msj.estructura.menu.title || msj.estructura.menu.text): 'Aun no es posible visualizar la conversación completa del 🤖 Bot de Facebook. ATTE: Laboratorio Digital'}}
                        </div>
                    </div>
                    <div v-else-if="msj.estructura.tipo=='templateButton'">
                        <div class="preguntaFB bg-primary text-white" :title="msj.mensaje.hora">
                            {{msj.estructura.menu.payload.text}}
                        </div>
                    </div>
                    <div v-else-if="msj.estructura.tipo=='respuestaRapidaCard'">
                        <div class="card" v-for="menu in msj.estructura.menu.attachment.payload.elements" :key="menu.id">
                            <img class="card-img-top  align-self-center" :src="menu.image_url" :alt="msj.mensaje.fecha" >
                            <div class="card-body">{{menu.title}}</div>
                            <div class="card-body"><span class="card-link">{{menu.buttons[0].title}}</span></div>
                        </div>
                    </div>
                    <div v-else-if="msj.estructura.tipo=='felizometro'">
                        <div class="preguntaFB bg-primary text-white" :title="msj.mensaje.hora">
                            {{msj.estructura.menu.text}}
                        </div>
                    </div>
                    <div v-else-if="msj.estructura.tipo=='resultados'">
                        <div class="preguntaFB bg-primary text-white" :title="msj.mensaje.hora">
                            <a class="text-white" :href="msj.estructura.menu.attachment.payload.url"><font-awesome-icon icon="arrow-alt-circle-down" /> Resultados.pdf</a>
                        </div>
                    </div>
                  </div>
                  <div v-else>
                      <div class="preguntaFB bg-primary text-white" :title="msj.mensaje.hora">
                          Aun no es posible visualizar la conversación completa del 🤖 Bot de Facebook. ATTE: Laboratorio Digital
                      </div>
                  </div>
                </div>
                <div v-else class="alert alert-danger text-center my-4" role="alert">
                    <strong>El paciente</strong> se encuentra fuera de linea, no es posible enviarle mensaje en este momento.
                </div>
            </li>
            <li>
                <hr id="final" style="visibility: hidden;">
            </li>
        </ul>
    </div>
    <div v-else class="container">
        <div class="row">
            <div class="col text-center">
                <div v-if="!cargando" class="alert alert-secondary cambioOrigen text-center mt-4 mb-4" role="alert">
                    <b>Conversación sin historial</b>
                </div>
                <div v-else class="spinner-border text-success text-center mt-4 mb-4" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>

    <div class="fade modal-acosta" id="img">
        <div @click="modalClick" class="overlaycerrar">
            </div>
            <button @click="rotar(-90)" class="btnGiraIzq"><font-awesome-icon class="h3" icon="undo-alt" /></button>
         
        <div class="contenido-modal" id="imgModal" role="document">
            
            <img id="zoom" class="imgModal" :src="modal" alt="img de paciente" @mousewheel="zoom($event)">
            <div class="d-flex flex-column">
              <button @click="subirObajar(-10)" class="flechasIMG"><font-awesome-icon class="h6" icon="arrow-up" /></button>
              <button @click="subirObajar(10)" class="flechasIMG"><font-awesome-icon class="h6" icon="arrow-down" /></button>
            </div>
        </div>
            <button @click="rotar(90)" class="btnGiraDer"><font-awesome-icon class="h3" icon="redo-alt" /></button>
            <span @click="zoom(1)" class="btnZoomPositivo"><font-awesome-icon class="h5" icon="search-plus" /></span>
            <Slider v-model="zoomv" orientation="vertical" class="barraZoom" @change="zoom" @click="zoom()"/>
            <span @click="zoom(-1)" class="btnZoomNegativo"><font-awesome-icon class="h5" icon="search-minus" /></span>
         
    </div>
     <a class="btnAbajo" href="#final" v-show="mensajes.length > 5" >
        <font-awesome-icon icon="angle-double-down" />
    </a>
    <a id="desplazamiento" :href="'#'+this.ultimoIdMensaje">
    </a>
    <div class="msj text text-l" v-show="comentarioP === 1 && telefono && (sessionStorageUser.rol_id === 3 || sessionStorageUser.rol_id === 4)" id="comentarioPlataforma" style="font: 150% sans-serif;">
        El Monitor {{ monitor }} envió un Comentario para el número {{ telefono }} con motivo de {{ motivo }} <br>
        El comentario es: {{ comentario }}
        <br>
        <div>
            <Button class="btn btn-success btn-sm" @click="cerrarNota()" style="position: absolute; bottom: 8px; right: 8px;">
            <font-awesome-icon class="mr-1" icon="times" /><span class="ocultoMovil"> Cerrar Comentario</span>
            </Button>
        </div>
        <br>
    </div>
</div> <!-- /#page-content-wrapper -->
</template>
<script> 
import asesorService from '../services/asesor'
import {library} from '@fortawesome/fontawesome-svg-core'
import { faCheck, faCheckDouble, faFileDownload, faAngleDoubleDown, faUndoAlt, faRedoAlt, faSearchPlus, faSearchMinus, faArrowUp, faArrowDown, faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faCheck, faCheckDouble, faFileDownload, faAngleDoubleDown, faUndoAlt, faRedoAlt, faSearchPlus, faSearchMinus, faArrowUp, faArrowDown, faArrowAltCircleDown)
import Slider from 'primevue/slider'
import Querys from '../graphQL/chat.graphql'
import ChatService from '../graphQL/chat.service'
//import NavbarVue from './Navbar.vue'
var moment = require('moment')
moment.locale('es')
export default {
    name: 'Mensajes',
    props: ['toggledSidebar', 'sender'],
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        Slider,
    },
    data() {
        return {
            boton:'Cargar Historial',
            modal: '',
            mensajes: [],
            mensajesAnt: [],
            conversacion:[],
            cargando: false,
            chatBox: '',
            polling: null,
            dia: '',
            hoy: new Date(),
            ruta: window.location.pathname,
            sessionStorageUser: JSON.parse((sessionStorage.getItem('sesion'))),
            intervalo: 0,
            cuenta: 0,
            zoomv: 0,
            topImg:0,
            hiloCargarMensaje: null,
            asesores: '',
            network: localStorage.getItem('Network'),
            telefono:null,
            motivo:'',
            monitor:'',
            comentarioP:0,
            comentario:'',
            id:null,
            ultimoIdMensaje:null,
            mostrar:true
        }
    },
    created() {
        this.getMensajes()
        this.listaAsesores()
        this.$bus.$off('ver-mensajes')
        this.$bus.$on('ver-mensajes', () => {
            this.cargando = true
            this.mensajes = []
            this.network = localStorage.getItem('Network')
            this.getMensajes()
            this.getComentario()
        })

        this.$bus.$off('abrirConversacion')
        this.$bus.$on('abrirConversacion', () => {
            this.cargando = true
            this.mensajes = []
            this.network = localStorage.getItem('Network')
            this.getMensajes()
        })

        // this.$bus.$off('sendresp-mensajes')
        // this.$bus.$on('sendresp-mensajes', senderId => {
        //     asesorService.verChat(senderId).then(respa => {
        //         this.mensajes = respa
        //     })
        // })
        this.$bus.$off('terminar-conversacion')
        this.$bus.$on('terminar-conversacion', () => {
            this.cargando = false
            this.mensajes = []
            sessionStorage.removeItem('sender_actual')
            if(this.$apollo.subscriptions.getMensajes){
                this.$apollo.subscriptions.getMensajes.destroy()
            }
            if(this.$apollo.subscriptions.getMensajesNi){
                this.$apollo.subscriptions.getMensajesNi.destroy()
            }
            if(this.$apollo.subscriptions.getMensajesHe){
                this.$apollo.subscriptions.getMensajesHe.destroy()
            }
            if(this.$apollo.subscriptions.getMensajesMedicos){
                this.$apollo.subscriptions.getMensajesMedicos.destroy()
            }
            if(this.$apollo.subscriptions.getMensajesFB){
                this.$apollo.subscriptions.getMensajesFB.destroy()
            }
            if(this.$apollo.subscriptions.getMensajesWASa){
                this.$apollo.subscriptions.getMensajesWASa.destroy()
            }
            if(this.$apollo.subscriptions.getMensajesWAGml){
                this.$apollo.subscriptions.getMensajesWAGml.destroy()
            }
            this.$bus.$emit('sesionPX', false)
            this.$bus.$emit('paciente')
            this.$bus.$emit('chatAbierto', false)
        })
        this.fecha()
        // this.$apollo.addSmartSubscription('getMensajes', {
        //     query: Querys.getMensajesPacientes,
        //     // Reactive variables
        //     variables () {
        //         // This works just like regular queries
        //         // and will re-subscribe with the right variables
        //         // each time the values change
        //         return {
        //             userid: '5216673010139',
        //         }
        //     },
        //     // Result hook
        //     // Don't forget to destructure `data`
        //     async result ({ data }) {
        //         this.mensajes = await PacienteService.setupMensajes(data.historials);
        //         console.log("Mensajes 1:",this.mensajes)
        //     },
        // })
    },
    mounted() {
        sessionStorage.removeItem('sender_actual')
    },
    apollo: {
        $subscribe: {}
    },
    watch: {
        mensajes: {
            handler() {
                this.$bus.$emit('bajarScroll')
            }
        }
    },
    methods: {
        getMensajes() {
            this.mostrar=true
            if(this.network!='WA'){
                this.mostrar=false
            }
            this.boton='Cargar Historial'
            let subname
            let tableName
            let setupName
            if(this.$apollo.subscriptions.getMensajes){
                this.$apollo.subscriptions.getMensajes.destroy()
            }
            if(this.$apollo.subscriptions.getMensajesNi){
                this.$apollo.subscriptions.getMensajesNi.destroy()
            }
            if(this.$apollo.subscriptions.getMensajesHe){
                this.$apollo.subscriptions.getMensajesHe.destroy()
            }
            if(this.$apollo.subscriptions.getMensajesMedicos){
                this.$apollo.subscriptions.getMensajesMedicos.destroy()
            }
            if(this.$apollo.subscriptions.getMensajesFB){
                this.$apollo.subscriptions.getMensajesFB.destroy()
            }
            if(this.$apollo.subscriptions.getMensajesWASa){
                this.$apollo.subscriptions.getMensajesWASa.destroy()
            }
            if(this.$apollo.subscriptions.getMensajesWAGml){
                this.$apollo.subscriptions.getMensajesWAGml.destroy()
            }
            if(this.$apollo.subscriptions.getMensajesPet){
                this.$apollo.subscriptions.getMensajesPet.destroy()
            }
            if(this.$apollo.subscriptions.getMensajesGoogle){
                this.$apollo.subscriptions.getMensajesGoogle.destroy()
            } 

            if(this.network == 'WA') {
                subname = 'getMensajes'
                tableName = 'historials_wmx'
                setupName = 'setupMensajesWA'
            }else if(this.network == 'WAni') {
                subname = 'getMensajesNi'
                tableName = 'historials_wni'
                setupName = 'setupMensajesWANi'
            }else if(this.network == 'WAhemo') {
                subname = 'getMensajesHe'
                tableName = 'historials_whe'
                setupName = 'setupMensajesWA'
            }else if(this.network == 'WAmedicos') {
                subname = 'getMensajesMedicos'
                tableName = 'historials_wmedicos'
                setupName = 'setupMensajesWA'
            }else if(this.network == 'FB'){
                subname = 'getMensajesFB'
                tableName = 'historials_fb'
                setupName = 'setupMensajesFB'
            }else if(this.network == 'WAsa'){
                subname = 'getMensajesWASa'
                tableName = 'historials_wsa'
                setupName = 'setupMensajesWANi'
            }else if(this.network == 'WAgml'){
                subname = 'getMensajesWAGml'
                tableName = 'historials_wgml'
                setupName = 'setupMensajesWA'
            }else if(this.network == 'WApetct'){
                subname = 'getMensajesPet'
                tableName = 'historials__petct'
                setupName = 'setupMensajesWA'
            }else if(this.network == 'GOOGLE'){ 
                subname = 'getMensajesGoogle'
                tableName = 'historial_google'
                setupName = 'setupMensajesGoogle'
            } else if(this.network == 'WAmesa'){             
                subname = 'getMensajesWASoporte'
                tableName = 'soporte_historials'
                setupName = 'setupMensajesWA'
            } 
            this.$apollo.addSmartSubscription(subname, {
                query: Querys[subname],
                variables () { 
                    const senderActual = sessionStorage.getItem('sender_actual');
                    return {
                        userid: senderActual ? senderActual : '',
                    }
                },
          
                async result ({ data }) {  
                        let respa = await ChatService[setupName](data[tableName]); 
                    respa = respa.map(mensaje =>{
                        mensaje.mensaje.hora = moment(moment(mensaje.mensaje.fecha, 'YYYY-MM-DD HH:mm A').toDate()).fromNow()
                        if(mensaje.asesor_id!=0){                        
                            //var buscar = this.asesores.find(asesor => asesor.id == mensaje.asesor_id )
                            //mensaje.asesor = buscar.nombre
                            let indexAsesor =  this.asesores.map(asesor => asesor.id).indexOf(+mensaje.asesor_id)
                            mensaje.asesor = indexAsesor == -1 ? 'Tú' : this.asesores[indexAsesor].nombre
                            return mensaje
                        }                
                        return mensaje
                    }) 
                    this.mensajes = respa
                 



                },
            })
        },
        async verConversacion() {
            this.cargarMsj(this.sender)
            sessionStorage.setItem('sender_actual', this.sender)
        },
        async listaAsesores() { 
            await asesorService.listaDeAsesores().then(respa => {
                this.asesores = respa
            })
        },
        abrirModal(imagen) {
            this.modal = imagen
            this.cuenta = 0
            var image = document.getElementById('imgModal')
            image.setAttribute("style", "transform: rotate(" + this.cuenta + "deg)")
            var modalImg = document.getElementById('img')
            modalImg.classList.add('show')
            
        },
        networkActiva(){
            this.network = asesorService.getNetwork()
        },
        // async cargarMsj(id) {
        //         await asesorService.verChat(id).then(async respa =>{
        //             var moment = require('moment')
        //             moment.locale('es')
        //             respa = respa.map(mensaje =>{
                        
        //                 mensaje.mensaje.hora = moment(moment(mensaje.mensaje.fecha, 'YYYY-MM-DD HH:mm A').toDate()).fromNow()
        //                 if(mensaje.asesor_id!=0){                       
        //                     if (this.asesores.length) {

        //                         let indexAsesor = this.asesores.map(asesor => asesor.id).indexOf(+mensaje.asesor_id)
                            
        //                         mensaje.asesor = indexAsesor == -1 ? 'Tú' : this.asesores[indexAsesor].nombre
        //                         return mensaje
        //                     }else{
        //                         this.cargarMsj(id)
        //                     }
                            
        //                 }
        //                 return mensaje
        //             })
        //             this.mensajes = respa
        //             this.$bus.$emit('bajarScroll', 1)
        //         })

        // },
        // cargarMsjAutomatico() {
        //     var moment = require('moment')
        //     moment.locale('es')
        //     let respa = this.mensajes.map(mensaje =>{
        //         mensaje.mensaje.hora = moment(moment(mensaje.mensaje.fecha, 'YYYY-MM-DD HH:mm A').toDate()).fromNow()
        //         if(mensaje.asesor_id!=0){                        
        //             //var buscar = this.asesores.find(asesor => asesor.id == mensaje.asesor_id )
        //             //mensaje.asesor = buscar.nombre
        //             let indexAsesor =  this.asesores.map(asesor => asesor.id).indexOf(+mensaje.asesor_id)
        //             mensaje.asesor = indexAsesor == -1 ? 'Tú' : this.asesores[indexAsesor].nombre
        //             return mensaje
                    
        //         }                
        //         return mensaje
        //     })
        //     this.mensajes = respa
        // },
        fecha() {
            this.dia = this.hoy.toString().substr(8, 2);
        },
        // pollData() {
        //     clearTimeout(this.hiloGet)
        //     this.polling = setInterval(() => {
                
        //         let senderid = sessionStorage.getItem('sender_actual')
        //         if (senderid) {
        //             //this.cargarMsjAutomatico(this.actual)
        //             this.$bus.$emit('existeSender', true)
        //         } else {
        //             //this.cargarMsjAutomatico(this.actual)
        //             this.$bus.$emit('existeSender', false)
        //         }

        //     }, this.intervalo)

        // },
        modalClick(){
            var modalImg = document.getElementById('img')
            modalImg.classList.remove('show')    

        },
        rotar(angulo){
            
            this.cuenta += angulo
            var image = document.getElementById('imgModal')
            image.setAttribute("style", "transform: rotate(" + this.cuenta + "deg)")
            
        },
        zoom(evento){
            if (evento.deltaY<0){
                this.zoomv+=10
            } else {
                this.zoomv+=-10
            }
            let zoom = 100 + this.zoomv
            if(zoom < 50){
                return
            }
            var image = document.getElementById('zoom')
            image.setAttribute("style", "height:" + zoom + "%!important")
        },
        subirObajar(mouse){
            this.topImg += mouse
            var image = document.getElementById('zoom')
            //image.setAttribute("style", "top:" + this.topImg + "%!important")
            image.style.top = this.topImg + '%'
        },
        getComentario(){
            let senderid = sessionStorage.getItem('sender_actual')
            let dato={
                asesorid:this.sessionStorageUser.id
            } 
            asesorService.getNota(dato).then(resp=>{
                if(resp.length>0){
                    this.comentarioP=1
                    for (let i = 0; i < resp.length; i++) {
                    if(senderid==resp[i].telefono){
                        this.id=resp[i].id;
                        this.telefono=resp[i].telefono;
                        this.monitor=resp[i].nombre_monitor;
                        this.motivo=resp[i].desmotivo;
                        this.comentario=resp[i].descripcion;
                    }
                }
                }else{
                    this.comentarioP=0
                }
                
            })
            
        },
        cerrarNota(){
          let data={
            id:this.id
          }
          asesorService.cerrarNota(data)
            this.$bus.$emit('actualizar-chat')
            this.comentarioP=0
            this.id=null
            this.telefono=null
            this.monitor=null
            this.motivo=null
            this.comentario=null
        },
        getMensajesAnt() {
            let senderid = {
                senderid: sessionStorage.getItem('sender_actual')
            };

            asesorService.getMensajesAnt(senderid)
            .then(resp => {
                let messages = resp.map(x => {
                    // Formatear la fecha
                    const fecha = new Date(x.createdAt);
                    const year = fecha.getFullYear();
                    const month = String(fecha.getMonth() + 1).padStart(2, '0');
                    const day = String(fecha.getDate()).padStart(2, '0');
                    const hours = String(fecha.getHours()).padStart(2, '0');
                    const minutes = String(fecha.getMinutes()).padStart(2, '0');
                    const amOrPm = fecha.getHours() >= 12 ? 'pm' : 'am';
                    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes} ${amOrPm}`;
                        let tipo = x.tipo
                        let tipo2 = x.tipo && x.tipo.substring(0, 3)
                        let tipo1 = tipo ==="FIN" ? "2" : "1";
                    let origen = "1";
                        if (tipo2 === "ASE") {
                            origen = "3";
                        } else if (tipo2 === "BOT") {
                            origen = "1";
                        } else if (tipo2 === "USE") {
                            origen = "2";
                        }
                        if (tipo.includes("IMG")) {
                            return {
                                asesor_id: x.asesor_id ? null : 0,
                                id: x.id,
                                mensaje: {
                                    base64:false,
                                    caption: x.caption ? null : "",
                                    fecha: formattedDate,
                                    hora: "",
                                    msj: x.estado,
                                    status_delivered: x.deliveredstatuscode,
                                    status_send: x.status_send,
                                    tipo: "7"
                                },
                                origen:origen,
                                senderid: x.senderid,
                                updatedAt: "Fri Oct 13 2023"
                            };
                        } else {
                            return {
                                asesor_id: x.asesor_id ? null : 0,
                                id: x.id,
                                mensaje: {
                                    caption: x.caption ? null : "",
                                    fecha: formattedDate,
                                    hora: "",
                                    msj: x.estado,
                                    status_delivered: x.deliveredstatuscode,
                                    status_send: x.status_send,
                                    tipo: tipo1
                                },
                                origen:origen,
                                senderid: x.senderid,
                                updatedAt: "Fri Oct 13 2023"
                            };
                        }
                    
                });
                    this.mensajes = messages.concat(this.mensajes);
                if (messages.length > 0) {
                    this.ultimoIdMensaje = messages[messages.length - 1].id;
                    setTimeout(() => {
                    document.getElementById('desplazamiento').click();
                    }, 4000); 
                    this.mostrar=false
                }else{
                    this.boton='Esta conversación no cuenta con historial'
                    var boton = document.getElementById("mensajesAnti");
                    boton.disabled = true;
                }
            })
            .catch(error => {
                console.error('Error al obtener mensajes: ', error);
            });
        }
    }
}
</script>
