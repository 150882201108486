<template>
    <div>
      <DataTable :paginator="true" :rows="500" :value="Treporte" :sortField="sortField" :sortOrder="sortOrder" removableSort ref="tablaReporte">
        <template #header>
          <div style="text-align: right">
            <Button class="btn btn-success" label="Export" @click="exportCSV($event)">
              <span class="d-none d-sm-none d-md-block">Exportar a Excel</span>
              <font-awesome-icon class="ml-1 d-block d-sm-block d-md-none" icon="file-excel"/>
              <i class="far fa-file-excel"></i>
            </Button>
          </div>
        </template>
        <Column v-for="col of columnas" :field="col.field" :header="col.header" :key="col.field" :sortable="true"></Column>
      </DataTable>
    </div>
  </template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import {
    library
} from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faFileExcel
} from "@fortawesome/free-solid-svg-icons"
library.add(faFileExcel)
export default {
    name: 'Reportedinamico',
    props: {
        Treporte:Array,
        columnas: null
    },
    data() {
        return {
            asesor: JSON.parse(sessionStorage.getItem('sesion')),  
            loading: false,
            columns: {},
            sortField: '', 
            sortOrder: 0
        }
    },
    computed: {
    calculateSortOptions() {
      if (this.asesor.grupo_id === 6) {
        return {
          sortField: 'asesor',
          sortOrder: 1
        };
      } else {
        return {
          sortField: '',
          sortOrder: 0
        };
      }
    }
  },
    created(){
        const { sortField, sortOrder } = this.calculateSortOptions;
        this.sortField = sortField;
        this.sortOrder = sortOrder;
        this.columnsrep1 = [
        {
            field: "fecha",
            header: "Fecha",
        },
        {
            field: "hora",
            header: "Hora",
        },
        {
            field: "totalPacientesNoAtendidos",
            header: "No se antendieron",
        },
        {
            field: "totalBot",
            header: "Asistente Virtual",
        },
        {
            field: "totalAtendidos",
            header: "En asesor",
        },
        {
            field: "totalPacientes",
            header: "TOTAL",
        },
        ]
        this.columnsrep2 = [
        {
            field: "asesores",
            header: "Asesor",
        },
        {
            field: "nAtendidos",
            header: "Atendidos",
        },
        {
            field: "tiempoPromedio",
            header: "Tiempo",
        },
        ]
        this.columnsrep3 = [
        {
            field: "count",
            header: "Pacientes",
        },
        {
            field: "createdAt",
            header: "Fecha",
        },
        ]
        this.columnsrep4 = [
        {
            field: "count",
            header: "Pacientes",
        },
        {
            field: "createdAt",
            header: "Fecha",
        },
        ]
        this.columnsrep5 = [
        {
            field: "count",
            header: "Pacientes",
        },
        {
            field: "nombre",
            header: "Asesor",
        },
        {
            field: "createdAt",
            header: "Fecha",
        },
        ]
        this.columnsrep6 = [
        {
            field: "count",
            header: "Transferencias",
        },
        {
            field: "comentario",
            header: "Motivo",
        },
        ]
        this.columnsrep7 = [
        {
            field: "count",
            header: "Transferencias",
        },
        {
            field: "comentario",
            header: "Motivo",
        },
        {
            field: "createdAt",
            header: "Fecha",
        },
        ]
        this.columnsrep8 = [
        {
            field: "timezone",
            header: "Fecha",
        },
        {
            field: "asesor",
            header: "Asesor",
        },
        {
            field: "respuesta1",
            header: "⭐",
        },
        {
            field: "respuesta2",
            header: "⭐⭐",
        },
        {
            field: "respuesta3",
            header: "⭐⭐⭐",
        },
        {
            field: "respuesta4",
            header: "⭐⭐⭐⭐",
        },
        {
            field: "respuesta5",
            header: "⭐⭐⭐⭐⭐",
        },
        {
            field: "avg",
            header: "Promedio",
        },
        ]
        this.columnsrep9 = [
        {
            field: "date",
            header: "Fecha",
        },
        {
            field: "hora",
            header: "Hora",
        },
        {
            field: "total",
            header: "Total",
        },
        ]
        this.columnsrep10 = [
        {
            field: "fecha",
            header: "Fecha",
        },
        {
            field: "resultados",
            header: "Total de Resultados",
        },
        {
            field: "rsv",
            header: "RSV",
        },
        {
            field: "opc",
            header: "OPC",
        },
        {
            field: "aviso",
            header: "Total Recordatorios",
        },
        {
            field: "aviso_rsv",
            header: "Recordatorio de RSV",
        },
        {
            field: "aviso_opc",
            header: "Recordatorio de OPC",
        },
        ]
        this.columnsrep12 = [
        {
            field: "fecha",
            header: "Fecha",
        },
        {
            field: "RESULTADOS",
            header: "Resultados",
        },
        {
            field: "COTIZACION",
            header: "Cotizaciones",
        },
        {
            field: "CITAS",
            header: "Citas",
        },
        {
            field: "UBICACION Y TELELEFONO",
            header: "Ubica tu clínica",
        },
        ]
        this.columnsrep13 = [
        {
            field: "asesor_nombre",
            header: "Asesor",
        },
        {
            field: "tiempo_trans",
            header: "Horas contestadas",
        },
        {
            field: "de",
            header: "Empezó",
        },
        {
            field: "hasta",
            header: "Terminó",
        },
        {
            field: "fecha",
            header: "Fecha",
        },
        ]
        this.columnsrep14 = [
        {
            field: "nombre",
            header: "Asesor",
        },
        {
            field: "ATENDIENDO",
            header: "En inbox",
        },
        {
            field: "ESPERA_1",
            header: "En pendientes",
        },
        ]
        this.columnsrep15 = [
        {
            field: "NOMBRE",
            header: "Asesor",
        },
        {
            field: "T_ATENDIDOS",
            header: "Pacientes atendidos",
        },
        {
            field: "T_FINALIZADOS",
            header: "Conversaciones finalizadas",
        },
        {
            field: "T_MENSAJES",
            header: "Mensajes enviados",
        },
        ]
        this.columnsrep16 = [
        {
            field: "fecha",
            header: "Fecha",
        },
        {
            field: "hora",
            header: "Hora",
        },
        {
            field: "total",
            header: "Pacientes no atendidos",
        },
        ]
    },
    components: {
        'DataTable': DataTable,
        'Column': Column,
        'font-awesome-icon': FontAwesomeIcon
    },
    methods: {
        exportCSV() {
            this.$refs.tablaReporte.exportCSV();
        }
    }
}
</script>
