<template>
  <div>
    <!-- <div class="container-fluid px-0 mt-1" v-on:mouseover="rol==1?(hover = true):(hover=false)" v-on:mouseleave="hover = false" >
      <div class="row mb-2">
        <div class="col-5 switch-network d-flex flex-row justify-content-center">
          <div class="btn-network rounded-circle bg-whatsapp" >
              <i class="icon-whatsapp opt-wa"></i>
          </div>            
          
        </div>
        <div class="col-2 d-flex flex-row justify-content-center align-items-center">
          <font-awesome-icon class="h4" icon="comment"/>
        </div>
        <div class="col-5 switch-network d-flex flex-row justify-content-center">
          <div class="btn-network rounded-circle bg-messenger" >
              <i class="icon-messenger-new opt-fb"></i>
          </div>
        </div>
      </div>
      <div class="row infoMonitor" v-tooltip.top="'Atención total'">
        <div class="col-5 d-flex flex-row justify-content-center pr-4 ">
          <p class="PxGeneral pb-0">{{datos.whatsapp.pacientes[0].count}}<br> <small class="PxColaN"><img class="bandera mr-1" src="../../../assets/images/ni.svg" height="13px" alt="Bandera de Nicaragua" /><b>{{datos.nicaragua.pacientes}}</b></small></p>
        </div>
        <div class="col-2 d-flex flex-row justify-content-center align-items-center">
          <font-awesome-icon class="dPaciente h4" icon="user-injured"/>
        </div>
        <div class="col-5 d-flex flex-row justify-content-center align-items-center">
          <p class="PxGeneral">{{datos.facebook.pacientes}}</p>
        </div>
      </div>
      <div class="row infoMonitor" v-tooltip.top="'Pacientes atendidos por el asistente virtual'">
        <div class="col-5 d-flex flex-row justify-content-center pr-4 ">
          <p class="PxBot pb-0" v-if="!hover">{{datos.whatsapp.pacientes[0].count-datos.whatsapp.atendidos[0].count}}<br> <small class="PxColaN"><img class="bandera mr-1" src="../../../assets/images/ni.svg" height="13px" alt="Bandera de Nicaragua" /><b>{{datos.nicaragua.pacientes-datos.nicaragua.atendidos}}</b></small></p>
          <p class="PxBot" v-else>{{((datos.whatsapp.pacientes[0].count-datos.whatsapp.atendidos[0].count)*100/(datos.whatsapp.pacientes[0].count)).toFixed(1)+'%'}}</p>
        </div>
        <div class="col-2 d-flex flex-row justify-content-center align-items-center">
          <font-awesome-icon class="dBot h4" icon="robot"/>
        </div>
        <div class="col-5 d-flex flex-row justify-content-center align-items-center">
          <p class="PxBot" v-if="!hover">{{datos.facebook.pacientes-datos.facebook.atendidos}}</p>
          <p class="PxBot" v-else>{{((datos.facebook.pacientes-datos.facebook.atendidos)*100/(datos.facebook.pacientes)).toFixed(1)+'%'}}</p>
        </div>
      </div>
      <div class="row infoMonitor" v-tooltip.top="'Pacientes atendidos por un asesor'">
        <div class="col-5 d-flex flex-row justify-content-center pr-4 ">
          <p class="PxAsesor pb-0" v-if="!hover">{{datos.whatsapp.atendidos[0].count}} <br> <small class="PxColaN"><img class="bandera mr-1" src="../../../assets/images/ni.svg" height="13px" alt="Bandera de Nicaragua" /><b>{{datos.nicaragua.atendidos}}</b></small></p>
          <p class="PxAsesor" v-else>{{((datos.whatsapp.atendidos[0].count)*100/(datos.whatsapp.pacientes[0].count)).toFixed(1)+'%'}}</p>
        </div>
        <div class="col-2 d-flex flex-row justify-content-center align-items-center">
          <font-awesome-icon class="dAsesor h4" icon="user"/>
        </div>
        <div class="col-5 d-flex flex-row justify-content-center align-items-center">
          <p class="PxAsesor" v-if="!hover">{{datos.facebook.atendidos}}</p>
          <p class="PxAsesor" v-else>{{((datos.facebook.atendidos)*100/(datos.facebook.pacientes)).toFixed(1)+'%'}}</p>
        </div>
      </div>
      <div class="row infoMonitor" v-tooltip.top="'Tiempo de espera promedio'">
        <div class="col-5 d-flex flex-row justify-content-center pr-4 ">
          <p class="PxAtencion" :class="{'text-danger': horaWA > 2, 'text-warning':horaWA > 1}">{{horaWA}}:{{minutoWA}} hrs</p>
        </div>
        <div class="col-2 d-flex flex-row justify-content-center align-items-center">
          <font-awesome-icon class="h4" icon="user-clock"/>
        </div>
        <div class="col-5 d-flex flex-row justify-content-center">
          <p class="PxAtencion" :class="{'text-danger': horaFB > 2, 'text-warning':horaFB > 1}">{{horaFB}}:{{minutoFB}} hrs</p>
        </div>
      </div>
      <div class="row infoMonitor" v-tooltip.top="'Tiempo de espera máximo'">
        <div class="col-5 d-flex flex-row justify-content-center pr-4 ">
          <p class="PxAtencionCola" >{{colaAntigua.whatsapp.tiempo.substring(1,3)=='day'?'+24':colaAntigua.whatsapp.tiempo.substring(1,9)}} hrs</p>
        </div>
        <div class="col-2 d-flex flex-row justify-content-center align-items-center">
          <font-awesome-icon class="h4" icon="clock"/>
        </div>
        <div class="col-5 d-flex flex-row justify-content-center">
          <p class="PxAtencionCola" >{{colaAntigua.facebook.tiempo.substring(1,3)=='day'?'+24':colaAntigua.facebook.tiempo.substring(1,9)}} hrs</p>
        </div>
      </div>
      <div class="row infoMonitor" v-tooltip.top="'Sesiones perdidas'">
        <div class="col-5 d-flex flex-row justify-content-center pr-4 ">
          <p class="PxSinSesion" :class="{'text-danger': parseInt(sesiones.whatsapp.sesiones) > 20, 'text-warning':parseInt(sesiones.whatsapp.sesiones) > 5}">{{sesiones.whatsapp.sesiones}}</p>
        </div>
        <div class="col-2 d-flex flex-row justify-content-center align-items-center">
          <font-awesome-icon class="h4" icon="user-times"/>
        </div>
        <div class="col-5 d-flex flex-row justify-content-center">
          <p class="PxSinSesion" >{{sesiones.facebook.sesiones}}</p>
        </div>
      </div>
      
      <div class="row infoMonitor" v-tooltip.top="'Pacientes en espera de atención'">
        <div class="col-5 d-flex flex-row justify-content-center pr-4 ">
          <p>
            <b class="PxCola" :class="{'text-dark':cola.whatsapp.cola<=0,'text-success':cola.whatsapp.cola<=20,'text-orange':cola.whatsapp.cola>=21, 'text-danger': cola.whatsapp.cola>=60}" >{{cola.whatsapp.cola}}</b> <br>
            <img class="bandera mr-1" src="../../../assets/images/ni.svg" height="13px" alt="Bandera de Nicaragua" />
            <b class="PxColaN pb-0 mt-1" :class="{'text-dark':nicaragua.cola<=0,'text-success':nicaragua.cola<=49,'text-orange':nicaragua.cola>=50, 'text-danger': nicaragua.cola>=200}" >{{nicaragua.cola}}</b>
          </p>
        </div>
        <div class="col-2 d-flex flex-row justify-content-center align-items-center">
          <font-awesome-icon class="dCola h4" icon="user-injured"/><font-awesome-icon class="dCola h5" icon="user-friends"/>
        </div>
        <div class="col-5 d-flex flex-row justify-content-center align-items-center">
          <p class="PxCola" :class="{'text-dark':cola.facebook.cola<=0,'text-success':cola.facebook.cola<=6, 'text-orange':cola.facebook.cola>=7, 'text-danger': cola.facebook.cola>=50}" >{{cola.facebook.cola}}</p>
        </div>
      </div>
      
    </div> -->
    <Carousel :value="redes" :numVisible="1" :numScroll="1" :circular="true" :autoplayInterval="pin?999999:3000" orientation="vertical" verticalViewPortHeight="570px" >
     
      <template #item="red">
        <div class="container-fluid px-0 mt-1" v-on:mouseover="rol==1?(hover = true):(hover=false)" v-on:mouseleave="hover = false" >
          <div class="row mb-2">
            <div class="col-4 d-flex flex-row justify-content-center align-items-center">
              <font-awesome-icon class="h4" icon="comment"/>
            </div>
            <div class="col-4 switch-network d-flex flex-row justify-content-center">
              <div v-if="red.data.id==1||red.data.id==3||red.data.id==5" class="btn-network rounded-circle bg-whatsapp" >
                  <i class="icon-whatsapp opt-wa"></i>
                  <img v-if="red.data.id==3" class="bandera" src="../../../assets/images/ni.svg" height="13px" alt="Bandera de México" />
                  <img v-if="red.data.id==5" class="bandera" src="../../../assets/images/sa.svg" height="25px" alt="Bandera de México" />
              </div> 
              <div v-else-if="red.data.id==2" class="btn-network rounded-circle bg-messenger" >
                  <i class="icon-messenger-new opt-fb"></i>
              </div>
              <div v-else class="btn-network rounded-circle logoHemo ">
                <img class="" src="@/assets/images/hemo_isotipo.png" height="30px" alt="Comunidad de hemodialisis" />
              </div>
            </div>
            <div class="col-4">
              <button @click="pin=!pin" type="button" class="btn btn-outline-secondary rounded-circle" :class="{'btn-secondary text-white':pin}"><font-awesome-icon class="" icon="thumbtack"/></button>
            </div>
          </div>
          <div class="row infoMonitor" v-tooltip.top="'Atención total'">
            <div class="col-4 d-flex flex-row justify-content-center align-items-center">
              <font-awesome-icon class="dPaciente h4" icon="user-injured"/>
            </div>
            <div class="col-8 d-flex flex-row justify-content-center align-items-center">
              <p class="PxGeneral">{{red.data.pxtotales}}</p>
            </div>
          </div>
          <div class="row infoMonitor" v-tooltip.top="'Pacientes atendidos por el asistente virtual'">
            <div class="col-4 d-flex flex-row justify-content-center align-items-center">
              <font-awesome-icon class="dBot h4" icon="robot"/>
            </div>
            <div class="col-8 d-flex flex-row justify-content-center align-items-center">
              <p class="PxBot" v-if="!hover">{{red.data.pxbot}}</p>
              <p class="PxBot" v-else>{{((red.data.pxbot)*100/(red.data.pxtotales)).toFixed(1)+'%'}}</p>
            </div>
          </div>
          <div class="row infoMonitor" v-tooltip.top="'Pacientes atendidos por un asesor'">
            <div class="col-4 d-flex flex-row justify-content-center align-items-center">
              <font-awesome-icon class="dAsesor h4" icon="user"/>
            </div>
            <div class="col-8 d-flex flex-row justify-content-center align-items-center">
              <p class="PxAsesor" v-if="!hover">{{red.data.pxasesor}}</p>
              <p class="PxAsesor" v-else>{{((red.data.pxasesor)*100/(red.data.pxtotales)).toFixed(1)+'%'}}</p>
            </div>
          </div>
          <div class="row infoMonitor" v-tooltip.top="'Tiempo de espera promedio'" v-if="red.data.hora">
            <div class="col-4 d-flex flex-row justify-content-center align-items-center">
              <font-awesome-icon class="h4" icon="user-clock"/>
            </div>
            <div class="col-8 d-flex flex-row justify-content-center">
              <p class="PxAtencion" :class="{'text-danger': red.data.hora > 2, 'text-warning':red.data.hora > 1}">{{red.data.hora}}:{{red.data.minuto}} hrs</p>
            </div>
          </div>
          <div class="row infoMonitor" v-tooltip.top="'Tiempo de espera máximo'" v-if="red.data.tiempoMax">
            <div class="col-4 d-flex flex-row justify-content-center align-items-center">
              <font-awesome-icon class="h4" icon="clock"/>
            </div>
            <div class="col-8 d-flex flex-row justify-content-center">
              <p class="PxAtencionCola" >{{red.data.tiempoMax}} hrs</p>
            </div>
          </div>
          <div class="row infoMonitor" v-tooltip.top="'Sesiones perdidas'" v-if="red.data.sesionesPerdidas">
            <div class="col-4 d-flex flex-row justify-content-center align-items-center">
              <font-awesome-icon class="h4" icon="user-times"/>
            </div>
            <div class="col-8 d-flex flex-row justify-content-center">
              <p class="PxSinSesion" :class="{'text-danger': parseInt(red.data.sesionesPerdidas) > 20, 'text-warning':parseInt(red.data.sesionesPerdidas) > 5}">{{red.data.sesionesPerdidas}}</p>
            </div>
          </div>
          
          <div class="row infoMonitor" v-tooltip.top="'Pacientes en espera de atención'">
            <div class="col-4 d-flex flex-row justify-content-center align-items-center">
              <font-awesome-icon class="dCola h4" icon="user-injured"/><font-awesome-icon class="dCola h5" icon="user-friends"/>
            </div>
            <div class="col-8 d-flex flex-row justify-content-center align-items-center">
              <p class="PxCola" :class="{'text-dark':red.data.cola<=0,'text-success':red.data.cola<=6, 'text-orange':red.data.cola>=7, 'text-danger': red.data.cola>=50}" >{{red.data.cola}}</p>
            </div>
          </div>
          
        </div>
        
      </template>
    </Carousel>
  </div>
</template>
<script>
import Carousel from 'primevue/carousel'
import Tooltip from 'primevue/tooltip'
import SocketIo from 'socket.io-client'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faComment, faUserInjured, faRobot, faUser, faUserFriends, faUserTimes, faThLarge, faListUl, faUserClock, faClock, faThumbtack } from '@fortawesome/free-solid-svg-icons'
library.add(faComment, faUserInjured, faRobot, faUser, faUserFriends, faUserTimes, faThLarge, faListUl, faUserClock, faClock, faThumbtack)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
export default {
    name: 'DatosXred',
    data() {
      return {
        datos: { "whatsapp": { "pacientes": [ { "count": "-1", "createdAt": "2020-08-20" } ], "atendidos": [ { "count": "-1", "createdAt": "2020-08-20" } ] }, "facebook": { "pacientes": [ { "count": "-1", "createdAt": "2020-08-20" } ], "atendidos": [ { "count": "-1", "createdAt": "2020-08-20" } ] } },
        cola: { "facebook": { "cola": -1 }, "whatsapp": { "cola": -1 } },
        //hola:null,
        hover: false,
        rol: JSON.parse(sessionStorage.getItem('sesion')).rol_id,
        pin:false,
        minutoWA:'00',
        minutoFB:'00',
        horaWA:'00',
        horaFB:'00',
        tiempos:null,
        hemodialisis:-1,
        sesiones:[{atendidos:0}],
        colaAntigua:null,
        nicaragua:-1,
        salvador: -1,
        redes:[{
          id:1,
          pxtotales:-1,
          pxbot:-1,
          pxasesor:-1,
          hora: -1,
          minuto:-1,
          tiempoMax:-1,
          sesionesPerdidas:-1,
          cola:-1,
        },{
          id:2,
          pxtotales:-1,
          pxbot:-1,
          pxasesor:-1,
          hora: -1,
          minuto:-1,
          tiempoMax:-1,
          sesionesPerdidas:-1,
          cola:-1,
        },{
          id:3,
          pxtotales:-1,
          pxbot:-1,
          pxasesor:-1,
          hora: -1,
          minuto:-1,
          tiempoMax:-1,
          sesionesPerdidas:-1,
          cola:-1,
        },{
          id:4,
          pxtotales:-1,
          pxbot:-1,
          pxasesor:-1,
          hora: -1,
          minuto:-1,
          tiempoMax:-1,
          sesionesPerdidas:-1,
          cola:-1,
        },{
          id:5,
          pxtotales:-1,
          pxbot:-1,
          pxasesor:-1,
          hora: -1,
          minuto:-1,
          tiempoMax:-1,
          sesionesPerdidas:-1,
          cola:-1,
        },]
      }
    },
    components: {
        FontAwesomeIcon,
        Carousel
    },
    directives: {
      'tooltip': Tooltip
    },
    created(){
      let data = {
        query: {
            "Tipo": "1"
        }
      }

      var socket = SocketIo.connect('https://socket-ws.salud-digna.site', data)
      socket.on('pacientesPorDiaRedes',  (payload) => {
        this.datos=payload
        this.redes[0].pxtotales=this.datos.whatsapp.pacientes[0].count
        this.redes[0].pxbot=(this.datos.whatsapp.pacientes[0].count-this.datos.whatsapp.atendidos[0].count)
        this.redes[0].pxasesor=this.datos.whatsapp.atendidos[0].count
        this.redes[1].pxtotales=this.datos.facebook.pacientes
        this.redes[1].pxbot=(this.datos.facebook.pacientes-this.datos.facebook.atendidos)
        this.redes[1].pxasesor=this.datos.facebook.atendidos
        this.redes[2].pxtotales=this.datos.nicaragua.pacientes
        this.redes[2].pxbot=(this.datos.nicaragua.pacientes-this.datos.nicaragua.atendidos)
        this.redes[2].pxasesor=this.datos.nicaragua.atendidos
        this.redes[3].pxtotales=this.datos.hemodialisis.pacientes
        this.redes[3].pxbot=(this.datos.hemodialisis.pacientes-this.datos.hemodialisis.atendidos)
        this.redes[3].pxasesor=this.datos.hemodialisis.atendidos
        this.redes[4].pxtotales=this.datos.salvador.pacientes
        this.redes[4].pxbot=(this.datos.salvador.pacientes-this.datos.salvador.atendidos)
        this.redes[4].pxasesor=this.datos.salvador.atendidos
      })
      socket.on('pedirColaRedes',  (payload) => {
        this.cola=payload
        this.redes[0].cola=this.cola.whatsapp.cola
        this.redes[1].cola=this.cola.facebook.cola
      })
      socket.on('AntiguoCola',  (payload) => {
        this.colaAntigua=payload
        this.redes[0].tiempoMax=this.colaAntigua.whatsapp.tiempo
        this.redes[1].tiempoMax=this.colaAntigua.facebook.tiempo
        this.redes[2].tiempoMax=this.colaAntigua.nicaragua.tiempo
        this.redes[3].tiempoMax=this.colaAntigua.hemodialisis.tiempo
        this.redes[4].tiempoMax=this.colaAntigua.salvador.tiempo
      })
      // socket.on('parametros',  (payload) => {
      //   this.hola=payload
      // })
      socket.on('HorasAtencionRedes',  (payload) => { 
        this.tiempos=payload
        if(payload.facebook.tiempo.minutes<10){
          this.minutoFB=  payload.facebook.tiempo.minutes ? '0'+payload.facebook.tiempo.minutes :'00'
        }else if(payload.facebook.tiempo.minutes==0 || payload.facebook.tiempo.minutes===undefined){
          this.minutoFB='00'
        }else{
          this.minutoFB=payload.facebook.tiempo.minutes
        }
        if(payload.whatsapp.tiempo.minutes<10){
          this.minutoWA= payload.whatsapp.tiempo.minutes ? '0'+payload.whatsapp.tiempo.minutes :'00'
        }else if(payload.whatsapp.tiempo.minutes==0 || payload.whatsapp.tiempo.minutes===undefined){
          this.minutoWA='00'
        }else{
          this.minutoWA=payload.whatsapp.tiempo.minutes
        }
        
        if(payload.whatsapp.tiempo.hours==0 || payload.whatsapp.tiempo.hours===undefined){
          this.horaWA="0"
        }else{
          this.horaWA=payload.whatsapp.tiempo.hours
        }
        if(payload.facebook.tiempo.hours==0 || payload.facebook.tiempo.hours===undefined){
          this.horaFB="0"
        }else{
          this.horaFB=payload.facebook.tiempo.hours
        }
        
        this.redes[0].minuto=this.minutoWA
        this.redes[0].hora=this.horaWA
        this.redes[1].minuto=this.minutoFB
        this.redes[1].hora=this.horaFB
        this.redes[2].minuto=this.tiempos.nicaragua.tiempo.minutes
        this.redes[2].hora=this.tiempos.nicaragua.tiempo.hours
        this.redes[3].minuto=this.tiempos.hemodialisis.tiempo.minutes
        this.redes[3].hora=this.tiempos.hemodialisis.tiempo.hours
        this.redes[4].minuto=this.tiempos.salvador.tiempo.minutes
        this.redes[4].hora=this.tiempos.salvador.tiempo.hours
      })
      let colaNicaragua = {
            query: {
                "Tipo": "2"
            }
      }
      var socketNicaragua = SocketIo.connect('https://socket-ws.salud-digna.site', colaNicaragua)
      socketNicaragua.on('pedirColaNicaragua',  (payload) => {
          this.nicaragua=payload
          this.redes[2].cola=this.nicaragua.cola
      })
      socket.on('pedirColaHemodialisis',  (payload) => {
          this.hemodialisis=payload
          this.redes[3].cola=this.hemodialisis.cola
      })
      socket.on('pedirColaSalvador',  (payload) => {
          this.salvador=payload
          this.redes[4].cola=this.salvador.cola
      })
      socket.on('SesionesP',  (payload) => {
          this.sesiones=payload
          this.redes[0].sesionesPerdidas=this.sesiones.whatsapp.sesiones
          this.redes[1].sesionesPerdidas=this.sesiones.facebook.sesiones
          this.redes[2].sesionesPerdidas=this.sesiones.nicaragua.sesiones
          this.redes[3].sesionesPerdidas=this.sesiones.hemodialisis.sesiones
          this.redes[4].sesionesPerdidas=this.sesiones.salvador.sesiones
      })
      
    },
    methods:{
      
    }
}
</script>