import axios from 'axios'
import config from './config'
//const network = localStorage.getItem('network')
const token = sessionStorage.getItem('token')
//const ruta = window.location.href
let apiFB = axios.create({
    
    baseURL: config.baseurlFB, //(network=='FB'&&ruta=='/chat')?config.baseurlFB:config.BASEURL,
    headers: {
        Authorization: `${token}`
    }
})

export default apiFB