<template>
<div class>
    <div class="row justify-content-around">

    <div class="modal-content-BWA col-5"  >
        <div id="headerWA">
        <img id="logoWAM" src="https://sfo2.digitaloceanspaces.com/sd-storage/PLATAFORMA-INBOX/imagenes/burbuja/whatsapp-logo.png" alt="Logo de whatsapp para Salud Digna">
        <img id="logoSDBlancoBWA" src="https://sfo2.digitaloceanspaces.com/sd-storage/PLATAFORMA-INBOX/imagenes/burbuja/LogoSD.png" alt="Logo Salud Digna">
        </div>
        <div id="mensajecontBWA">
            <div ><a class="h5">{{msj}}</a></div>
        <div id="mensajeCabBWA"></div>
        </div>
            <div id="listacontBWA">
            <ul id="listaDeBotonesBWA">
                <draggable
                            :list="arrastraBoton"
                            :disabled="!enabled"
                            class="list-group"
                            ghost-class="ghost"
                            @start="dragging = true"
                            @end="dragging = false,guardarOrden()"
                        >
                            <div
                            class="list-group-item"
                            style="width: 105%; padding: 10px; margin: 5px; background: #F8F8F8 0% 0% no-repeat padding-box;border: 1px solid #157EFB;border-radius: 4px;opacity: 1; text-align: left; font: normal normal normal 16px/20px ;letter-spacing: 0px;color: #616161;opacity: 1;"
                            v-for="sboton in arrastraBoton"
                            :key="sboton.id">
                            <div class="row">
                                <div class="col-sm-8">
                                    <div><h4 v-if="sboton.estatus==1" > <i class="icon-ok" style="color: #45c655;"/>  {{ sboton.descripcion }}</h4></div>
                                    <div><h4 v-if="sboton.estatus==0" > <i class="icon-cancel" style="color: #dc3545;"/>  {{ sboton.descripcion }}</h4></div>
                                </div>
                                
                                <div class="col-sm-4">
                                    <button class="btn float-right" @click="eliminarBoton(sboton)" style="background-color: transparent; color: #dc3545;"><font-awesome-icon icon="trash"/></button>
                                    <button class="btn float-right" @click="editarBoton(sboton)" style="background-color: transparent; color: #157EFB;"><font-awesome-icon icon="pencil-alt"/></button>
                                    
                                </div>
                            </div>
                            </div>
                        </draggable>
                </ul>
            </div>
    <button id="whatsappbotonBur" @click="nuevoBoton()">
      <img id="imgbotonBWA" src="https://sfo2.digitaloceanspaces.com/sd-storage/PLATAFORMA-INBOX/imagenes/burbuja/awesome-paper-plane.png">
      <span id="textobotonBWA" style="margin-left:10px">Nuevo Boton</span>
    </button>
  </div>

            <table class="table table-hover table-reflow table-bordered col-5" style="z-index:99">
                <thead class="thead-light">
                            <h4>Mensajes</h4>
                            <tr>
                            <th>Frase</th>
                            <th>Estatus</th>
                            <th>Nuevo <button class="btn btn-primary btn-sm" @click="nuevoMensaje()"><font-awesome-icon icon="plus"/></button></th>
                            </tr>
                </thead>
                <tbody>
                    
                    <tr v-for="mensaje in mensajes" :key="mensaje.id" >
                        <th scope="row"><b class="h5">{{mensaje.frase}}</b></th>
                        <td class="h5">
                            <a v-if="mensaje.estatus==0" class="pt-5 my-2" style="font-size: 1em">
                            Inactivo
                            </a>
                            <a v-if="mensaje.estatus==1" class="pt-5 my-2" style="font-size: 1em">
                            Activo
                            </a></td>
                        <td>
                            <button class="btn btn-warning btn-sm" @click="editarMensaje(mensaje)"><font-awesome-icon icon="edit"/></button><button class="btn btn-danger btn-sm" @click="eliminarMensaje(mensaje)"><font-awesome-icon icon="trash"/></button>
                        </td>
                        
                    </tr>
                </tbody>
            </table>
    </div>
    
    <Sidebar :visible="panelLateralB" position="right" class="lateralGeneral">
    <div v-if="nuevoB">
        <h4 v-if="nuevoB==true"><b>Nuevo Boton</b></h4>
            <h4 v-else><b>Editar Boton</b></h4>
                <div class="contenidoAsesorParametros mt-4">
                        <div class="input-group input-group-sm mb-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text" style="width: 115px;" >Descripcion</span>
                                <textarea v-model="seleccionadoBoton.descripcion" type="text" class="form-control" rows="3" cols="40" maxlength="40"></textarea>
                            </div>
                        </div>
                        <div class="input-group input-group-sm mb-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text" style="width: 100px;" >Enlace</span>
                                <textarea v-model="seleccionadoBoton.frase" type="text" class="form-control" rows="3" cols="40"></textarea>
                            </div>
                        </div>
                    <div class="input-group input-group-sm mb-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Estatus</span>
                            <div class="form-check">
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="seleccionadoBoton.estatus">
                                        <label class="custom-control-label" for="customSwitch1" ></label>
                                    </div> 
                            </div>      
                        </div>
                    </div>
                    <button v-if="seleccionadoBoton.frase=='' || seleccionadoBoton.descripcion=='' || !seleccionadoBoton.frase || !seleccionadoBoton.descripcion" class="btn btn-success btn-sm float-right mt-2" disabled >Guardar <font-awesome-icon icon="save"/></button>
                    <button v-else class="btn btn-success btn-sm float-right mt-2" @click="guardarBoton(seleccionadoBoton.frase,seleccionadoBoton.descripcion,seleccionadoBoton.estatus,sessionStorageUser.id)">Guardar <font-awesome-icon icon="save"/></button>        
                </div>
    </div>
    <div v-else-if="nuevoB==false">
    <h4 v-if="nuevoB==true"><b>Editar Boton</b></h4>
            <h4 v-else><b>Editar Boton</b></h4>
                <div class="contenidoAsesorParametros mt-4">
                        <div class="input-group input-group-sm mb-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text" style="width: 115px;" >Descripcion</span>
                                <textarea v-model="seleccionadoBoton.descripcion" type="text" class="form-control" rows="3" cols="40" maxlength="40"></textarea>
                            </div>
                        </div>
                        <div class="input-group input-group-sm mb-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text" style="width: 100px;" >Enlace</span>
                                <textarea v-model="seleccionadoBoton.frase" type="text" class="form-control" rows="3" cols="40"></textarea>
                            </div>
                        </div>
                    <div class="input-group input-group-sm mb-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Estatus</span>
                            <div class="form-check">
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="seleccionadoBoton.estatus">
                                        <label class="custom-control-label" for="customSwitch1" ></label>
                                    </div> 
                            </div>      
                        </div>
                    </div>
                    <button v-if="seleccionadoBoton.frase=='' || seleccionadoBoton.descripcion=='' || !seleccionadoBoton.frase || !seleccionadoBoton.descripcion" class="btn btn-success btn-sm float-right mt-2" disabled >Guardar <font-awesome-icon icon="save"/></button>
                    <button v-else class="btn btn-success btn-sm float-right mt-2" @click="guardarBoton(seleccionadoBoton.frase,seleccionadoBoton.descripcion,seleccionadoBoton.estatus,sessionStorageUser.id)">Guardar <font-awesome-icon icon="save"/></button>
                </div>
    </div>
    
        <button class="btn btn-secondary btn-sm float-left mt-2" @click="cerrar()">Cerrar</button>
         
    </Sidebar>

    <Sidebar :visible="panelLateralM" position="right" class="lateralGeneral">
        <div v-if="nuevoM">
        <h4 v-if="nuevoM==true"><b>Nuevo Mensaje</b></h4>
        <h4 v-else><b>Editar Mensaje</b></h4>
            <div class="contenidoAsesorParametros mt-4">
                    <div class="input-group input-group-sm mb-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width: 100px;" >Frase</span>
                            <textarea v-model="seleccionadoMensaje.frase" type="text" class="form-control" rows="3" cols="40"></textarea>
                        </div>
                    </div>
                <div class="input-group input-group-sm mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Estatus</span>
                        <div class="form-check">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="seleccionadoMensaje.estatus">
                                    <label class="custom-control-label" for="customSwitch1" ></label>
                                </div> 
                        </div>      
                    </div>
                </div>
                <button v-if="seleccionadoMensaje.frase=='' || !seleccionadoMensaje.frase" class="btn btn-success btn-sm float-right mt-2" disabled >Guardar <font-awesome-icon icon="save"/></button>
                <button v-else class="btn btn-success btn-sm float-right mt-2" @click="guardarMensaje(seleccionadoMensaje.frase,seleccionadoMensaje.estatus,sessionStorageUser.id)">Guardar <font-awesome-icon icon="save"/></button>        
            </div>
    </div>
    <div v-else-if="nuevoM==false">
        <h4 v-if="nuevoM==true"><b>Nuevo Mensaje</b></h4>
        <h4 v-else><b>Editar Mensaje</b></h4>
            <div class="contenidoAsesorParametros mt-4">
                    <div class="input-group input-group-sm mb-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width: 100px;" >Frase</span>
                            <textarea v-model="seleccionadoMensaje.frase" type="text" class="form-control" rows="3" cols="40"></textarea>
                        </div>
                    </div>
                <div class="input-group input-group-sm mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Estatus</span>
                        <div class="form-check">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="seleccionadoMensaje.estatus">
                                    <label class="custom-control-label" for="customSwitch1" ></label>
                                </div> 
                        </div>      
                    </div>
                </div>
                <button v-if="seleccionadoMensaje.frase=='' || !seleccionadoMensaje.frase" class="btn btn-success btn-sm float-right mt-2" disabled >Guardar <font-awesome-icon icon="save"/></button>
                <button v-else class="btn btn-success btn-sm float-right mt-2" @click="guardarMensaje(seleccionadoMensaje.frase,seleccionadoMensaje.estatus,sessionStorageUser.id)">Guardar <font-awesome-icon icon="save"/></button>        
            </div>
    </div>
    <button class="btn btn-secondary btn-sm float-left mt-2" @click="cerrar()">Cerrar</button>
    </Sidebar>

    <Sidebar :visible="panelLateralOrden" position="right" class="lateralGeneral">
        <div>
            <h4><b>Editar Orden</b></h4>
            <div class="col-12">

                <draggable
                    :list="arrastraBoton"
                    :disabled="!enabled"
                    class="list-group"
                    ghost-class="ghost"
                    @start="dragging = true"
                    @end="dragging = false,guardarOrden()"
                >
                    <div
                    class="list-group-item"
                    v-for="sboton in arrastraBoton"
                    :key="sboton.id"
                    >
                    {{ sboton.frase }}
                    </div>
                </draggable>
                </div>
                <button class="btn btn-success btn-sm float-right mt-2" @click="guardarOrden()">Guardar <font-awesome-icon icon="save"/></button>
        </div>
        <button class="btn btn-secondary btn-sm float-left mt-2" @click="cerrar()">Cerrar</button>
    </Sidebar>
</div>
</template>
<script>
import Sidebar from 'primevue/sidebar'
import asesorService from "../../services/asesor"
import 'vue-select/dist/vue-select.css'

import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
import {
    library
} from "@fortawesome/fontawesome-svg-core";
import {
    faTimes,faReply,faFileExcel,faTrash,faFilePdf,faPencilAlt,faCheck,faCross
} from "@fortawesome/free-solid-svg-icons";
library.add(faTimes, faReply, faFileExcel,faTrash,faFilePdf,faPencilAlt,faCheck,faCross);
import draggable from 'vuedraggable'

export default{
    data(){
        return{
            sessionStorageUser: JSON.parse(sessionStorage.getItem('sesion')),
            panelLateralB:false,
            panelLateralM:false,
            nuevoB:false,
            nuevoM:false,
            seleccionadoMensaje:{},
            seleccionadoBoton:{},
            mensajes:null,
            botones:null,
            panelLateralOrden:false,
            dragging: false,
            enabled: true,
            arrastraBoton:null,
            msj:null
        }
    },
    components:{
        FontAwesomeIcon,
        Sidebar,
        draggable
    },
    mounted(){
        this.inicio()
    },
    methods:{
        inicio(){
            asesorService.getBotones().then(respuestas => {
                    return this.botones = respuestas    
            })
            asesorService.getMensajes().then(resp => {
                this.mensajes = resp
                this.msj=resp.filter(item=>item.estatus==1)
                this.msj = this.msj.length > 0 ? this.msj[0].frase : ""
            })
            asesorService.getBotones().then(respuestas => {
                    return this.arrastraBoton = respuestas    
            })
        },
        cerrar(){
            this.nuevoB = false
            this.nuevoM = false
            this.panelLateralB=false
            this.panelLateralM=false
            this.seleccionadoBoton={}
            this.seleccionadoMensaje={}
            this.panelLateralOrden=false
        },
        nuevoBoton(){
            this.panelLateralB=!this.panelLateralB
            this.nuevoB=true
        },
        nuevoMensaje(){
            this.panelLateralM=!this.panelLateralM
            this.nuevoM=true  
        },
        eliminarBoton(boto){
            asesorService.desactivarBoton(boto.id).then(resp =>{
                if(resp){
                    this.cerrar()
                    this.inicio()
                }
            })  
        },
        eliminarMensaje(mns){
            asesorService.desactivarMensaje(mns.id).then(resp =>{
                if(resp){
                    this.cerrar()
                    this.inicio()
                }
            })  
        },
        guardarBoton(a,b,c,d){
            if(this.nuevoB){
                asesorService.crearBoton(a,b,c === undefined ? 1 : c === true ? 1 : 0,d).then(resp=>{
                    if(resp){
                            this.cerrar()
                            this.inicio()
                    }
                })
            }else{
                asesorService.actualizarBoton(this.seleccionadoBoton.id,a,b,c === true ? 1 : c === false ? 0 : c,d).then(resp=>{
                    if(resp){
                            this.cerrar()
                            this.inicio()
                    }
                })
            }
            
        },
        guardarMensaje(a,b,c){
            if(this.nuevoM){
                asesorService.crearMensaje(a,b === undefined ? 1 : b === true ? 1 : 0,c).then(resp=>{
                    if(resp){
                            this.cerrar()
                            this.inicio()
                        }
                })
            }else{
                asesorService.actualizarMensaje(this.seleccionadoMensaje.id,a,b === true ? 1 : b === false ? 0 : b,c).then(resp=>{
                    if(resp){
                            this.cerrar()
                            this.inicio()
                        }
                })
            }
            
        },
        editarBoton(boto){ 
            this.seleccionadoBoton=Object.assign({}, boto)
            this.panelLateralB=true
        },
        editarOrden(bo){
            this.arrastraBoton=Object.assign([], bo)
            this.arrastraBoton = this.arrastraBoton.filter((item)=>item.estatus===1)
            this.panelLateralOrden=true
        },
        async guardarOrden(){
                await Promise.all(
                   this.arrastraBoton.map((e,i)=>asesorService.actualizarBotonOrden(e.id,i))
                )
                    this.cerrar()
                    this.inicio() 
        },
        editarMensaje(mnsa){ 
            this.seleccionadoMensaje=Object.assign({}, mnsa)
            this.panelLateralM=true
        }
    }
}
</script>

<style>
        .modalBWA {
      top: 10px;
      right: -5px;
      position: fixed; /* Stay in place */
      overflow: auto; /* Enable scroll if needed */
      width: auto;
      z-index: 99;
    }
    
    /* Modal Content/Box */
    .modal-content-BWA{
      background-color: #fefefe;
      margin-top: 20px; /* 15% from the top and centered */
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 4px;
      opacity: 1;
      padding-bottom: 17px;

    }
    
    /* The Close Button */
    .cerrarBWA {
      float: right;
      display: block;
      color: #45c655;
      text-align: center;
      border-radius: 100%;
      font-size:15px;
      background: #F1F1F1 0% 0% no-repeat padding-box;
      opacity: 1;
      height: 4%;
      width: 8%;
      margin-right: 6px;
    }
    
    .cerrarBWA:hover,
    .cerrarBWA:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
    #logoSDBlancoBWA{
      width: 103px;
      height: 32px;
      border-left: 1px solid #FFFFFF; 
      padding-left: 10px;
      margin-bottom: 5px;
    }
    #logoWAM{
      width: 43px;
      height: 43px;
      padding: 5px;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 5px;
    }
    #whatsappbotonBur{
      background: #45C655 0% 0% no-repeat padding-box;
      border-radius: 4px;
      opacity: 1;
      border: 0px;
      display: block;
      margin-top: 15px;
      margin-left: auto;
      margin-right: 16px;
      width: auto;
    }
    #imgbotonBWA{
      padding-left: 10px;
      padding-top: 9.5px;
      margin-bottom: 9.5px;
    }
    #textobotonBWA{
      text-align: left;
      font: normal normal bold 16px/20px 'Open Sans';
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
      padding-right: 13px;
      padding-top: 9.5px;
      display: inline-block;
      padding-bottom: 9.5px;
      width: auto;
      height: 32px;
      margin-left: 3px;
    }
    #headerWA{
      background: #45C655 0% 0% no-repeat padding-box;
      border: 1px solid #45C655;
      border-radius: 4px 4px 0px 0px;
      opacity: 1;
/*       padding-top: 5px; */
      width:auto;
      margin-left:-15px;
      margin-right:-15px;
    }
    #mensajecontBWA{
      padding: 10px;
      margin-bottom: 15px;
      margin-top: 10px;
      background: #F8F8F8 0% 0% no-repeat padding-box;
      border-radius: 4px 4px 4px 0px;
      opacity: 1; 
      max-height: auto;
    }
    #mensajeCabBWA{
      text-align: left;
    }
    #listacontBWA{
      position: relative; right: 35px; margin-top: -10px; margin-bottom: -10px;
    }
    #listaDeBotonesBWA{
      list-style:none;
    }
</style>