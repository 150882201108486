<template>
    <div>
      
        <h4 class="text-black-50 font-weight-bold">WHATSAPP <i class="icon-whatsapp "></i></h4>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-md-3">
            <div class="card resultadosCard p-3">
              <div class="tipo borderAbajo my-3"><b class="h5 font-weight-bold">Resultados Total: </b><b class="h5">{{resultadosTotalesWhatsapp.TotalDia}}</b></div>
              <div class="tipo borderAbajo my-3"><b class="h6 font-weight-bold">COVID-19 🦠👌: </b> <b class="h6 font-weight-bold">{{resultadosTotalesWhatsapp.TotalCovid}}</b></div>
              <div class="tipo borderAbajo my-3"><b class="h6 font-weight-bold">Laboratorio 🔬: </b> <b class="h6 font-weight-bold">{{resultadosTotalesWhatsapp.Laboratorio}}</b></div>
              <div class="tipo borderAbajo my-3"><b class="h6 font-weight-bold">Examen de la vista 👁: </b> <b class="h6 font-weight-bold">{{resultadosTotalesWhatsapp.ExamenVista}}</b></div>
              <div class="tipo borderAbajo my-3"><b class="h6 font-weight-bold">OPCs 👓: </b> <b class="h6 font-weight-bold">{{resultadosTotalesWhatsapp.Lentes}}</b></div>
            </div>
            
          </div>
          <div class="col-12 col-md-3 ">
            <div class="card resultadosCard p-3">
              <div class="tipo borderAbajo my-3"><b class="h5 font-weight-bold">Total Covid-19: </b><b class="h5">{{resultadosTotalesWhatsapp.TotalCovid}}</b></div>
              <div class="tipo my-3"><b class="h6 font-weight-bold">Prueba PCR: </b><b class="h6">{{resultadosTotalesWhatsapp.TotalPCR}}</b></div>
              <div class="tipo"><b class="h6 ml-1">Entregado: </b><b class="h6">{{resultadosTotalesWhatsapp.CovidEntregado}}</b></div>
              <div class="tipo"><b class="h6 ml-1">Pendiente: </b><b class="h6">{{resultadosTotalesWhatsapp.CovidPendiente}}</b></div><br>
              <div class="tipo my-3"><b class="h6 font-weight-bold">Prueba Antigenos: </b><b class="h6">{{resultadosTotalesWhatsapp.TotalAntigeno}}</b></div>
              <div class="tipo"><b class="h6 ml-1">Entregado: </b><b class="h6">{{resultadosTotalesWhatsapp.AntigenoEntregado}}</b></div>
              <div class="tipo"><b class="h6 ml-1">Pendiente: </b><b class="h6">{{resultadosTotalesWhatsapp.AntigenoPendiente}}</b></div><br>
              
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="card resultadosCard p-3">
              <div class="tipo my-3 borderAbajo"><b class="h5 font-weight-bold ">Comparativa: </b><b class="h6 text-danger">{{hoy}}</b></div>
              <Chart v-if="this.chartData.datasets[0].data[0]" type="doughnut" :data="chartData" />
              
            </div>
          </div>
          <div class="col-12 col-md-2">
            <div class="card resultadosCard p-3">
              <div class="my-3"><span class="h5 font-weight-bold">Resultados: </span></div>
              <div class=" my-3"><b class="h6">Pendientes: </b> <b class="h6 font-weight-bold">{{resultadosTotalesWhatsapp.Pendiente}}</b></div>
              <div class=" my-3"><b class="h6">Entregados: </b> <b class="h6 font-weight-bold">{{resultadosTotalesWhatsapp.TotalDia}}</b></div>
              <div class="d-flex flex-column justify-content-center h-100" style="-webkit-box-align: center!important;">
                <div class="progress">
                <div class="progress-bar progress-bar-striped progress-bar-animated rosaGrafica" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" :style="'width: '+(((parseInt(resultadosTotalesWhatsapp.TotalDia)-parseInt(resultadosTotalesWhatsapp.Pendiente))*100)/(parseInt(resultadosTotalesWhatsapp.TotalDia)+parseInt(resultadosTotalesWhatsapp.Pendiente)))+'%'"></div>
              </div>
              <h2>{{(((parseInt(resultadosTotalesWhatsapp.TotalDia)-parseInt(resultadosTotalesWhatsapp.Pendiente))*100)/(parseInt(resultadosTotalesWhatsapp.TotalDia)+parseInt(resultadosTotalesWhatsapp.Pendiente))).toFixed(2)}}%</h2>
              </div>
            </div>
            
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <div class="card resultadosCard p-3 graficaResultadosWhatsapp">
              <Chart type="bar" v-if="this.basicData.datasets[0]" :data="basicData" :options="options" height="75" />
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import Tooltip from 'primevue/tooltip'
import SocketIo from 'socket.io-client'
import Chart from 'primevue/chart'
import dashboardService from '@/services/dashboard'
export default {
    name:'Whatsapp',
    data() {
      return {
        resultadosTotalesWhatsapp:-1,
        totalesGraficaMensualWhatsApp: -1,
        hoy: null,
        basicData:{
          labels:[],
          datasets: []
        },
        chartData: {
          labels: ['Notificación','Bot','Plataforma'],
          datasets: [
            {
              data: [0, 0, 0],
              backgroundColor: [
                "#FFC830",
                "#99D992",
                "#7ED3EE"
              ],
              hoverBackgroundColor: [
                "#FFC830",
                "#99D992",
                "#7ED3EE"
              ]
            }
          ]
        },
        options:{
          responsive: true,
          // tooltips: {
          //   mode: 'index',
          //   intersect: false,
          // },
          legend: {
            display:false
          },
          scales: {
            yAxes: [{
                display: true,
                ticks: {
                    beginAtZero: true
                },
            }]
        }
        },
      }
    },
    components: {
        'Chart': Chart
    },
    props:{
        ruta: String,
        data: Object
    },
    directives: {
      'tooltip': Tooltip
    },
    created(){
      var moment = require('moment')
      moment.locale('es')
      this.hoy = moment().format('LL')
      this.get()
    },
    methods:{
      get(){
        var moment = require('moment')
        moment.locale('es')
        var socket = SocketIo.connect(this.ruta, this.data)
        socket.on('resultadosTotalesWhatsapp',  (mensaje) => {
          this.resultadosTotalesWhatsapp=mensaje
          this.chartData.datasets[0].data=[parseInt(this.resultadosTotalesWhatsapp.EntregadoNotificacion), parseInt(this.resultadosTotalesWhatsapp.EntregadoBot), parseInt(this.resultadosTotalesWhatsapp.EntregadoPlataforma)]
        })
        // socket.on('totalesGraficaMensualWhatsApp',  (mensaje) => {
        //   this.totalesGraficaMensualWhatsApp=mensaje
        //     this.basicData.labels.push(moment.months(moment().month()))
        //   this.totalesGraficaMensualWhatsApp.map(x =>{
        //     this.basicData.datasets.push({
        //       label: moment(x.dia).format('ll'),
        //       backgroundColor: '#FFC830',
        //       data: [x.cantidad]
        //     })
        //     return x
        //   })
        // this.$forceUpdate
        // })
        dashboardService.getMesWhatsapp().then(mensaje =>{
          this.totalesGraficaMensualWhatsApp=mensaje
          this.basicData.labels.push(moment.months(moment().month()))
          this.totalesGraficaMensualWhatsApp.map(x =>{
            this.basicData.datasets.push({
              label: moment(x.dia).format('ll'),
              backgroundColor:  moment(x.dia).date()%2==0?'#ffc830':'#99d992',
              data: [x.cantidad]
            })
            return x
          })
        this.$forceUpdate
        })
      }
    }
}
</script>