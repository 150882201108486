<template>
  <div class="container">
    <!-- <Toast /> -->
    <div class="accordion" id="accordionParametros">
      <div class="card">
        <div class="card-header">
          <b>{{controlName}}</b>
          <h2 class="mb-0">
            <button @click="btnGuardarParametro" v-if="agregar" class="btn btn-success float-right"><font-awesome-icon icon="save" /></button>
            <button :class="{'btn btn-primary float-right': !agregar,'btn btn-danger float-right mx-3': agregar}" @click="btnAgregar" type="button" data-toggle="collapse" data-target="#collapseParametros" aria-expanded="true" aria-controls="collapseParametros"><font-awesome-icon v-if="agregar" icon="times" /><font-awesome-icon v-else icon="plus" /></button>
          </h2>
        </div>
        <div id="collapseParametros" class="collapse" data-parent="#accordionParametros">
          <div class="card-body">
            <div class="container">
              <!-- <div class="row">
            <div class="col-md-4"><label for="tipo">Tipo:</label></div>    
            <div class="col-md-8"><select class="custom-select" id="tipo"><option selected :value="0">Copy</option><option :value="1">Configuracion</option></select></div>
              </div>-->
              <div class="row">
                <div class="col-md-4"><label for="nombre">Nombre:</label></div>
                <div class="col-md-8"><input type="text" class="form-control" id="nombre_parametro" v-model="item_parametro.nombre" /></div>
              </div>
              <div class="row">
                <div class="col-md-4"><label for="valor">Valor:</label></div>
                <div class="col-md-8"><textarea class="form-control" rows="5" id="valor_parametro" v-model="item_parametro.valor"></textarea></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
          <!-- style="table-layout: auto" -->

    <DataTable :value="parametros" editMode="row" data-key="id" :editingRows.sync="editingRowsParametros" @row-edit-init="onRowEditInitParametros" @row-edit-cancel="onRowEditCancelParametros" @row-edit-save="onRowEditCompleteParametros">
      <template #empty>No se encontraron registros</template>
      <Column field="tipo" header="Tipo" bodyStyle="text-align:center" headerStyle="width: 10%"><template #editor="slotProps"><input disabled class="form-control"  v-model="slotProps.data[slotProps.column.field]" /></template></Column>
      <Column field="nombre" header="Nombre" bodyStyle="text-align:center" headerStyle="width: 15%"><template #editor="slotProps"><textarea class="form-control" rows="4"  v-model="slotProps.data[slotProps.column.field]" /></template></Column>
      <Column field="valor" header="Valor" bodyStyle="text-align:center" headerStyle="width: 25%"><template #editor="slotProps"><textarea class="form-control" rows="4" v-model="slotProps.data[slotProps.column.field]" /></template></Column>
      <Column field="status" header="Estatus" headerStyle="width: 20%" bodyStyle="text-align:center"><template #body="slotProps"><div v-if="slotProps.data.status==1" class="rounded bg-success text-white font-weight-bold p-2 text-center">Activo<font-awesome-icon spin icon="cog" /></div><div v-else class="rounded bg-info text-white font-weight-bold p-2 text-center"> Desactivado <font-awesome-icon icon="cog" /></div></template><template #editor="slotProps"><div v-if="slotProps.data.status==1" class="rounded bg-success text-white font-weight-bold p-2 text-center"> Activo <font-awesome-icon spin icon="cog" /></div><div v-else class="rounded bg-info text-white font-weight-bold p-2 text-center"> Desactivado <font-awesome-icon icon="cog" /></div></template></Column>
      <Column  header="Activar/Desactivar" headerStyle="width: 15%" bodyStyle="text-align:center"> <template #body="slotProps"><button @click="cambiarEstatusParametro(slotProps.data.id,slotProps.data.status,slotProps.index);" class="btn btn-sm btn-space " :class="{'btn-info':slotProps.data.status==0, 'btn-secondary':slotProps.data.status==1}"> <font-awesome-icon v-if="slotProps.data.status==1" icon="pause" /> <font-awesome-icon v-else icon="play" /> <span v-if="slotProps.data.status==1">  Desactivar</span> <span v-else>  Activar</span></button></template> <template #editor="slotProps"><button @click="cambiarEstatusParametro(slotProps.data.id,slotProps.data.status,slotProps.index);" class="btn btn-sm btn-space " :class="{'btn-info':slotProps.data.status==0, 'btn-secondary':slotProps.data.status==1}"><font-awesome-icon v-if="slotProps.data.status==1" icon="pause" /><font-awesome-icon v-else icon="play" /><span v-if="slotProps.data.status==1">  Desactivar </span><span v-else>  Activar</span></button></template></Column>
      <Column :rowEditor="true" bodyStyle="text-align:center" headerStyle="width: 15%"></Column>
    </DataTable>  
    </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import controlesServices from "../../../services/controles";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

import { faPlus, faTimes, faSave } from "@fortawesome/free-solid-svg-icons";
library.add(faPlus, faTimes, faSave);
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// import Toast from 'primevue/toast';

import Vue from "vue";

export default {
  name: "Parametros",
  data() {
    return {
      agregar: false,
      parametros: [],
      displayModalParametros: false,
      editingCellRows: {},
      editingRowsParametros: [],
      item_control: { nombre: null, descripcion: null },
      item_parametro: {},
    };
  },
  components: {
    DataTable,
    Column,
    FontAwesomeIcon,
    // Toast
  },
  props: {
    controlId: null,
    controlName:null
  },
  originalRows: null,
  created() {
    this.originalRows = {};
  },
  mounted() {
    this.item_parametro = {};
    this.item_parametro.controlid = this.controlId;
    controlesServices.getParametros(this.controlId).then((resp) => {
      this.parametros = resp;
    });
    this.agregar = false;
  },
  methods: {
    showSuccess() {
            this.$toast.add({severity:'success', summary: 'Operación completada', detail:'la acción se ha completado correctamente', life: 3000});
        },
        showError() {
            this.$toast.add({severity:'error', summary: 'Error', detail:'Ha ocurrido un error durante el proceso', life: 3000});
        },
    onRowEditCompleteParametros(event) {
      let datos={parametroid:event.data.id,nombre:event.data.nombre,valor:event.data.valor}
      controlesServices.updateParametro(datos).then(res=>{
        if(res!=null){
          this.showSuccess();
        }
        else{
          this.showError();
        }
      })
    },
    onCellEdit(newValue, props) {
      if (!this.editingCellRows[props.index]) {
        this.editingCellRows[props.index] = { ...props.data };
      }
      this.editingCellRows[props.index][props.column.field] = newValue;
    },
    onRowEditInitParametros(event) {
      this.originalRows[event.index] = { ...this.parametros[event.index] };
    },
    onRowEditCancelParametros(event) {
      Vue.set(this.parametros, event.index, this.originalRows[event.index]);
    },
    btnAgregar() {
      this.agregar = !this.agregar;
    },
    btnGuardarParametro() {
      let datos={controlid:this.item_parametro.controlid,nombre:this.item_parametro.nombre,valor:this.item_parametro.valor}
      controlesServices.AgregarParametro(datos).then((res) => {
        if(res!=null){
          this.showSuccess();
          this.parametros.push(res);
          this.btnAgregar();
          this.item_parametro.nombre=null;
          this.item_parametro.valor=null;
        }else{
          this.showError();
        }

      });
    },
    cambiarEstatusParametro(id,estatus,index){
        controlesServices.CambiarEstatusParametro(id, estatus).then(res=>{
          if(res.status==200){
              this.parametros[index].status=!this.parametros[index].status;
              this.showSuccess();
          }
        });
    }
  },
};
</script>