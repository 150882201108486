<template>
<div id="app" @clickAfuera="eventoClick">
    <router-view/>
</div>
</template>
<script>
export default {
    name: 'PlataformaINBOX',
    watch: {
        $route(to) {
            document.title = to.meta.title || 'Plataforma Whatsapp';
        },
    },
    directives:{
        clickAfuera:{
            bind () {
            this.event = event => this.vm.$emit(this.expression, event)
            this.el.addEventListener('click', this.stopProp)
            document.body.addEventListener('click', this.event)
            },   
            unbind() {
                this.el.removeEventListener('click', this.stopProp)
                document.body.removeEventListener('click', this.event)
            },

            stopProp(event) { event.stopPropagation() }
        }
        
    },
    methods:{
        eventoClick(){
            // if (document.getElementById('contenedorPerfil').contains(e.target)) {} else {
            //     var dropdowns = document.getElementById("miPerfil");
            //     var perfil = document.getElementById("perfil");
            //     var menuPerfil = document.getElementById("menuPerfil");
            //     var flecha = document.getElementById("toggleFlecha");
            //     flecha.classList.remove("icon-angle-up-1");
            //     flecha.classList.add("icon-angle-down-1");
            // if (dropdowns.classList.contains('show')) {
            //     dropdowns.classList.remove('show');
            // }
            // if (perfil.classList.contains('show')) {
            //     perfil.classList.remove('show');
            //     menuPerfil.classList.toggle("menuPerfil");
            // }

            // }
            // if (document.getElementById('tableroDeNotificacion').contains(e.target)) {} else {
            //     var dropdowns = document.getElementById("tableroDeNotificacion");
            //     if (dropdowns.classList.contains('show')) {
            //         dropdowns.classList.remove('show');
            //     }

            // }
        }
    }
}
</script>