// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCZyK8KQxAUspMbu-ukE-NG1fxlET4mnE8",
    authDomain: "chat-hibrido.firebaseapp.com",
    databaseURL: "https://chat-hibrido.firebaseio.com",
    projectId: "chat-hibrido",
    storageBucket: "chat-hibrido.appspot.com",
    messagingSenderId: "537167205842",
    appId: "1:537167205842:web:2770d8966eca2d45aca40b",
    measurementId: "G-RMRD63KCJ3"
};
const app = initializeApp(firebaseConfig);
// Initialize Firebase
export default getAuth(app)
// const analytics = getAnalytics(app);
// const db = getFirestore(app);