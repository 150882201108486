<template>
    <asesores />
</template>
<script>
import Asesores from '@/components/Dashboard/Tablas/Asesores.vue';
export default {
    name: 'Catalogo',
    components:{
        Asesores
    }
}
</script>