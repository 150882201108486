<template>
<div class="container">
    <div class="row">
        <div class="col-md-3" style="padding:1rem">
            <v-select class="pypCombo" placeholder="Buscar por tema" :options="temas" label="nombre"  v-model="selectRespuestas">
                <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                        No se encontró copy de: <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
                </template>
            </v-select>
        </div>
    </div>
    <table class="table table-hover table-reflow table-bordered">
        <thead class="thead-light">
            <tr>
                <th>Asesor</th>
                <th>Tema</th>
                <th>Copy</th>
                </tr>
        </thead>
        <tbody>
            <tr v-for="usuario in copys" :key="usuario.id" v-show="((selectRespuestas == null) || (usuario.yolo[0].id.includes(selectRespuestas.id)))">
                
                <th scope="row"><b class="h5">{{usuario.nombre}}</b></th>
                <td class="h5">{{usuario.tema}}</td>
                <td class="h5">{{usuario.respuesta}}</td>
                
            </tr>
        </tbody>
    </table>
    
</div>
</template>
<script>
import asesorService from "../../services/asesor"
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
export default {
    data() {
        return {
            copys: null,
            usuarioSesion:JSON.parse(sessionStorage.getItem('sesion')),
            panelLateral:false,
            seleccionado:{},
            esperar:false,
            nuevoUsuario:false,
            selectRespuestas:null,
            temas:[{id:'1',nombre:'Saludo'},
            {id:'2',nombre:'Despedida'},
            {id:'3',nombre:'Información general'},
            {id:'4',nombre:'Abreviatura'},
            {id:'5',nombre:'Queja'},
            {id:'6',nombre:'Paquetes'},
            {id:'7',nombre:'Lentes'},
            {id:'8',nombre:'Laboratorio'},
            {id:'9',nombre:'Mastografía'},
            {id:'10',nombre:'Ultrasonidos'},
            {id:'11',nombre:'Densitometría'},
            {id:'12',nombre:'Papanicolaou'},
            {id:'13',nombre:'Tomografía'},
            {id:'14',nombre:'Electrocardiograma'},
            {id:'15',nombre:'Rayos x'},
            {id:'16',nombre:'Resonancia'},
            {id:'17',nombre:'Nutrición'},
            {id:'18',nombre:'COVID'},
            {id:'19',nombre:'Devolución'},
            {id:'20',nombre:'Bolsa de trabajo'},
            {id:'21',nombre:'No se realiza'},
            {id:'22',nombre:'Medidas de prevención'},
            {id:'23',nombre:'Otro'},
            {id:'24',nombre:'Hemodialisis'},
            ],
        }
    },
    userservice: null,
    created() {
      
        //this.userservice = new userservice();
    },
    components:{
        vSelect
    },
    mounted() {
        this.inicio()
        
    },
    methods: {
        inicio(){
            asesorService.getRespuestas().then((respuestas) => { 
                respuestas.map(respuesta=>{
                    if(respuesta.tema!='COVID'){
                        respuesta.yolo=this.temas.filter(x=>x.id==respuesta.tema)
                        respuesta.tema=respuesta.yolo[0].nombre
                    }
                    
                    return respuesta
                })

                if(this.usuarioSesion.rol_id==2){
                    this.copys=respuestas.filter(x=> x.rol_id>=2)
                }else{
                    this.copys = respuestas
                }
             })
        },
        // nuevo(){
        //     this.panelLateral=true
        //     this.seleccionado={facebook:0,estatus:1,num_pacientes:0,rol_id:3,fecha_nacimiento:'1990-01-01',grupo_id:2, cola:'ASC'}
        //     this.nuevoUsuario=true
        // },
        // editar(user){
        //     this.nuevoUsuario=false
        //     this.seleccionado=user
        //     this.seleccionado.fecha_nacimiento?false:this.seleccionado.fecha_nacimiento='1990-01-01'
        //     this.panelLateral=true
        // },
        // eliminar(user){
        //     user.estatus=0
        //     let obj = {
        //         id: user.id,
        //         estatus:0
        //     }
        //     userservices.editarUsuario(obj).then(resp =>{
        //             if(resp){
        //                 this.cerrar()
        //                 this.inicio()
        //             }
        //         }).finally(()=>{
        //         this.esperar=false 
        //         })
        // },
        // guardar(){
        //     this.esperar=true
        //     if(this.nuevoUsuario){
        //         userservices.nuevoUsuario(this.seleccionado).then(resp =>{
        //             if(resp){
        //                 this.cerrar()
        //                 this.inicio()
        //             }
        //         }).finally(()=>{
        //         this.esperar=false 
        //         })
        //     }else{
        //         userservices.editarUsuario(this.seleccionado).then(resp =>{
        //             if(resp){
        //                 this.cerrar()
        //                 this.inicio()
        //             }
        //         }).finally(()=>{
        //         this.esperar=false 
        //         })
        //     }
        // },
        // cerrar(){
        //     this.panelLateral=false
        // }
    }
}
</script>
