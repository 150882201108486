<template>
    <div>
        <h1>Incidencias</h1>
    </div>
</template>
<script>

export default {
name: 'incidencia'    
}
</script>