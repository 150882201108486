<template>
    <Login/>
</template>

<script>
// @ is an alias to /src
import Login from '@/components/Login.vue'
// import LoginService from '../services'

export default {
  name: 'login',
  components: {
    Login
  },
  created(){
  }
}
</script>
