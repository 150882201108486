<template>
    <div>
        <h2>Comentarios Plataforma</h2>
        <div class="MenuComentarios">
            <div class="row">
                <div class="form-group col-md-3 col-11">
                    <label for="fechaInicial">Fecha inicial</label>
                    <input v-model="fecha_inicio" type="date" id="fecha_inicio" class="form-control"/>
                </div>
                <div class="form-group col-md-3 col-11">
                    <label for="fechaFinal">Fecha final</label>
                    <input v-model="fecha_final" type="date" id="theDate" class="form-control"/>
                </div>
                <div class="form-group col-md-3 col-11">
                    <label for="Asesor">Asesor</label>
                        <select v-model="lista" class="form-control" required>
                            <option disabled value="" hidden>Selccione un Asesor</option>
                            <option v-for="asesor in asesorSeleccionado" :key="asesor.id" :value="asesor" >{{ asesor.nombre }}</option>
                        </select>
                </div>
                <div class="form-group col-md-3 col-11 my-auto">
                    <button type="button" class="btn btn-primary" :disabled="lista == ''" @click="getComentarios()"> Consultar</button>
                    <button type="button" class="btn btn-secondary" @click="borrar()" style="margin-left: 10px;">Limpiar <font-awesome-icon icon="trash"/></button>
                </div>
            </div>
            <ve-table :columns="columns" :table-data="tableData" :paginate="true" :responsive="true" :lineNumbers="false" class="styled" styleClass="table">
            </ve-table>
        </div>
        <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalC">
  Launch demo modal
</button> -->
        <!--  Modal Notas -->
        <div class="modal fade" id="exampleModalC" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" role="dialog" data-backdrop="static"  >
            <div class="modal-dialog modal-dialog-centered " style="top:190px">
                <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title" id="exampleModalLabel">Notas</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm">
                        <h5 style="margin-top: 5px;">Seleccione un Asesor: </h5>

                            <select class="custom-select" v-model="asesorSelec" style="max-width: 465px;margin-left: 5px" disabled>
                            <option >
                                {{asesorSelec}}
                            </option>
                            </select>
                        </div>
                        </div>
                    <div class="row">
                        <div class="col-sm">
                        <h5 style="margin-top: 5px;">Seleccione un Motivo: </h5>

                            <select class="custom-select" v-model="motivoSegSeleccionado" style="max-width: 465px;margin-left: 5px">
                            <option v-for="motivo in motivosSeg" v-bind:key="motivo.id" :value="motivo">
                                {{motivo.descripcion}} 
                            </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-group-prepend col-sm" style="margin-top:10px">
                            <span class="input-group-text" >Descripcion</span>
                            <textarea v-model="seleccionado.descripcion" type="text" class="form-control" rows="3" cols="40" maxlength="500" ></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-group-prepend col-sm" style="margin-top:10px">
                            <span class="input-group-text" >Calificacion</span>
                            <textarea v-model="value" type="text" class="form-control" rows="1" cols="40" maxlength="3" ></textarea>
                        </div>
                    </div>       
                </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                <button v-if="asesorSelec==null||motivoSegSeleccionado==null||seleccionado.descripcion==''||value==''" type="button" class="btn btn-primary" disabled >Guardar</button>
                                <button v-else type="button" class="btn btn-primary" @click="guardar(motivoSegSeleccionado,seleccionado.descripcion,value)">Guardar</button>
                            </div>
                </div>
                </div>
        </div>
        <!-- termian modal notas -->
    </div>
</template>
<script>
import asesorServices from '../../services/asesor'
import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
import {
    library
} from "@fortawesome/fontawesome-svg-core";
import {
    faTrash
} from "@fortawesome/free-solid-svg-icons";
library.add(faTrash);
import Vue from "vue";
import "vue-easytable/libs/theme-default/index.css";
import VueEasytable from "vue-easytable";

Vue.use(VueEasytable);
export default{
    data(){
        return{
            asesorSeleccionado:null,
            fecha_inicio: null,
            fecha_final: null,
            lista:"",
            seleccionado:{},
            asesorSelec:null,
            motivoSegSeleccionado:null,
            motivosSeg:null,
            value:'',
            editarSelec:null,
            columns: [
          { field: "telefono", key: "telefono", title: "Telefono", align: "left" },
          { field: "descripcion", key: "descripcion", title: "Descripcion", align: "left" },
          { field: "motivo", key: "motivo", title: "Motivo", align: "left" },
          { field: "createdAt", key: "createdAt", title: "Fecha", align: "left" },
          { field: "calificacion", key: "calificacion", title: "Calificacion",align: "left" },
          {
                        field: "",
                        key: "boton",
                        title: "Editar / Borrar",
                        width: "",
                        center: "left",
                        renderBodyCell: (rowIndex) => {
                            return (
                                <span>
                                    <button
                                        class="btn btn-primary"
                                        data-toggle="modal" data-target="#exampleModalC"
                                        on-click={() => this.editar(rowIndex)}
                                    >
                                        Editar
                                    </button>
                                    &nbsp;
                                    <button
                                        class="btn btn-danger"
                                        on-click={() => this.cerrarNota(rowIndex)}
                                    >
                                    <font-awesome-icon icon="trash"/>
                                    </button>
                                </span>
                            );
                        },
                    },
        ],
        tableData: [],
        } 
    },
    components:{
        FontAwesomeIcon
    },
    created() {
        this.getMotivosSeg()
        let today = new Date()
        this.maxDate = new Date()
        this.maxDate.setDate(today.getDate() - 1)        
    },
    mounted() {
        this.getAsesores()
        var date = new Date()

        var day = date.getDate()
        var month = date.getMonth() + 1
        var year = date.getFullYear()

        if (month < 10) month = "0" + month
        if (day < 10) day = "0" + day

        var today = year + "-" + month + "-" + day
        this.fecha_final = today
        this.fecha_inicio = `${year}-${month}-${"01"}`
    },
    methods:{
        getAsesores(){
            asesorServices.getAsesorEsp().then(respa => {
            this.asesorSeleccionado = respa
            })
        },
        borrar(){
            this.lista=""
            var date = new Date()
            var day = date.getDate()
            var month = date.getMonth() + 1
            var year = date.getFullYear()
            if (month < 10) month = "0" + month
            if (day < 10) day = "0" + day
            var today = year + "-" + month + "-" + day
            this.fecha_final = today
            this.fecha_inicio = `${year}-${month}-${"01"}`
            this.tableData= []
        },
        getComentarios(){
            let data={
                asesor_id: this.lista.id,
                fecha_ini: this.fecha_inicio,
                fecha_fin: this.fecha_final 
            }
            asesorServices.getComentarios(data).then(resp=>{
                this.tableData = resp.map(item => {
                let fechaFormateada = new Date(item.createdAt).toISOString().split("T")[0];
                let motivoEncontrado = this.motivosSeg.find(motivo => motivo.id === item.motivo_id);
                return { ...item, createdAt: fechaFormateada,motivo: motivoEncontrado ? motivoEncontrado.descripcion : null};
                })
            })
        },
        editar(data){
            this.motivoSegSeleccionado = this.motivosSeg.find(item => item.id === data.row.motivo_id);
            this.asesorSelec=this.lista.nombre
            this.seleccionado.descripcion=data.row.descripcion
            this.value=data.row.calificacion
            this.editarSelec=data
           //document.querySelector('[data-target="#exampleModalC"]').click(open)
        },
        getMotivosSeg(){
            asesorServices.getMotivosSeguimiento().then(resp=>{
                resp.forEach(e => {
                e
              });
              this.motivosSeg= resp
            })
        },
         guardar(b,c,d){
            let data={
                id:this.editarSelec.row.id,
                motivo_id:b.id,
                asesor_id:this.lista.id,
                monitor_id:this.editarSelec.row.monitor_id,
                telefono:this.editarSelec.row.telefono,
                descripcion:c,
                createdAt:this.editarSelec.row.createdAt,
                updatedAt:this.editarSelec.row.updatedAt,
                estatus:this.editarSelec.row.estatus,
                calificacion:d
            }
             asesorServices.editarNota(data).then(() => {
                alert('Se editó con éxito');
                this.cerrarModal()
                this.getComentarios()
            })

        },
        cerrarModal() {
        document.querySelector('[data-target="#exampleModalC"]').click(close)
        },
        cerrarNota(datos){
            
          let data={
            id:datos.row.id
          }
          asesorServices.cerrarNota(data)
          this.getComentarios()
        }
    }
}
</script>