import Vue from 'vue'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'
//import { gql } from 'graphql-tag'

let urlendpointhasura
let uriwsLink
const token = sessionStorage.getItem('token')
const getHeaders = () => {
	const headers = {
		'Authorization': `Bearer ${token}`
	}
	return headers
}

const hostname = window && window.location && window.location.hostname
if (hostname === 'localhost') {
	urlendpointhasura = 'https://chatbdrespaldo.sdmkt.org/v1/graphql'
	uriwsLink = 'wss://chatbdrespaldo.sdmkt.org/v1/graphql'
} else {
	urlendpointhasura = 'https://chatbdrespaldo.sdmkt.org/v1/graphql'
	uriwsLink = 'wss://chatbdrespaldo.sdmkt.org/v1/graphql'
}

const httpLink = new HttpLink({
	uri: urlendpointhasura,
	options: {
		reconnect: true
	},
	headers: getHeaders()
})

const wsLink = new WebSocketLink({
	uri: uriwsLink,
	options: {
		reconnect: true,
		connectionParams: () => {
			return { headers: getHeaders() }
		}
	}
})

const link = split(
	// split based on operation type
	({ query }) => {
		const definition = getMainDefinition(query)
		return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
	},
	wsLink,
	httpLink
)

// Error Handling
const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.map(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`))
	if (networkError) 
		console.log(`[Network error]: ${JSON.stringify(networkError)}`)
		
	
})

// Create the apollo client
export const apolloClient = new ApolloClient({
	link: errorLink.concat(link),
	cache: new InMemoryCache(),
	connectToDevTools: true
})

// Install the Vue plugin
Vue.use(VueApollo)

export const apolloProvider = new VueApollo({
	defaultClient: apolloClient
})
