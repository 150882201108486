import axios from 'axios'
import config from './config'
let apiDashboard = axios.create({
    
    baseURL: config.baseDashboard,
    headers: {
        Token: 'F<YxSj2BJ3-mr%y&:7L:H.y^/8%H~PZtsgT%s8L8',
        "Access-Control-Allow-Origin": "*",
    }
})

export default apiDashboard