<template>
    <div>
      
        <h4 class="text-black-50 font-weight-bold">SITIO WEB</h4>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 col-12 ">
            <div class="card resultadosCard p-3">
              <span class="text-left text-danger">Datos del día</span>
              <div class="tipo borderAbajo my-3"><b class="h5 font-weight-bold">Resultados: </b><b class="h5">{{totalesPorDiaPaginaWeb.TotalResultados}}</b></div>
              
              <div class="tipo"><b class="h6 ml-1">Server39: </b><b class="h6">{{totalesPorDiaPaginaWeb.TotalServer39}}</b></div>
              <div class="tipo"><b class="h6 ml-1">Server47: </b><b class="h6">{{totalesPorDiaPaginaWeb.TotalServer47}}</b></div><br>
              
              <div class="tipo"><b class="h6 ml-1">Descargas: </b><b class="h6">{{totalesPorDiaPaginaWeb.TotalDescargas}}</b></div>
              <div class="tipo"><b class="h6 ml-1">Visualizados: </b><b class="h6">{{totalesPorDiaPaginaWeb.TotalVisualizado}}</b></div><br>
              
            </div>
          </div>
          <div class="col-md-3 col-12 ">
            <div class="card resultadosCard p-3">
              <span class="text-left text-danger">Datos del mes</span>
              <div class="tipo borderAbajo my-3"><b class="h5 font-weight-bold">Resultados: </b><b class="h5">{{resultadosTotalesPaginaWeb.TotalResultados}}</b></div>
              
              <div class="tipo"><b class="h6 ml-1">Server39: </b><b class="h6">{{resultadosTotalesPaginaWeb.TotalServer39}}</b></div>
              <div class="tipo"><b class="h6 ml-1">Server47: </b><b class="h6">{{resultadosTotalesPaginaWeb.TotalServer47}}</b></div><br>
              
              <div class="tipo"><b class="h6 ml-1">Descargas: </b><b class="h6">{{resultadosTotalesPaginaWeb.TotalDescargas}}</b></div>
              <div class="tipo"><b class="h6 ml-1">Visualizados: </b><b class="h6">{{resultadosTotalesPaginaWeb.TotalVisualizado}}</b></div><br>
              
            </div>
          </div>
          <div class="col-md-6 col-12 ">
            <div class="card resultadosCard p-3">
              <div class="tipo my-3 borderAbajo"><b class="h5 font-weight-bold ">Comparativa plataformas: </b><b class="h6 text-danger">{{hoy}}</b></div>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="comparativaClass">
                      <b class="elegir" :class="{'active': tipoComparativa==1}" @click="tipoComparativa=1">Sistema</b>
                      <b class="elegir" :class="{'active': tipoComparativa==2}" @click="tipoComparativa=2">Tipo</b>
                      <b class="elegir" :class="{'active': tipoComparativa==3}" @click="tipoComparativa=3">Navegador</b>
                    </div>
                    <div >
                      <ul class="tipoComparativa">
                        <li v-show="tipoComparativa==1" v-for="tipo in totalesPorDiaPaginaWeb.SistemaDispositivo" :key="tipo"><b>{{tipo.tipo}}</b> <span>{{tipo.porcentaje}}%</span></li>
                        <li v-show="tipoComparativa==2" v-for="tipo in totalesPorDiaPaginaWeb.TipoDispositivo" :key="tipo"><b>{{tipo.tipo}}</b> <span>{{tipo.porcentaje}}%</span></li>
                        <li v-show="tipoComparativa==3" v-for="tipo in totalesPorDiaPaginaWeb.NavegadoresDispositivo" :key="tipo"><b>{{tipo.tipo}}</b> <span>{{tipo.porcentaje}}%</span></li>
                      </ul>
                    </div>
                  </div>
                  <div v-if="tipoComparativa==1" class="col-12 col-md-6">
                    <Chart v-if="this.graficaOS.datasets[0].data[0]" type="pie" :data="graficaOS" />
                  </div>
                  <div v-else-if="tipoComparativa==2" class="col-12 col-md-6">
                    <Chart v-if="this.graficaType.datasets[0].data[0]" type="pie" :data="graficaType" />
                  </div>
                  <div v-else class="col-12 col-md-6">
                    <Chart v-if="this.graficaBrowser.datasets[0].data[0]" type="pie" :data="graficaBrowser" />
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 col-md-6">
            <div class="card resultadosCard p-3">
              <Chart type="bar" v-if="this.basicData.datasets[0]" :data="basicData" :options="options" />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="card resultadosCard p-3" style="height: 40vh; overflow: overlay;">
              <div class="comparativaClass none">
                <b class="elegir" :class="{'active': tipoResultado==1}" @click="tipoResultado=1">Clínica</b>
                <b class="elegir" :class="{'active': tipoResultado==2}" @click="tipoResultado=2">Estudio</b>
              </div>
              <table v-show="tipoResultado==1" class="table">
                <thead class="thead-dark">
                  <tr>
                    <th>#</th>
                    <th>Clínicas</th>
                    <th>Resultados</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="clinica in totalesPorDiaPaginaWeb.Clinicas" :key="clinica.id_sucursal">
                    <th scope="row">{{clinica.id_sucursal}}</th>
                    <td>{{clinica.sucursal}}</td>
                    <td>{{clinica.cantidad}}</td>
                  </tr>
                </tbody>
              </table>
              <table v-show="tipoResultado==2" class="table">
                <thead class="thead-dark">
                  <tr>
                    <th>Estudio</th>
                    <th>Resultados</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="estudio in totalesPorDiaPaginaWeb.Estudios" :key="estudio.id_estudio">
                    <th scope="row">{{estudio.estudio.charAt(0).toUpperCase()+estudio.estudio.slice(1)}}</th>
                    
                    <td>{{estudio.cantidad}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
              
    </div>
</template>
<script>
import Tooltip from 'primevue/tooltip'
import SocketIo from 'socket.io-client'
import Chart from 'primevue/chart'
import dashboardService from '@/services/dashboard'
export default {
    name:'SitioWeb',
    data() {
      return {
        tipoComparativa:1,
        tipoResultado:1,
        totalesPorDiaPaginaWeb:-1,
        totalesGraficaMensualPaginaWeb: -1,
        resultadosTotalesPaginaWeb: -1,
        hoy: null,
        basicData:{
          labels:[],
          datasets: []
        },
        graficaOS: {
          labels: ['Windows','Android','iOS','Otro'],
          datasets: [
            {
              data: [0, 0, 0],
              backgroundColor: [
                "#FFC830",
                "#99D992",
                "#7ED3EE",
                "#FF7E00"
              ],
              hoverBackgroundColor: [
                "#FFC830",
                "#99D992",
                "#7ED3EE",
                "#FF7E00"
              ]
            }
          ]
        },
        graficaType: {
          labels: ['Movil','Escritorio'],
          datasets: [
            {
              data: [0, 0, 0],
              backgroundColor: [
                "#FFC830",
                "#99D992",
                "#7ED3EE",
                "#FF7E00"
              ],
              hoverBackgroundColor: [
                "#FFC830",
                "#99D992",
                "#7ED3EE",
                "#FF7E00"
              ]
            }
          ]
        },
        graficaBrowser: {
          labels: ['Chrome','Firefox','Safari','Otro'],
          datasets: [
            {
              data: [0, 0, 0],
              backgroundColor: [
                "#FFC830",
                "#99D992",
                "#7ED3EE",
                "#FF7E00"
              ],
              hoverBackgroundColor: [
                "#FFC830",
                "#99D992",
                "#7ED3EE",
                "#FF7E00"
              ]
            }
          ]
        },
        options:{
          responsive: true,
          // tooltips: {
          //   mode: 'index',
          //   intersect: false,
          // },
          legend: {
            display:false
          },
          scales: {
            yAxes: [{
                display: true,
                ticks: {
                    beginAtZero: true
                },
            }]
        }
        },
      }
    },
    components: {
        'Chart': Chart
    },
    props:{
        ruta: String,
        data: Object
    },
    directives: {
      'tooltip': Tooltip
    },
    created(){
      var moment = require('moment')
      moment.locale('es')
      this.hoy = moment().format('LL')
      this.get()
    },
    methods:{
      get(){
        var moment = require('moment')
        moment.locale('es')
        var socket = SocketIo.connect(this.ruta, this.data)
        socket.on('totalesPorDiaPaginaWeb',  (mensaje) => {
          this.totalesPorDiaPaginaWeb=mensaje
          this.graficaOS.datasets[0].data=[parseInt(this.totalesPorDiaPaginaWeb.SistemaDispositivo[0].cantidad), parseInt(this.totalesPorDiaPaginaWeb.SistemaDispositivo[1].cantidad), parseInt(this.totalesPorDiaPaginaWeb.SistemaDispositivo[2].cantidad),parseInt(this.totalesPorDiaPaginaWeb.SistemaDispositivo[3].cantidad)]
          this.graficaType.datasets[0].data=[parseInt(this.totalesPorDiaPaginaWeb.TipoDispositivo[0].cantidad), parseInt(this.totalesPorDiaPaginaWeb.TipoDispositivo[1].cantidad)]
          this.graficaBrowser.datasets[0].data=[parseInt(this.totalesPorDiaPaginaWeb.NavegadoresDispositivo[3].cantidad||0), parseInt(this.totalesPorDiaPaginaWeb.NavegadoresDispositivo[0].cantidad), parseInt(this.totalesPorDiaPaginaWeb.NavegadoresDispositivo[1].cantidad),parseInt(this.totalesPorDiaPaginaWeb.NavegadoresDispositivo[2].cantidad)]
        })
        socket.on('resultadosTotalesPaginaWeb',  (mensaje) => {
          this.resultadosTotalesPaginaWeb=mensaje
          
        })
        // socket.on('totalesGraficaMensualPaginaWeb',  (mensaje) => {
        //   this.totalesGraficaMensualPaginaWeb=mensaje
        //     this.basicData.labels.push(moment.months(moment().month()))
        //   this.totalesGraficaMensualPaginaWeb.map(x =>{
        //     this.basicData.datasets.push({
        //       label: moment(x.dia).format('ll'),
        //       backgroundColor: '#FFC830',
        //       data: [x.cantidad]
        //     })
        //     return x
        //   })
        // this.$forceUpdate
        // })
        dashboardService.getMesSitio().then(mensaje =>{
          this.totalesGraficaMensualWhatsApp=mensaje
          this.basicData.labels.push(moment.months(moment().month()))
          this.totalesGraficaMensualWhatsApp.resultadosGrafica.map(x =>{
            this.basicData.datasets.push({
              label: moment(x.dia).format('ll'),
              backgroundColor: moment(x.dia).date()%2==0?'#ffc830':'#99d992',
              data: [x.cantidad]
            })
            return x
          })
        this.$forceUpdate
        })
      }
    }
}
</script>