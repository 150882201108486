<template>
<div>
    <div class="container-fluid px-0">
        <div class="row"></div>
        <div class="row">
            <div class="col-12-fluid col-xx-10 px-0">
                <div class="row px-0">
                    <div class="col-12 col-md-3">
                        <datos />
                        <div class="row m-3">
                          <div class="btn-group col-6 d-none d-sm-none d-md-flex" role="group" v-tooltip.top="'Cuadricula/Lista'">
                            <button type="button" class="btn btn-outline-info" :class="{'active': lista==1}" @click="lista=1"><font-awesome-icon icon="th-large"/></button>
                            <button type="button" class="btn btn-outline-info" :class="{'active': lista==0}" @click="lista=0"><font-awesome-icon icon="list-ul"/></button>
                          </div>
                          
                          <div class="col-12 col-md-6 btn-group dropup">
                            <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-tooltip.top="'Filtrar orden de Asesores'">
                              <font-awesome-icon icon="filter"/>
                            </button>
                            <div class="dropdown-menu">
                              <a class="dropdown-item" @click="filtrar(0)" :class="{'disabled': orden==0, 'activo': orden==0}" ><font-awesome-icon icon="sort-numeric-down-alt"/> Mayor a menor</a>
                              <a class="dropdown-item" @click="filtrar(1)" :class="{'disabled': orden==1, 'activo': orden==1}" ><font-awesome-icon icon="sort-numeric-down"/> Menor a mayor</a>
                              <a class="dropdown-item" @click="filtrar(2)" :class="{'disabled': orden==2, 'activo': orden==2}" style="padding-left:18px"><i class="icon-whatsapp opt-wa"></i>Whatsapp</a>
                              <a class="dropdown-item" @click="filtrar(3)" :class="{'disabled': orden==3, 'activo': orden==3}" style="padding-left:18px"><i class="icon-messenger-new opt-wa"></i> Messenger</a>
                              <a class="dropdown-item" @click="filtrar(4)" :class="{'disabled': orden==4, 'activo': orden==4}" ><font-awesome-icon icon="star"/> Mayor puntuacíon</a>
                              <a class="dropdown-item" @click="filtrar(5)" :class="{'disabled': orden==5, 'activo': orden==5}" ><font-awesome-icon icon="star-half-alt"/> Menor puntuación</a>
                              <a class="dropdown-item" @click="filtrar(6)" :class="{'disabled': orden==6, 'activo': orden==6}" >🟢 En linea</a>
                              <a class="dropdown-item" @click="filtrar(7)" :class="{'disabled': orden==7, 'activo': orden==7}" >🔴 Desconectado</a>
                              <a class="dropdown-item" @click="filtrar(8)" :class="{'disabled': orden==8, 'activo': orden==8}" ><font-awesome-icon icon="user-plus"/> Todos</a>
                              <a class="dropdown-item" @click="filtrar(9)" :class="{'disabled': orden==9, 'activo': orden==9}" ><font-awesome-icon icon="user-minus"/> Asesores especiales</a>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-8 col-xl-9 asesoresMonitor">
                        <asesores :tipoLista="lista" />
                    </div>
                </div>
                <gMsjXhr  />
            </div>
            <div class="col-xx-2 col-12" :class="{'msjMonitorFB': red=='FB', 'msjMonitor': red=='WA', 'msjFusion': red==''}">
              <div class="btn-group switchRed" role="group" aria-label="Elegir Red Social">
                <button type="button" class="btn  p-1" :class="{'btn-success': red=='WA', 'btn-secondary': red!='WA'}" @click="red='WA'"><i class="icon-whatsapp h5"></i></button>
                <button type="button" class="btn  p-1" :class="{'btn-info': red=='', 'btn-secondary': red!=''}" @click="red=''"><font-awesome-icon icon="globe"/></button>
                <button type="button" class="btn  p-1" :class="{'btn-primary': red=='FB', 'btn-secondary': red!='FB'}" @click="red='FB'"><i class="icon-messenger-new h6"></i></button>
              </div>
              <div v-if="red=='WA'">
                <div v-for="(msj) of msjLive[0]" :key="msj.id" class="mensajeWA">
                  <div class="d-flex"><b class="" :style="{ color: msj.color }">+{{msj.senderid}}</b><div class="nombreWA ml-1"> ~{{msj.nombre}}</div></div>
                  <p class="textWA text-break">{{msj.estado}}</p>
                  <small class="horaWA">{{msj.hora}} hrs</small>
                </div>
              </div>
              <div v-else-if="red=='FB'">
                <div v-for="(msj) of msjLive[1]" :key="msj.id" class="mensajeFB">
                  <div class="bg-dark rounded-circle imgFacebook"></div>
                  <div class="contenedorFB">
                    <b>{{msj.nombre}}</b>
                    <p class="textWA text-break">{{msj.estado}}</p>
                  </div>
                  <div class="responderFB">
                    <a class="linkFB" @click="responder(msj.senderid)" data-toggle="modal" data-target="#traspasarChat">Responder</a> · <span class="horaFB">{{msj.hora}}</span>
                  </div>
                  
                  <!-- <small class="horaWA">{{msj.hora}} hrs</small> -->
                </div>
              </div>
              <div v-else>
                <div v-for="(msj) of juntos" :key="msj.id">
                  <div v-if="msj.redSocial=='W'" class="mensajeWA">
                    <div class="d-flex"><b class="" :style="{ color: msj.color }">+{{msj.senderid}}</b><div class="nombreWA ml-1"> ~{{msj.nombre}}</div></div>
                    <p class="textWA text-break">{{msj.estado}}</p>
                    <small class="horaWA">{{msj.hora}} hrs</small>
                  </div>
                  <div v-else class="mensajeFB">
                    <div class="bg-dark rounded-circle imgFacebook"></div>
                    <div class="contenedorFB">
                      <b >{{msj.nombre}}</b>
                      <p class="textWA text-break">{{msj.estado=='Get Started'?'Empezar':msj.estado}}</p>
                    </div>
                    <div class="responderFB">
                      <a class="linkFB" @click="responder(msj.senderid)" data-toggle="modal" data-target="#traspasarChat">Responder</a> · <span class="horaFB">{{msj.hora}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Tooltip from 'primevue/tooltip'
import GMsjXhr from '@/components/Dashboard/Graficas/MsjXhr.vue'
import Datos from '@/components/Dashboard/Monitor/DatosXredes.vue'
import Asesores from '@/components/Dashboard/Monitor/Asesores.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faThLarge, faListUl, faSortNumericDownAlt, faSortNumericDown, faFilter, faStar, faStarHalfAlt, faGlobe, faUserPlus, faUserMinus } from '@fortawesome/free-solid-svg-icons'
library.add(faThLarge, faListUl, faSortNumericDownAlt, faSortNumericDown, faFilter, faStar, faStarHalfAlt, faGlobe, faUserPlus, faUserMinus)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import SocketIo from 'socket.io-client'
export default {
    name: 'Monitor',
    components: {
        'datos': Datos, GMsjXhr, Asesores, FontAwesomeIcon
    },
    data(){
        return{
            hoy: new Date(),
            hiloMsj: null,
            lista: 1,
            red:'',
            orden: 0,
            juntos:null,
            msjLive: [[{"senderid":"Recibiendo...","nombre":"Mensajes de pacientes","fecha":"2020-08-24T20:58:01.393Z","estado":"Se estan esperando los mensajes...","redSocial":"W","color":"#801773","hora":"00:00"}],[{"senderid":"2648548668509341","nombre":"Esperando...","fecha":"2020-08-24T20:58:37.530Z","estado":"Aun no llega ningun mensaje","redSocial":"F","hora":"en este momento"}]],
        }
    },
    directives: {
      'tooltip': Tooltip
    },
    mounted(){
        this.get()
    },
    methods: {
      responder(sender) {
        sessionStorage.setItem('sender',sender) 
        this.$bus.$emit("abrirInbox", sender)
      },
      filtrar(filtro){
        this.orden=filtro
        this.$bus.$emit('cambiarFiltro', filtro) 
      },
      get(){
        let data = {
            query: {
                "Tipo": "1"
            }
        }

        var socket = SocketIo.connect('https://socket-ws.salud-digna.site', data)
        socket.on('mensajesPorDiaRedes',  (mensaje) => {
          mensaje[1].sort((a,b) =>{
            return b.fecha - a.fecha
          })
          
          this.msjLive=mensaje

          var moment = require('moment')
          moment.locale('es')
          this.msjLive[0].map(
              c=>{
                c.color = '#' + c.senderid.toString().substr(7, 6)
                c.hora =  moment(c.fecha).utc().format('LT')
                return c
              }
          )
          this.msjLive[1].map(
              c=>{
                c.color = '#' + c.senderid.toString().substr(7, 6)
                c.hora =  moment(moment(c.fecha, 'YYYY-MM-DD HH:mm A').toDate()).fromNow()
                  
                  return c
              }
          )
          this.juntos=this.msjLive[0].concat(this.msjLive[1])
          this.juntos.sort((a,b) =>{
            return Date.parse(b.fecha) - Date.parse(a.fecha)
          })
        })



          // clearTimeout(this.hiloMsj)
          // sdService.mensajeLive().then(live=>{ 
          //     let msjs = JSON.parse(JSON.stringify(live))
          //     this.hoy = new Date()   
          //     this.msjLive = msjs.map(
          //         c=>{
          //             c.color = '#' + c.senderid.toString().substr(7, 6)
          //             return c
          //         }
          //     )

          // this.hiloMsj = setTimeout(this.get, 1500)
          // }).catch(() => {
          //     this.hiloMsj = setTimeout(this.get, 1500)
          // })
      }
    },
//     beforeDestroy() {
//     this.hiloMsj = null
//     delete this.hiloMsj
//   },
//     destroyed(){
//         this.get()
//     }
}
</script>
