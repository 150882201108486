<template>
<div class="mt-3">
    <DataTable :value="asesores" selectionMode="single" :selection.sync="selectedUser" @row-select="onRowSelect" dataKey="asesor" :paginator="true" :rows="15">
        <template #header>
            Usuarios dados de alta
            <div style="text-align:left">
                <Button class="btn btn-success top10" @click="addNewUser()">Nuevo <i class="fas fa-plus"></i></Button>
            </div>
        </template>
        <Column field="usuario" header="Nombre" :sortable="true"></Column>
        <Column field="rol"  header="Tipo de usuario" :sortable="true"></Column>
        <Column field="email" header="Correo institucional" :sortable="true"></Column>
    </DataTable>

    <Dialog header="Usuario" :visible.sync="dialogVisible" :style="{width: '400px'}" :modal="true">
        <div class="p-cardialog-content">
            <div class="p-grid p-fluid" v-if="asesor">
                <div class="p-col-4"><label for="usuario">Nombre</label></div>
                <div class="p-col-8">
                    <InputText id="usuario" v-model="asesor.usuario" :autocomplete="off" />
                </div>

                <div class="p-col-4"><label for="rol">Tipo de usuario</label></div>
                <div class="p-col-8">
                    <InputText id="rol" v-model="asesor.rol" disabled="true" placeholder="Asesor" value="Asesor" autocomplete="off" />
                </div>

                <div class="p-col-4"><label for="email">Email</label></div>
                <div class="p-col-8">
                    <InputText id="email" type="email" v-model="asesor.email" autocomplete="off" />
                </div>
            </div>
        </div>

        <template #footer>
            <button @click="deleteUser()" class="btn btn-danger">Borrar <i class="far fa-trash-alt"></i></button>
            <button @click="saveUser()" class="btn btn-success">Guardar <i class="far fa-save"></i></button>
        </template>
    </Dialog>
</div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
export default {
    components: {
        DataTable,
        Column,
        Dialog,
        InputText

    },
    data() {
        return {
            asesores: null,
            dialogVisible: false,
            asesor: null,
            selectedUser: null,
        }
    },
    created() {
        this.asesores = [
                {
                    usuario: 'Luis Enrique Valdez Rojas',
                    rol: 3,
                    alta: '05/12/2019',
                    email: 'l.v@salud-digna.org'
                },
                {
                    usuario: 'Zeymer Montes Ramos',
                    rol: 3,
                    alta: '03/12/2019',
                    email: 'z.m@salud-digna.org'
                },{
                    usuario: 'Alan Rivera Bojorquez',
                    rol: 3,
                    alta: '05/12/2019',
                    email: 'a.r@salud-digna.org'
                },{
                    usuario: 'Ernesto Espinoza Ochoa',
                    rol: 3,
                    alta: '11/12/2019',
                    email: 'e.e@salud-digna.org'
                },{
                    usuario: 'Paul Soto Robles',
                    rol: 3,
                    alta: '02/12/2019',
                    email: 'p.s@salud-digna.org'
                },{
                    usuario: 'Bryan López Llanes',
                    rol: 2,
                    alta: '01/12/2019',
                    email: 'b.l@salud-digna.org'
                },
                {
                    usuario: 'Emmanuel López Loera',
                    rol: 1,
                    alta: '30/11/2019',
                    email: 'emmmanuel.lopez@salud-digna.org'
                }
            
        ]
    },
    methods: {
        addNewUser() {
            this.asesor = {usuario: '', rol: 'Asesor', email: ''};
            this.dialogVisible = true;
        },
        deleteUser() {
            let index = this.findIndexByUser(this.asesor.usuario);
            this.asesores = this.asesores.filter((val,i) => i !== index);
            this.dialogVisible = false;
            this.asesor = null;
            this.selectedUser = null;
        },
        saveUser() {
            let index = this.findIndexByUser(this.asesor.usuario);
            let asesores = [...this.asesores];
            this.asesor.rol = 3
            if (index === -1)
                asesores.push(this.asesor);
            else
                asesores[index] = this.asesor;

            this.asesores = asesores;
            this.dialogVisible = false;
            this.asesor = null;
            this.selectedUser = null;
        },
        onRowSelect(event) {
            this.asesor = {...event.data};
            this.dialogVisible = true;
        },
        findIndexByUser(usuario) {
            let index = -1;
            for (let i = 0; i < this.asesores.length; i++) {
                if (this.asesores[i].usuario === usuario) {
                    index = i;
                    break;
                }
            }

            return index;
        }
    }
}
</script>
