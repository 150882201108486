<template>

    <div class="container">  
        <div class="h2">Felizometro Dia Actual</div>
        <table class="table table-hover table-reflow table-bordered">
        <thead class="thead-light">
            <tr>
                <th>Asesor</th>
                <th>Estatus</th>
                <th>Comentarios</th>
                <th>Tipo de Registrado</th>
                <th>Hora</th>
                </tr>
        </thead>
        <tbody>
            <tr v-for="asesor in felizometro" :key="asesor.id">
            <th scope="row"><b class="h5">{{asesor.nombre}}</b></th>
                <td>
                    <a v-if="asesor.estatus==1" class="pt-5 my-2" style="font-size: 1.5em">
                    <font-awesome-icon icon="laugh" style="color:#75d27f"/> Motivad@
                    </a>
                    <a v-if="asesor.estatus==2" class="pt-5 my-2" style="font-size: 1.5em">
                    <font-awesome-icon icon="smile" style="color:#7fe1c5"/> Feliz
                    </a>
                    <a v-if="asesor.estatus==3" class="pt-5 my-2" style="font-size: 1.5em">
                    <font-awesome-icon icon="meh" style="color:#ffc900" /> Normal
                    </a>
                     <a v-if="asesor.estatus==4" class="pt-5 my-2" style="font-size: 1.5em">
                    <font-awesome-icon icon="sad-cry" style="color:#fb9a27" /> Triste
                    </a>
                    <a v-if="asesor.estatus==5" class="pt-5 my-2" style="font-size: 1.5em">
                    <font-awesome-icon icon="tired" style="color:#ff7b7c"/> Estresad@
                    </a>
                    <!-- <a v-if="asesor.estatus==6" class="pt-5 my-2" style="font-size: 1.5em">
                    <font-awesome-icon icon="head-side-mask" style="color:green"/> Enferm@
                    </a>
                    <a v-if="asesor.estatus==7" class="pt-5 my-2" style="font-size: 1.5em">
                    <font-awesome-icon icon="sad-cry" style="color:#61c4e8"/> Triste
                    </a>
                    <a v-if="asesor.estatus==8" class="pt-5 my-2" style="font-size: 1.5em">
                    <font-awesome-icon icon="tired" style="color:orangered"/> Estresad@
                    </a>
                    <a v-if="asesor.estatus==9" class="pt-5 my-2" style="font-size: 1.5em">
                    <font-awesome-icon icon="angry" style="color:red"/> Enojad@
                    </a> -->
                    </td>
                <td class="h5">{{asesor.descripcion}}</td>
                <td class="h5">
                    <a v-if="asesor.entradaTurno==0" class="pt-5 my-2" style="font-size: 1em">
                    Boton
                    </a>
                    <a v-if="asesor.entradaTurno==1" class="pt-5 my-2" style="font-size: 1em">
                    Automatico
                    </a>
                </td>
                <td>
                    {{asesor.hora}}
                </td>
          </tr>
        </tbody>
    </table>
</div>
</template>
<style>
.td{
    text-align:end;
}

</style>

<script>
import asesorService from "../../services/asesor"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSmile, faAngry,faLaugh,faFrown,faMeh,faHeadSideMask,faKissBeam,faSadCry,faDizzy,faTired} from '@fortawesome/free-solid-svg-icons'
library.add(faSmile, faAngry,faLaugh,faFrown,faMeh,faHeadSideMask,faKissBeam,faSadCry,faDizzy,faTired)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import moment from "moment"
export default{
    data(){
        return{
            felizometro: null,
            usuarioSesion:JSON.parse(sessionStorage.getItem('sesion')),
            dia:''
        }
    },
    components:{
        FontAwesomeIcon
    },
    created(){
      
    },
    mounted() {
        this.inicio()  
    },
    methods: {
        inicio(){
            asesorService.getFelizometro().then(resp => {
                
             this.felizometro=resp.map(item=>{
                   return {
                     hora: moment(item.createdAt).format("hh:mm:ss A"),
                     nombre: item.asesor.nombre,
                     estatus: item.estatus,
                     descripcion: item.descripcion,
                     entradaTurno: item.entradaTurno
                   }
                    
                })

                
                
            })
        }
    }
}
</script>