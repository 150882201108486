export const columnsrep1 = [
    {
        field: "fecha",
        header: "Fecha",
    },
    {
        field: "hora",
        header: "Hora",
    },
    {
        field: "totalPacientesNoAtendidos",
        header: "No se antendieron",
    },
    {
        field: "totalBot",
        header: "Asistente Virtual",
    },
    {
        field: "totalAtendidos",
        header: "En asesor",
    },
    {
        field: "totalPacientes",
        header: "TOTAL",
    },
]
export const columnsrenomedicos = [
    {
        field: "date",
        header: "Fecha",
    },
    {
        field: "count",
        header: "Rechazados",
    }
]

export const medicosEncuesta = [
    {
        field: "pregunta",
        header: "Pregunta",
    },
    {
        field: "asesor",
        header: "Asesores",
    },
    {
        field: "respuesta1",
        header: "Pésima",
    },
    {
        field: "respuesta2",
        header: "Mala",
    },
    {
        field: "respuesta3",
        header: "Regular",
    },
    {
        field: "respuesta4",
        header: "Buena",
    },
    {
        field: "respuesta5",
        header: "Excelente",
    },
    {
        field: "total_cantidad",
        header: "Total",
    },
    {
        field: "prom_asemed",
        header: "Promedio por asesor",
    }

]

export const medicosEncuestaDia = [
    {
        field: "pregunta",
        header: "Pregunta",
    },
    {
        field: "asesor",
        header: "Asesores",
    },
    {
        field: "fecha",
        header: "Fecha",
    },
    {
        field: "respuesta1",
        header: "Pesima",
    },
    {
        field: "respuesta2",
        header: "Mala",
    },
    {
        field: "respuesta3",
        header: "Regular",
    },
    {
        field: "respuesta4",
        header: "Buena",
    },
    {
        field: "respuesta5",
        header: "Excelente",
    },
    {
        field: "total_cantidad",
        header: "Total",
    },
    {
        field: "prom_asemed",
        header: "Promedio por asesor",
    }

]

export const columnssinatender = [
    {
        field: "date",
        header: "Fecha",
    },
    {
        field: "count",
        header: "Rechazados",
    }

]

export const  columnsrep3 = [
    {
        field: "count",
        header: "Pacientes",
    },
    {
        field: "createdAt",
        header: "Fecha",
    },
]

export const columnsrep4 = [
    {
        field: "count",
        header: "Pacientes",
    },
    {
        field: "timezone",
        header: "Fecha",
    },
]

export const columnsrep5 = [
    {
        field: "asesor_id",
        header: "Asesor ID",
    },
    {
        field: "num_colaborador",
        header: "Numero de Colaborador",
    },
    {
        field: "count",
        header: "Pacientes",
    },
    {
        field: "nombre",
        header: "Asesor",
    },
    {
        field: "createdAt",
        header: "Fecha",
    },
]

export const  columnsrep6 = [
    {
        field: "count",
        header: "Transferencias",
    },
    {
        field: "comentario",
        header: "Motivo",
    },
]

export const columnsrep7 = [
    {
        field: "count",
        header: "Transferencias",
    },
    {
        field: "comentario",
        header: "Motivo",
    },
    {
        field: "createdAt",
        header: "Fecha",
    },
]

export const columnsrep8 = [   //Felizometro
    {
        field: "num_colaborador",
        header: "Numero Colaborador",
    },
    {
        field: "asesor",
        header: "Asesor",
    },
    {
        field: "respuesta1",
        //header: "Pésima 😠",
        header: "⭐",
    },
    {
        field: "respuesta2",
        //header: "Mala 😟",
        header: "⭐⭐",
    },
    {
        field: "respuesta3",
        //header: "Regular 😐",
        header: "⭐⭐⭐",
    },
    {
        field: "respuesta4",
        //header: "Buena 😊",
        header: "⭐⭐⭐⭐",
    },
    {
        field: "respuesta5",
        //header: "Excelente 😄",
        header: "⭐⭐⭐⭐⭐",
    },
    {
        field: "total_cantidad",
        header: "Cantidad",
    },
    {
        field: "total_suma",
        header: "Total",
    },
    {
        field: "calificacion",
        header: "Calificación",
    },
]

export const  columnsrep9 = [
    {
        field: "date",
        header: "Fecha",
    },
    {
        field: "hora",
        header: "Hora",
    },
    {
        field: "total",
        header: "Total",
    },
]

export const columnsrep10 = [
    {
        field: "fecha",
        header: "Fecha",
    },
    {
        field: "resultados",
        header: "Total de Resultados",
    },
    {
        field: "rsv",
        header: "RSV",
    },
    {
        field: "opc",
        header: "OPC",
    },
    {
        field: "aviso",
        header: "Total Recordatorios",
    },
    {
        field: "aviso_rsv",
        header: "Recordatorio de RSV",
    },
    {
        field: "aviso_opc",
        header: "Recordatorio de OPC",
    },
]

export const columnsrep12 = [
    {
        field: "fecha",
        header: "Fecha",
    },
    {
        field: "RESULTADOS",
        header: "Resultados",
    },
    {
        field: "COTIZACION",
        header: "Cotizaciones",
    },
    {
        field: "CITAS",
        header: "Citas",
    },
    {
        field: "UBICACION Y TELELEFONO",
        header: "Ubica tu clínica",
    },
]

export const columnsrep13 = [
    {
        field: "asesor_nombre",
        header: "Asesor",
    },
    {
        field: "tiempo_trans",
        header: "Horas contestadas",
    },
    {
        field: "de",
        header: "Empezó",
    },
    {
        field: "hasta",
        header: "Terminó",
    },
    {
        field: "fecha",
        header: "Fecha",
    },
]  

export const columnsrep14 = [
    {
        field: "nombre",
        header: "Asesor",
    },
    {
        field: "ATENDIENDO",
        header: "En inbox",
    },
    {
        field: "ESPERA_1",
        header: "En pendientes",
    },
]

export const  columnsrep15 = [
    {
        field: "NOMBRE",
        header: "Asesor",
    },
    {
        field: "T_ATENDIDOS",
        header: "Pacientes atendidos",
    },
    {
        field: "T_FINALIZADOS",
        header: "Conversaciones finalizadas",
    },
    {
        field: "T_MENSAJES",
        header: "Mensajes enviados",
    },
]

export const columnsrep16 = [
    {
        field: "fecha",
        header: "Fecha",
    },
    {
        field: "hora",
        header: "Hora",
    },
    {
        field: "total",
        header: "Pacientes no atendidos",
    },
]

export const columnsrep17 = [
    {
        field: "tiempo",
        header: "Promedio",
    },
    {
        field: "fecha",
        header: "Fecha",
    },
]

export const columnsrep18 = [
    {
        field: "atendidos",
        header: "Sesiones perdidas",
    },
    {
        field: "fecha",
        header: "Fecha",
    },
]

export const columnsrep19 = [
    {
        field: "asesor_id",
        header: "Asesor ID",
    },
    {
        field: "ejecutivo.num_colaborador",
        header: "Numero de Colaborador",
    },
    {
        field: "hora",
        header: "Hora",
    },
    {
        field: "ejecutivo.nombre",
        header: "Asesor",
    },
    {
        field: "tipos",
        header: "Tipos",
    },
    {
        field: "fecha",
        header: "Fecha",
    }
]

export const columnsrep20 = [
    {
        field: "nombre",
        header: "Asesor",
    },
    {
        field: "asesor_id",
        header: "Asesor id",
    },
    {
        field: "callcenter",
        header: "Callcenter",
    },
    {
        field: "consulta",
        header: "Consulta",
    },
    {
        field: "covid",
        header: "Covid-19",
    },
    {
        field: "covid_demora",
        header: "Covid-Demora",
    },
    {
        field: "covid_horarios",
        header: "Covid-Horarios",
    },
    {
        field: "ecommerce",
        header: "eCommerce",
    },
    {
        field: "etendido",
        header: "Etendido",
    },
    {
        field: "lentes",
        header: "Lentes",
    },
    {
        field: "queja",
        header: "Queja",
    },
    {
        field: "rh",
        header: "RH",
    },
    {
        field: "ticketperdido",
        header: "TicketPerdido",
    },
    {
        field: "convenio",
        header: "TicketConvenio",
    },
    /* {
      field: "Cacu",
      header: "Cacu 2023",
    }, */
    {
        field: "total",
        header: "Total",
    },
    {
        field: "total_unicos",
        header: "Total Unicos",
    },
]

export const columnsrep21 = [
    {
        field: "asesor",
        header: "Asesor",
    },
    {
        field: "total",
        header: "Pacientes atendidos",
    },
    {
        field: "fecha",
        header: "Fecha",
    },
]

export const columnsrep22 = [
    {
        field: "pacientes",
        header: "Pacientes",
    },
    {
        field: "fecha",
        header: "Fecha",
    },
]

export const columnsrep23 = [
    {
        field: "total",
        header: "Pacientes",
    },
    {
        field: "fecha",
        header: "Fecha",
    },
]

export const columnsrep24 = [
    {
        field: "atendidos",
        header: "Pacientes",
    },
    {
        field: "fecha",
        header: "Fecha",
    },
]

export const columnsrep26 = [
    {
        field: "asesor",
        header: "Asesor",
    },
    {
        field: "count",
        header: "Pacientes atendidos",
    },
    {
        field: "fecha",
        header: "Fecha",
    },
]

export const columnsrep27 = [
    {
        field: "fecha",
        header: "Fecha",
    },
    {
        field: "hora",
        header: "Hora",
    },
    {
        field: "cola",
        header: "Cola",
    },
    {
        field: "solicitudes",
        header: "Llegaron a la cola",
    },
    {
        field: "sc",
        header: "Se fueron de la cola",
    },
    {
        field: "pa",
        header: "Se atendieron",
    },
    {
        field: "asesores",
        header: "Asesores que atendieron",
    },


]

export const columnsrep28 = [
    {
        field: "fecha",
        header: "Fecha",
    },
    {
        field: "nombre",
        header: "Nombre",
    },
    {
        field: "num_colaborador",
        header: "# Colab",
    },
    {
        field: "inicio_sesion",
        header: "Inicio",
    },
    {
        field: "entradaEstatus1",
        header: "Est",
    },
    {
        field: "diferenciaEntrada1",
        header: "Dif",
    },
    {
        field: "primerMensaje",
        header: "PrMen",
    },
    {
        field: "comida_i",
        header: "ComidaI",
    },
    {
        field: "salidaEstatus1",
        header: "Est",
    },
    {
        field: "diferenciaSalida1",
        header: "Dif",
    },
    {
        field: "comida_f",
        header: "ComidaF",
    },
    {
        field: "entradaEstatus2",
        header: "Est",
    },
    {
        field: "diferenciaEntrada2",
        header: "Dif",
    },
    {
        field: "cierre_sesion",
        header: "Cierre",
    },
    {
        field: "salidaEstatus2",
        header: "Est",
    },
    {
        field: "diferenciaSalida2",
        header: "Dif",
    },
    {
        field: "tiempo_t",
        header: "TTr",
    },
    {
        field: "tiempo_nt",
        header: "TNTr",
    },
]

export const columnsrep29 = [
    {
        field: "asesor_id",
        header: "Asesor ID",
    },
    {
        field: "num_colaborador",
        header: "Numero de Colaborador",
    },
    {
        field: "asesor",
        header: "Nombre",
    },
    {
        field: "fecha",
        header: "Fecha",
    },
    {
        field: "hora",
        header: "Hora",
    },
    {
        field: "estatus",
        header: "Estatus",
    },
    {
        field: "descripcion",
        header: "Comentarios",
    },
    {
        field: "entradaTurno",
        header: "Entrada Turno",
    },

]

export const columnsrep30 = [
    {
        field: "nombre",
        header: "Servicio",
    },
    {
        field: "count",
        header: "Cantidad",
    },
]

export const columnsrep31 = [  //Bitacora Breaks
    {
        field: "num_colaborador",
        header: "Numero Colaborador",
    },
    {
        field: "fecha",
        header: "Fecha",
    },
    {
        field: "desde",
        header: "Desde",
    },
    {
        field: "hasta",
        header: "Hasta",
    },
    {
        field: "nombre",
        header: "Asesor",
    },
    {
        field: "motivo",
        header: "Motivo",
    }, {
        field: "tiempo",
        header: "Tiempo transcurrido",
    },
]

export const columnsrep32 = [  //Comportamiento diario WhatsApp
    {
        field: "pacientes_general",
        header: "Pacientes general",
    },
    {
        field: "pacientes_asesor",
        header: "Pacientes asesor",
    },
    {
        field: "pacientes_porasesor",
        header: "Pacientes por asesor",
    },
    {
        field: "fecha",
        header: "Fecha",
    }, {
        field: "atencion_promedio_marketing",
        header: "Atencion promedio marketing",
    }, {
        field: "p_atencion",
        header: "Porcentaje de Atencion",
    },
]

export const columnsrep33 = [
    {
        field: "createdAt",
        header: "Fecha",
    },
    {
        field: "pacientes",
        header: "Pacientes",
    }
]

export const columnsrep34 = [
    {
        field: "createdAt",
        header: "Fecha",
    },
    {
        field: "asesor_id",
        header: "Asesor ID",
    },
    {
        field: "asesor",
        header: "Asesores",
    },
    {
        field: "count",
        header: "Pacientes Atendidos",
    }
]

export const columnsrep35 = [
    {
        field: "createdAt",
        header: "Fecha",
    },
    {
        field: "atendidos",
        header: "Pacientes Atendidos",
    }
]

export const columnsrep36 = [
    {
        field: "fecha",
        header: "Fecha",
    },
    {
        field: "menu_asesores",
        header: "Menu Asesores",
    },
    {
        field: "asesor",
        header: "Asesores",
    },
    {
        field: "asesor_examen_de_la_vista",
        header: "Examenes de la vista",
    },
    {
        field: "asesor_facturacion",
        header: "Facturaciones",
    },
    {
        field: "asesor_cotizacion_estudios",
        header: "Cotizaciones",
    },
    {
        field: "asesor_menu_otros",
        header: "Menu Otros",
    },
    {
        field: "asesor_resultados",
        header: "Resultados",
    },
    {
        field: "total",
        header: "Total",
    },
]

export const columnsrep37 = [
    {
        field: "fecha",
        header: "Fecha",
    },
    {
        field: "hora",
        header: "Hora",
    },
    {
        field: "total",
        header: "Total",
    }
]

export const columnsrepEsperaAsesor = [
    {
        field: "fecha",
        header: "Fecha",
    },
    {
        field: "asesor_id",
        header: "Asesor Id",
    },
    {
        field: "nombre",
        header: "Asesor",
    },
    {
        field: "promedio_espera_mkt",
        header: "Promedio espera",
    }
]

export const columnsrepGoogleCAsesor = [
    {
        field: "asesor",
        header: "Asesor",
    },
    {
        field: "createdAt",
        header: "Fecha",
    },
    {
        field: "count",
        header: "Total",
    }
]

export const columnsrepGooglePAsesor = [
    {
        field: "createdAt",
        header: "Fecha",
    },
    {
        field: "pacientes",
        header: "Pacientes",
    }
]

export const columnsComentariosFelizometro = [
    {
        field: "numero",
        header: "Telefono",
    },
    {
        field: "asesor",
        header: "Nombre asesor",
    },
    {
        field: "calificacion",
        header: "Calificacion",
    },
    {
        field: "comentario",
        header: "Comentario",
    },
    {
        field: "fecha",
        header: "Fecha",
    }
]

export const columnsEncuestasContestadas = [
    {
        field: "numero",
        header: "Telefono",
    },
    {
        field: "asesor_id",
        header: "Asesor Id",
    },
    {
        field: "asesor_nombre",
        header: "Nombre asesor",
    },
    {
        field: "calificacion",
        header: "Calificacion",
    },
    {
        field: "comentario",
        header: "Comentario",
    },
    {
        field: "fecha_envio",
        header: "Fecha Envio",
    },
    {
        field: "fecha_contestacion",
        header: "Fecha Contestada",
    }
]

		
	
export const columnsEncuestasContestadasDetalle = [
    {
        field: "asesor_id_dato",
        header: "Asesor ID",
    },
    {
        field: "asesor_nombre_dato",
        header: "Asesor",
    },
    {
        field: "total_pacientes",
        header: "Total encuestas",
    },
    {
        field: "csat_promedio",
        header: "CSAT Promedio",
    },
    {
        field: "csat_porcentaje",
        header: "CSAT Porcentaje",
    },
]