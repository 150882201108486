import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import configAuth from "../firebase/firebase"
// import { getDatabase, ref, onValue} from "firebase/database";

const provider = new GoogleAuthProvider();
const auth = configAuth;

export const signin = () => signInWithPopup(auth, provider.setCustomParameters({prompt:'select_account'}))
    .then((result) => {
        // auth.onAuthStateChanged(async (user) => {
        //     if (user) {
        //         const db = getDatabase();
        //         const dbRef  = ref(db,'metadata/' + user.uid + '/refreshTime');

        //         onValue(dbRef , async () => {

        //             const token = await user.getIdToken(true);
        //             const idTokenResult = await user.getIdTokenResult();
        //             const hasuraClaim = idTokenResult.claims['https://hasura.io/jwt/claims'];
        //             if (hasuraClaim) {
        //                 sessionStorage.setItem('X_HC_DR', hasuraClaim['x-hasura-default-role']);
        //                 sessionStorage.setItem('X_HC_RTP', hasuraClaim['x-sd-roletp']);
        //                 sessionStorage.setItem('token', token)
        //                 // getUser(hasuraClaim['x-sd-sdnumer']);
        //                 // setPhotoUrl(user.photoURL);

        //                 // if(sessionStorage.getItem('X_HC_DR') == "programador") {
        //                 //     setIsDeveloper(true);
        //                 // }
        //             }
        //         });
        //     }
        // });
        return result.user
    }).catch((error) => {
        console.log(error)
    });