import api from './apiDashboard'
let service = {}

service.getServerTime = function() {
    return api.get(`/api/dashboard/tiempos-servidores`).then(res => res.data)
}

service.getMesSitio = function() {
    return api.get(`/api/dashboard/totales-mes-sitio-web`).then(res => res.data)
}
service.getMesWhatsapp = function() {
    return api.get(`/api/dashboard/totales-mes-whatsapp`).then(res => res.data)
}
service.getMesRabbit = function() {
    return api.get(`/api/dashboard/totales-mes-rabbit`).then(res => res.data)
}
service.getServidores = function() {
    return api.get(`/api/dashboard/servidores`).then(res => res.data)
}
service.getGraficaSitioNi = function() {
    return api.get(`/api/dashboard/totales-grafica-mes-sitio-web-nicaragua`).then(res => res.data)
}
service.getMesSitioNi = function() {
    return api.get(`/api/dashboard/totales-mes-sitio-web-nicaragua`).then(res => res.data)
}
service.getDiaSitioNi = function() {
    return api.get(`/api/dashboard/totales-dia-sitio-web-nicaragua`).then(res => res.data)
}
export default service