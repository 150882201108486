// import api from './api'
import axios from 'axios'
let service = {}

// service.envio = function(sender, template, parametros) {
//     let datos = [{
//         telefono: sender,
//         tipohsm: template,
//         msj: parametros,
//     }]
//     return api.post('/mensaje/envio', datos).then(res => res.data)
// }


service.envio = function (capturista_id, numero, origen, hsm_id) {
    const datos = {
        capturista_id:capturista_id,
        hsm_id: hsm_id,
        origen:origen,
        userid: numero
    }

    // return console.log(datos)
    return axios.post('https://sdigna.com/api/sendplantilla', datos).then(res => res.data)
}

export default service