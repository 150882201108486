import api from './api'
let service = {}

service.Control = function() {
    return api.get('/controles').then(res => res.data).catch(err => {
        if (401 === err.response.status) {
            window.location = '/'
            console.log('Token Expirado ', err)
        }
    })
}

service.CambiarControl = function(id, estatus) {
    let datos = {
        controlid: id,
        estatus: estatus
    }
    return api.post('/updatecontrol', datos).then(res => res.data)
}

service.getCopys = function(id) {
    return api.get('/copy/' + `${id}`).then(res => res.data).catch(err => {
        if (401 === err.response.status) {
            window.location = '/'
            this.$gtag.event('tokenExpirado',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': '12hrs conectado',
                'value': 1
            })
        }
    })
}

service.getParametros = function(id) {
    return api.get('/parametro/' + `${id}`).then(res => res.data).catch(err => {
        if (401 === err.response.status) {
            window.location = '/'
            this.$gtag.event('tokenExpirado',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': '12hrs conectado',
                'value': 1
            })
        }
    })
}

service.CambiarEstatusControl = function(id, estatus) {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let datos = {
        controlId: id,
        status: estatus,
        asesor_id: asesor.id
    }
    return api.put('/status/control', datos).then(res => res);
}

service.AgregarCopy = function(datos) {
    return api.post('/copy', datos).then(res => res.data)
}

service.AgregarParametro = function(datos) {
    return api.post('/parametro', datos).then(res => res.data)
}

service.agregarControl = function(datos) {
    return api.post('/control', datos).then(res => res.data)
}


service.updateoControl = function(datos) {
    return api.put('/control', datos).then(res => res);
}
service.updateCopy = function(datos) {
    return api.put('/copy', datos).then(res => res);
}

service.updateParametro = function(datos) {
    return api.put('/parametro', datos).then(res => res);
}

service.configControlAsesor = function(data){
    return api.put('/control/horario',data).then(res =>res.data)
}

service.configLimiteEncuestas = function (data) {
    return api.put('/configEncuesta', data).then(res => res.data)
}


service.CambiarEstatusCopy = function(id, estatus) {
    let datos = {
        copyId: id,
        status: estatus
    }
    return api.put('/status/copy', datos).then(res => res);
}


service.CambiarEstatusParametro = function(id, estatus) {
    let datos = {
        parametroid: id,
        status: estatus
    }
    return api.put('/status/parametro', datos).then(res => res);
}
service.updateAsesorConfig = function(datos) {
    return api.put('/asesor/control', datos).then(res => res);
}
service.getControl = function() {
    return api.get('/control').then(res => res);
}
service.updateoControlPyP = function(datos) {
    return api.put('/controlpyp', datos).then(res => res);
}
service.getControlTiempo = function() {
    return api.get('/controlTiempoEsp').then(res => res);
}
service.updateControlTiempo= function(datos) {
    return api.put('/controlTiempoDEsp', datos).then(res => res);
}
service.updateAsesorConfigGen = function(a){
    let datosG={
        num_pacientes: a,
    }
    return api.put('/asesors/num_pacientes', datosG).then(res => res);
}
service.getAsesorConfigGen = function() {
    return api.get('/asesors/num_pacientes').then(res => res);
}

service.updateAsesorConfigGenEsp = function(datosEsp){
    return api.put('/asesors/num_pacientes/esp', datosEsp).then(res => res);
}
export default service