<template>
  <div>
    <link
      rel="stylesheet"
      href="https://unpkg.com/element-ui/lib/theme-chalk/index.css"
    />
    <h1>Envios</h1>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-6">

          <Accordion :activeIndex="paso">
            <AccordionTab header="Plantilla y Parámetros" :disabled="true" @mousemove="hayHsm=!hayHsm">
              <div class="row mt-3">
                <p class="h6 mr-3">Adjuntar base:</p>
                <!-- <input
                  class="d-none"
                  type="file"
                  @change="onUpload"
                  name=""
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  id=""
                />
                <el-upload
                  ref="upload"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  action=""
                  :auto-upload="false"
                  :on-change="onUpload"
                  :show-file-list="false"
                >
                  <el-button slot="trigger" native-type="file">
                    Adjuntar Excel
                  </el-button>
                </el-upload> -->
                <!-- <FileUpload mode="basic" name="demo[]" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" :maxFileSize="1000000" @upload="onUpload" /> -->
                <input type="file" name="excel" id="excel" @change="onUpload($event)" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" >
                <div v-if="archivo" class="mx-3" >
                  <!-- <v-select  placeholder="Plantillas disponibles" :options="archivo" label="sheetName" v-model="pagina" @input="param = Object.keys(pagina.sheet[0])">
                    <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                            No se encontró la plantilla de: <em>{{ search }}</em>.
                        </template>
                        <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
                    </template>
                </v-select> -->
                  
                </div>
              </div>
              <div class="row"  >
                <p class="h6 mr-3">Seleccionar template:</p>
                <!-- <v-select v-if="archivo!=null" placeholder="Plantillas disponibles" :options="sTemplate" label="nombre_hsm" v-model="wabyTemplate" @input="verTemplate()">
                    <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                            No se encontró la plantilla de: <em>{{ search }}</em>.
                        </template>
                        <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
                    </template>
                </v-select> -->
                <select v-if="sTemplate" v-model="wabyTemplate" class="form-control" v-on:change="verTemplate" required>
                  <option disabled value="" hidden >Selccione un template</option>
                  <option  v-for="item in sTemplate" :key="item.id" :value="item">{{ item.nombre_hsm }}</option>
                </select>
              </div>
              <div class="row mt-3">
                <div class="offset-9 float-right"><Buton label="Adelante" @click="enviarArchivo" class="p-button-success offset-8" :disable="loading3" /></div>
              </div>
            </AccordionTab>
            <!-- <AccordionTab header="Programación" :disabled="true">
              ¿Que día y en que horarío se debe enviar éste envio?

              <input type="datetime-local" name="" id="">
              <div class="row d-flex justify-content-between mt-3">
                <Buton label="Atrás" @click="paso=paso-1" class="p-button-danger" />
                <Buton label="Adelante" @click="paso=paso+1" class="p-button-success" />
              </div>
            </AccordionTab>
            <AccordionTab header="Resumen" :disabled="true">
              Vas a enviar: {{param}}
              <div class="row d-flex justify-content-between mt-3">
                <Buton label="Atrás" @click="paso=paso-1" class="p-button-danger" />
                <Buton label="Adelante" @click="paso=paso+1" class="p-button-success" />
              </div>
            </AccordionTab> -->
          </Accordion>
          
          
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-center">
          <div class="bgWA d-none d-md-block">
            <div class="phoneWa d-flex justify-content-center">
              <b class="horaCEL text-white">{{
                hoy.toString().substr(16, 5)
              }}</b>
            </div>
            <div class="mensajeWA">
              <p class="textWA">{{ template }}</p>
              <!-- <small class="horaWA"
                >{{ hoy.toString().substr(16, 5) }} hrs</small
              > -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css'
// import { Upload, Button } from "element-ui";
import { readExcel } from "@/plugins/importExcel";
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Buton from 'primevue/button';
import hsmServices from "../../services/sd"
// import FileUpload from 'primevue/fileupload'
export default {
  name: "HSM",
  components: {
    //vSelect,
    Accordion,AccordionTab, Buton,
    // 'FileUpload': FileUpload,
    // "el-upload": Upload,
    // "el-button": Button,
  },
  data() {
    return {
      paso: 0,
      template: "Hola",
      sTemplate: null,
      inputs: 0,
      wabyTemplate: {id:0,nombre_hsm:'Plantillas'},
      hoy: new Date(),
      disabled: true,
      value: null,
      id: null,
      loader: null,
      loading3: false,
      files: null,
      selected: [],
      archivo: null,
      pagina: {sheetName:'Seleccionar Hoja'},
      param: null,
      hayHSM:false,
      filtroHSM:null,
    };
  },
  beforeMount() {
    this.hayHSM=false
    this.sTemplate={nombre_hsm:'Seleccionar Plantilla'}
    hsmServices.getAllHsm().then((resp)=>{
      this.sTemplate=resp.data
      this.hayHSM=true
    })
  },
  updated(){
    if(this.wabyTemplate==null){
      this.wabyTemplate={id:0,nombre_hsm:'Plantillas'}
    }
  },
  methods: {
    // buscarHSM(event) {
    //   setTimeout(() => {
    //       if (!event.query.trim().length) {
    //           this.filtroHSM = [...this.sTemplate];
    //       }
    //       else {
    //           this.filtroHSM = this.sTemplate.filter((hsm) => {
    //               return hsm.nombre_hsm.toLowerCase().startsWith(event.query.toLowerCase());
    //           });
    //       }
    //   }, 100);
		// },
    verTemplate() {
      this.$forceUpdate()
      this.template = this.wabyTemplate.mensaje;
      let respaldo = this.wabyTemplate
      this.wabyTemplate=respaldo
      this.$forceUpdate()
    },
    remplazar(i) { 
      this.template.replace(`'{'+${i}+'}'`, this.i);
    },
    onUpload(event) {
      // this.archivo = readExcel(event.target.files[0]);
      this.archivo=event.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(this.archivo)
      this.files=readExcel(reader.result)
    },
    enviarArchivo(){
      this.loading3=true
      let formData = new FormData();
      formData.append("files", this.archivo);
      formData.append("models", JSON.stringify([{ name: this.archivo.name, model: this.wabyTemplate.nombre_hsm }]));
      hsmServices.uploadFile(formData).then((resp)=>{
        console.log(resp)
      }).finally(()=>{
        this.loading3=false
      })
    }
  },
};
</script>
<style>
.inactive > div > .p-fileupload-choose {
  display: none;
}
</style>