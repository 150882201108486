<template>
    <div>
      
        <h4 class="text-black-50 font-weight-bold">DASHBOARD</h4>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-md-2">
            <div class="card resultadosCard p-3">
              <div class="tipo borderAbajo my-2"><b class="h5 font-weight-bold">Resultados Total: </b><b class="h5">{{parseInt(totalesPorDiaPaginaWeb.TotalResultados)+parseInt(resultadosTotalesWhatsapp.TotalDia)+parseInt(resultadosTotalesWhatsapp.EntregadoPlataforma)}}</b></div>
              <div class="tipo borderAbajo my-2"><b class="h6 font-weight-bold">Resultados sitio web: </b> <b class="h6 font-weight-bold">{{totalesPorDiaPaginaWeb.TotalResultados}}</b></div>
              <div class="tipo borderAbajo my-2"><b class="h6 font-weight-bold">Resultados Whatsapp: </b> <b class="h6 font-weight-bold">{{resultadosTotalesWhatsapp.TotalDia}}</b></div>
              <div class="tipo borderAbajo my-2"><b class="h6 font-weight-bold">Resultados plataforma: </b> <b class="h6 font-weight-bold">{{resultadosTotalesWhatsapp.EntregadoPlataforma}}</b></div>
              
            </div>
            
          </div>
          <div class="col-12 col-md-5 ">
            <div class="card resultadosCard p-3">
              <table class="table table-borderless">
                <thead class="">
                  <tr>
                    <th aling="center" class="h6 font-weight-bold">Cron</th>
                    <th aling="center" class="h6 font-weight-bold">vs</th>
                    <th aling="center" class="h6 font-weight-bold">Bot</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-tooltip.bottom="'Resultados de estudios en general'">
                    <td>{{totalesPorDia.CRON.resultados}}</td>
                    <td>Resultados 🧪</td>
                    <td>{{totalesPorDia.BOT.resultados}}</td>
                  </tr>
                  <tr v-tooltip.bottom="'Resultados COVID19 PCR'">
                    <td>{{totalesPorDia.CRON.covid}}</td>
                    <td>COVID-19 PCR 🦠</td>
                    <td>{{totalesPorDia.BOT.covid}}</td>
                  </tr>
                  <tr v-tooltip.bottom="'Resultados de Antígeno COVID19'">
                    <td>{{totalesPorDia.CRON.antigeno}}</td>
                    <td>COVID-19 Antígeno💉</td>
                    <td>{{totalesPorDia.BOT.antigeno}}</td>
                  </tr>
                  
                  <tr v-tooltip.bottom="'Resultados de lentes'">
                    <td>{{totalesPorDia.CRON.glasses}}</td>
                    <td>Lentes 👓</td>
                    <td>{{totalesPorDia.BOT.glasses}}</td>
                  </tr>
                  <tr v-tooltip.bottom="'Resultados de laboratorio'">
                    <td>{{totalesPorDia.CRON.laboratorio}}</td>
                    <td>Laboratorio 🔬</td>
                    <td>{{totalesPorDia.BOT.laboratorio}}</td>
                  </tr>
                  <tr v-tooltip.bottom="'Resultados no listos'">
                    <td>{{totalesPorDia.CRON.no_ready}}</td>
                    <td>No listo ❌</td>
                    <td>{{totalesPorDia.BOT.no_ready}}</td>
                  </tr>
                  
                </tbody>
              </table>
              
            </div>
          </div>
          <div class="col-12 col-md-5">
            <div class="card resultadosCard p-3">
              <div class="tipo my-3 borderAbajo"><b class="h5 font-weight-bold ">Cola</b><b></b></div>
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="tipo my-0"><b class="h6 font-weight-bold">COLA NOTIFICACIONES </b><b class="h6"></b></div>
                  <div class="tipo"><b class="h6 ml-1">Totales: </b><b class="h6">{{totalesColasRabbit.TotalNotificaciones}}</b></div><br>
                  <div class="tipo my-0"><b class="h6 font-weight-bold">COLA BOT </b><b class="h6"></b></div>
                  <div class="tipo"><b class="h6 ml-1">Totales: </b><b class="h6">{{totalesColasRabbit.TotalBot}}</b></div><br>
                  <div class="tipo my-0"><b class="h6 font-weight-bold">COLA VALIDACIÓN </b><b class="h6"></b></div>
                  <div class="tipo"><b class="h6 ml-1">Totales: </b><b class="h6">{{totalesColasRabbit.TotalValidacion}}</b></div><br>
                </div>
                <div class="col-md-6 col-12">
                  <Chart v-if="rabbitData.datasets[0]" type="bar" :data="rabbitData" :options="options" />

                </div>
              </div>
              
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6 col-12">
            <div class="card resultadosCard p-3">
              
              <div class="row">
                <div class="tipo ml-5 mb-3"><b class="h5 font-weight-bold ">SERVIDOR SISTEMA</b><b></b></div>
                <div class="col-md-6 col-12 pl-5">
                  <div class="tipo"><span class="">Tiempo promedio </span><b class="h6"></b></div>
                  <div class="tipo"><b class="h6 ml-1 font-weight-bold">{{tiempoServidor.TiempoPromedio.toFixed(2)}} segundos</b><b class="h6"></b></div><br>
                  <div class="tipo"><span class="">Mejor tiempo</span><b class="h6"></b></div>
                  <div class="tipo"><b class="h6 ml-1 font-weight-bold">{{tiempoServidor.MejorTiempo.toFixed(2)}} segundos</b><b class="h6"></b></div><br>
                  <div class="tipo"><span class="">Peor tiempo </span><b class="h6"></b></div>
                  <div class="tipo"><b class="h6 ml-1 font-weight-bold">{{tiempoServidor.PeorTiempo.toFixed(2)}} segundos</b><b class="h6"></b></div><br>
                </div>
                <div class="col-md-6 col-12 d-flex flex-column justify-content-center">
                  <h2>{{tiempoServidor.Porcentaje}}%</h2>
                  <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated verdeGrafica" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" :style="'width: '+tiempoServidor.Porcentaje+'%'"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="card resultadosCard p-3">
              <div class="row">
                <div class="col-md-8 col-12">
                  <div class="tipo my-3"><b class="h5 font-weight-bold">Total Covid-19: </b><b class="h5">{{resultadosTotalesWhatsapp.TotalCovid}}</b></div>
                  <div class="tipo my-1"><b class="h6 font-weight-bold">Prueba PCR: </b><b class="h6">{{resultadosTotalesWhatsapp.TotalPCR}}</b></div>
                  <div class="tipo"><b class="h6 ml-1">Entregado: </b><b class="h6">{{resultadosTotalesWhatsapp.CovidEntregado}}</b></div>
                  <div class="tipo"><b class="h6 ml-1">Pendiente: </b><b class="h6">{{resultadosTotalesWhatsapp.CovidPendiente}}</b></div><br>
                  <div class="tipo my-1"><b class="h6 font-weight-bold">Prueba Antígeno: </b><b class="h6">{{resultadosTotalesWhatsapp.TotalAntigeno}}</b></div>
                  <div class="tipo"><b class="h6 ml-1">Entregado: </b><b class="h6">{{resultadosTotalesWhatsapp.AntigenoEntregado}}</b></div>
                  <div class="tipo"><b class="h6 ml-1">Pendiente: </b><b class="h6">{{resultadosTotalesWhatsapp.AntigenoPendiente}}</b></div><br>
                </div>
                <div class="col-md-4 col-12">
                  <Chart v-if="this.chartData.datasets[0].data[0]" type="doughnut" :data="chartData" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import Tooltip from 'primevue/tooltip'
import SocketIo from 'socket.io-client'
import Chart from 'primevue/chart';
export default {
    name:'DashboardResultados',
    data() {
      return {
        resultadosTotalesWhatsapp:-1,
        totalesPorDiaPaginaWeb:-1,
        totalesPorDia: {CRON:{resultados:-1},BOT:{resultados:-1}},
        totalesColasRabbit:-1,
        tiempoServidor:-1,
        hoy: null,
        basicData:{
          labels:[],
          datasets: []
        },
        rabbitData:{
          labels:['Cola'],
          datasets: []
        },
        chartData: {
          labels: ['PCR','Antígeno'],
          datasets: [
            {
              data: [0, 0, 0],
              backgroundColor: [
                "#FFC830",
                "#99D992",
                "#7ED3EE"
              ],
              hoverBackgroundColor: [
                "#FFC830",
                "#99D992",
                "#7ED3EE"
              ]
            }
          ]
        },
        options:{
          responsive: true,
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          scales: {
            yAxes: [{
                display: false,
                ticks: {
                    beginAtZero: true
                },
            }]
          }
        },
      }
    },
    components: {
        'Chart': Chart
    },
    props:{
        ruta: String,
        data: Object
    },
    directives: {
      'tooltip': Tooltip
    },
    created(){
      var moment = require('moment')
      moment.locale('es')
      this.hoy = moment().format('LL')
      this.get()
    },
    methods:{
      get(){
        var moment = require('moment')
        moment.locale('es')
        var socket = SocketIo.connect(this.ruta, this.data)
        socket.on('resultadosTotalesWhatsapp',  (mensaje) => {
          this.resultadosTotalesWhatsapp=mensaje
          
        })
        socket.on('totalesPorDiaPaginaWeb',  (mensaje) => {
          this.totalesPorDiaPaginaWeb=mensaje
          
        })
        socket.on('totalesPorDia',  (mensaje) => {
          this.totalesPorDia=mensaje

        })
        socket.on('resultadosTiemposServidor',  (mensaje) => {
          this.tiempoServidor=mensaje

        })
        socket.on('totalesColasRabbit',  (mensaje) => {
          this.totalesColasRabbit=mensaje
          this.rabbitData.datasets=[{
            label: 'Notif.',
            backgroundColor:"#FFC830",
            data:[parseInt(this.totalesColasRabbit.TotalNotificaciones)]
          },{
            label: 'Bot',
            backgroundColor:"#99D992",
            data:[parseInt(this.totalesColasRabbit.TotalBot)]
          },{
            label: 'Valid.',
            backgroundColor:"#7ED3EE",
            data:[parseInt(this.totalesColasRabbit.TotalValidacion)]
          }]
        })
        socket.on('totalesGraficaMensualWhatsApp',  (mensaje) => {
          this.totalesGraficaMensualWhatsApp=mensaje
            this.basicData.labels.push(moment.months(moment().month()))
          this.totalesGraficaMensualWhatsApp.map(x =>{
            this.basicData.datasets.push({
              label: moment(x.dia).format('ll'),
              backgroundColor: '#FFC830',
              data: [x.cantidad]
            })
            return x
          })
        this.$forceUpdate
        })
      }
    }
}
</script>